<template>
	<div>
		<div class="roleTitle">
			<span v-if="userInfo.payPassword == '' || userInfo.payPassword == null">设置支付密码</span>
			<span v-else>修改支付密码</span>
			<span class="balance">｜支付密码校验身份仅在使用账户余额时</span>
		</div>
		<div class="submitForm" v-if="status == 0 ">
			<div class="fl formItem">
				<div class="submitLable">
					<span class="lableText">已绑定手机：</span>
				</div>
				<div class="submitValue">
					{{phoneEncryption(userInfo.account)}}
				</div>
			</div>

			<div class="fl formItem">
				<div class="submitLable">
					<span class="lableText">短信验证码：</span>
				</div>
				<div class="submitValue">
					<el-input v-prevent-space v-model="userPassword.code" type="number" placeholder="请输入验证码"></el-input>
					<span class="getCode" @click="getCodes(userInfo.account,'5')">{{countDown || '获取验证码'}}</span>
				</div>
			</div>
			<div class="fl formItem">
				<div class="submitLable">
					<span class="lableText">支付密码：</span>
				</div>
				<div class="submitValue">
					<el-input v-prevent-space maxlength="6"  v-model="userPassword.oldPassword" placeholder="请输入支付密码"></el-input>
				</div>
			</div>
			<div class="fl formItem">
				<div class="submitLable">
					<span class="lableText">确认支付密码：</span>
				</div>
				<div class="submitValue">
					<el-input v-prevent-space maxlength="6"  v-model="userPassword.password" placeholder="请再次输入支付密码"></el-input>
				</div>
			</div>
			<div class="fl formItem">
				<div class="submitLable">&emsp;</div>
				<div class="submitValue">
					<el-button type="primary" v-if="userInfo.payPassword == '' || userInfo.payPassword == null" @click="updatePass">设置密码</el-button>
					<el-button type="primary" v-else @click="updatePass">修改密码</el-button>
				</div>
			</div>
		</div>

		<div class="status" v-if="status == 1 ">
			<img :src="require('@/static/image/mine/status1.png')" style="width: 172px;height: 164px;" />
			<span class="statusTitle">恭喜您！支付密码设置成功</span>
			<el-button type="primary" style="margin-top: 12px;font-size: 22px;" @click="status = 0">返回个人信息</el-button>
		</div>
	</div>
</template>

<script>
	import {
		updatePass,
		updateSend,
		fush
	} from '@/api/index.js'
	export default {
		data() {
			return {
				status: 0,
				// 倒计时
				countDown: 0,
				countdownInterval: null,
				userInfo:{},
				userPassword: {
					code: '',
					type: '2',
					oldPassword: '',
					password: ''
				}
			}
		},
		created() {
			
			fush().then(res => {
				if (res.code == 200) {
					this.userInfo = res.result
				}
			})
		},
		methods:{
			// 修改密码
			updatePass() {
				if (this.userPassword.code == '') {
					this.message('请输入验证码', 'error')
					return
				}
				if(this.userPassword.password.length != 6 || this.userPassword.oldPassword.length != 6){
					this.userPassword.oldPassword=''
					this.userPassword.password=''
					this.message('支付密码必须六位', 'error')
					return
				}
				
				if (this.userPassword.oldPassword == '') {
					this.message('请输入支付密码', 'error')
					return
				}
				if (this.userPassword.password == '') {
					this.message('请再次输入支付密码', 'error')
					return
				}
				if(this.userPassword.oldPassword != this.userPassword.password){
					this.message('两次密码不一致', 'error')
					return
				}
			
				updatePass(this.userPassword).then(res => {
					if(res.code == 200){
						this.status = 1
						this.userPassword.code=''
						this.userPassword.oldPassword=''
						this.userPassword.password=''
						this.countDown = 0;
						clearInterval(this.countdownInterval);
						
					}else{
						this.message('设置失败', 'error')
						this.userPassword.oldPassword=''
						this.userPassword.password=''
					}
					
					// this.startCountdown1()
				})
			},
			getCodes(account, type){
				if (this.countDown > 0) {
					return
				}
				this.getCode(type, account).then(res => {
					this.startCountdown();
				})
			},
			startCountdown() {
				this.countDown = 60; // 初始倒计时时间（秒）  
				this.countdownInterval = setInterval(() => {
					this.countDown--;
					if (this.countDown <= 0) {
						clearInterval(this.countdownInterval);
						this.countDown = 0;
						// 可以选择是否自动重新生成验证码  
						// this.generateCode();  
					}
				}, 1000);
			},
		}
		
	}
</script>

<style scoped lang="scss">
	.roleTitle {
		font-weight: 600;
		font-size: 24px;
		color: #1D223C;
		width: 950px;
		border-bottom: 1px solid #ECECEC;
		height: 60px;
		line-height: 60px;
		padding-left: 30px;
	}

	.balance {
		font-weight: 400;
		font-size: 16px;
		color: #A5A7B1;
	}

	.submitForm {
		margin-top: 50px;

		.formItem {
			margin-bottom: 30px;

			.submitLable {
				width: 200px;
				text-align: right;

				.lableText {
					font-weight: 400;
					font-size: 16px;
					color: #A5A7B1;
					line-height: 30px;
					position: relative;
				}

			}

			.submitValue {
				width: 376px;
				margin: 0 20px;
				position: relative;

				::v-deep .el-input__inner {
					width: 376px;
					height: 46px;
				}

				::v-deep .el-button--primary {
					width: 180px;
					height: 44px;
					// font-weight: 600;
					font-size: 16px;
				}
			}

			.lableNumber {
				margin: 0 20px;


				font-weight: bold;
				font-size: 24px;
				color: #FF5C00;


			}

			.submitRemark {
				width: 40%;
				color: #A5A7B1;
			}
		}
	}

	.getCode {
		position: absolute;
		right: 5px;
		line-height: 46px;
		font-size: 14px;
		color: #0085FF;
		cursor:pointer;
	}

	.getCode::after {
		content: '';
		position: absolute;
		width: 2px;
		height: 20px;
		left: -10px;
		top: 10px;
		background-color: #ECECEC;
	}

	.status {
		display: flex;
		flex-direction: column;
		align-items: center;
		// justify-content: center;
		margin-top: 100px;

		.statusTitle {
			font-weight: 600;
			font-size: 22px;
			color: #1D223C;
			margin-top: 12px;
		}

		.statusDetaile {
			font-weight: 400;
			font-size: 18px;
			color: #A5A7B1;
			margin-top: 12px;
		}
	}
</style>