<template>
	<div>
		<layout-top></layout-top>
		<div class="stepList">
			<el-steps :active="step" align-center>
				<el-step title="选择游戏分类">
					<div slot="icon">
						<div class="step" :class="{'stepAction': step>=1 }">1</div>
					</div>
				</el-step>
				<el-step title="填写分类信息">
					<div slot="icon">
						<div class="step" :class="{'stepAction': step>=2 }">2</div>
					</div>
				</el-step>
				<el-step title="填写商品信息">
					<div slot="icon">
						<div class="step" :class="{'stepAction': step>=3 }">3</div>
					</div>
				</el-step>
				<el-step title="完成商品发布">
					<div slot="icon">
						<div class="step" :class="{'stepAction': step>=4 }">
							✔
						</div>
					</div>
				</el-step>
			</el-steps>
		</div>

		<div class="lableTop">
			<img class="lableImage" :src="require('@/static/image/index/classBg.png')" />
			<el-row style="width: 1200px;margin: 0 auto;">
				<el-col :span="24">
					<div class="lableBg ">
						<img style="width: 80px;height: 80px;border-radius: 10px;margin-right: 15px;"
							:src="info.icon" />
						<div class=" gameClass">
							<div class="lableTitle">{{info.gameName}}</div>
							<div class="lableDetaile">{{info.districtName}}</div>
						</div>
					</div>
				</el-col>
				
			</el-row>
		</div>


		<div style="background-color: #F8F8F8;">
			<el-row style="width: 1200px;margin: 0 auto;">
				
				<el-col :span="24">
					<div class="screen">
						<div class="fl successInfo">
							<img :src="require('@/static/image/index/success.png')"
								style="width: 138px;height: 138px;" />
							<div class="successText1">恭喜您，商品发布成功</div>
							<div class="successText2">感谢您使用本平台出售商品，预祝您的商品早日成交～</div>
							<div style="margin-top: 40px;">
								<el-button class="successBtn" @click="$router.back(-2)" type="primary">继续发布</el-button>
								<el-button class="successBtn" @click="$router.push('/mineIndex/12')">查看已发布的商品</el-button>
							</div>
						</div>
					</div>
				</el-col>
			
			</el-row>
		</div>

		<shopping-card></shopping-card>
	</div>
</template>

<script>
	import layoutTop from '@/components/layoutTop.vue';
	import shoppingCard from '@/components/shoppingCart.vue';

	export default {
		components: {
			layoutTop,
			shoppingCard
		},
		
		data() {
			return {
				step: 4,
				info:{}
			}
		},
		created() {
			this.info = JSON.parse(this.$route.query.info)
		}
	}
</script>

<style scoped lang="scss">
	.stepList {
		width: 600px;
		margin: 30px auto;
	}
	.step {
		width: 27px;
		height: 27px;
		color: #fff;
		// background: #0085FF 0 0 no-repeat transfor;
		text-align: center;
		// transform: rotate(45deg);
		line-height: 27px;
		position: relative;
	}

	.step::before {
		content: "";
		position: absolute;
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
		z-index: -1;
		border-radius: 6px 6px 6px 6px;
		background: #DDDDDD;
		background-size: 100% 100%;
		transform: rotate(45deg);
		// transform: scaleY(-1);

	}

	.stepAction {
		width: 27px;
		height: 27px;
		color: #fff;
		// background: #0085FF 0 0 no-repeat transfor;
		text-align: center;
		// transform: rotate(45deg);
		line-height: 27px;
		position: relative;
	}

	.stepAction::before {
		content: "";
		position: absolute;
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
		z-index: -1;
		border-radius: 6px 6px 6px 6px;
		background: #0085FF;
		background-size: 100% 100%;
		transform: rotate(45deg);
		// transform: scaleY(-1);

	}

	.stepSuccess {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
	}

	::v-deep .el-step__title.is-process {
		color: #C0C4CC;
		font-weight: 500;

	}

	.lableTop {
		position: relative;

		.lableImage {
			width: 100%;
			height:150px;
			position: absolute;
		}
	}

	.lableBg {
		padding-top: 20px;
		height: 180px;
		display: flex;

		.lableTitle {
			font-weight: bold;
			font-size: 24px;
			color: #FFFFFF;
		}

		.lableDetaile {
			font-weight: 400;
			font-size: 16px;
			color: #FFFFFF;
			margin-top: 12px;
		}
	}

	.screen {
		color: #777A8A;
		background: #FFFFFF;
		margin-top: -80px;
		border-radius: 20px 20px 20px 20px;
		margin-bottom: 56px;
		padding-bottom: 20px;
		height: 500px;
		// padding: 0 0 24px;
	}

	.successInfo {
		flex-direction: column;
		justify-content: center;
		align-items: center;
		height: 500px;

		.successText1 {
			font-weight: 600;
			font-size: 22px;
			color: #0085FF;
			line-height: 38px;
			margin: 24px 0 12px;
		}

		.successText2 {
			font-weight: 400;
			font-size: 14px;
			color: #777A8A;
			line-height: 23px;
		}
		.successBtn{
			width: 242px;
			height: 54px;
			border-radius: 8px;
			font-size: 22px;
		}
	}
</style>