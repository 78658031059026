<template>
	<div>
		<div class="roleTitle">收货商品</div>
		<div class="fl search">
			<div class="fl formItem">
				<span class="searchText">游戏区服：</span>
				<div style="position: relative;" class="">
					<el-cascader ref="areaRef" style="width: 208px;" clearable :append-to-body="false"
						v-model="searchAreaId" class="submitValue" :options="gameList"
						:props="districtProps"></el-cascader>
				</div>
			</div>
			<div class="fl formItem" style="margin-left: 50px;">
				<span class="searchText">商品类型：</span>
				<div style="position: relative;" class="">
					<el-cascader ref="areaRef" style="width: 208px;" clearable :append-to-body="false"
						v-model="searchTypeId" class="submitValue" :options="gameList1"
						:props="districtProps1"></el-cascader>
				</div>
			</div>
			<div style="margin-left: 56px;margin-top: -15px;">
				<el-button icon="el-icon-refresh" @click="searchAreaId = [];searchTypeId= []">重置</el-button>
				<el-button type="primary" @click="getList" icon="el-icon-search">搜索</el-button>
			</div>
		</div>

		<div class="roleTitles">
			<el-badge value="" class="titleItem">
				<div @click="setType('')" :class="{'action':search.isOffShelf === ''}">全部商品</div>
			</el-badge>
			<el-badge value="" class="titleItem">
				<div @click="setType(0)" :class="{'action':search.isOffShelf === 0}">收货中</div>
			</el-badge>
			<el-badge value="" class="titleItem">
				<div @click="setType(1)" :class="{'action':search.isOffShelf === 1}">已下架</div>
			</el-badge>
		</div>
		<div class="roleTitles">
			<el-button type="primary" @click="publishShop" icon="el-icon-plus">发布商品</el-button>
		</div>


		<div class="tableList" style="overflow-y: auto;height: 700px; scrollbar-width: none; ">
			<el-table ref="multipleTable" :data="tableList" tooltip-effect="dark" style="width: 990px"
				@selection-change="handleCheckedCitiesChange">
				<el-table-column type="selection" prop="id" width="45">
				</el-table-column>
				<el-table-column width="180">
					<template slot="header">
						<div class="cardPrice">游戏区服/类型</div>
					</template>
					<template slot-scope="scope" >
						<div style="font-size: 12px;">
							{{scope.row.gameId_dictText}}/{{scope.row.districtName}}/{{scope.row.commodityTypeId_dictText}}
							<span v-if="scope.row.childCommodityType != null">/{{scope.row.childCommodityType}}</span>

							<div @click="setItem(scope.row)" style="color: #0085FF;">修改收货设置</div>
						</div>
					</template>
				</el-table-column>
				<el-table-column prop="name" width="120" align="left">
					<template slot="header">
						<div class="cardPrice">总收货量</div>
					</template>
					<template slot-scope="scope">
						<div class="totleInput">
							<el-input v-prevent-space v-model="scope.row.num"></el-input>
						</div>
					</template>
				</el-table-column>

				<el-table-column prop="address" width="200">
					<template slot="header">
						<div class="cardPrice">单价</div>
					</template>
					<template slot-scope="scope">
						<div class="fl" style="font-size: 12px;">
							1元=
							<div class="totleInput">
								<el-input v-prevent-space v-model="scope.row.piecesNum"></el-input>
							</div>
							/{{scope.row.commodityTypeName}}
						</div>
						<div style="font-size: 14px;margin-top: 10px;" class="fl">
							1{{scope.row.commodityTypeName}} = <span
								v-if="scope.row.piecesNum != 0">{{Number(1 / scope.row.piecesNum).toFixed(4)}}</span>
							<span v-else>0</span>元
						</div>
					</template>
				</el-table-column>
				<!-- <el-table-column prop="address" width="150">
					<template slot="header">
						<div class="cardPrice">比例</div>
					</template>
					<template slot-scope="scope">
						<div style="font-size: 12px;">1元 ={{(scope.row.piecesNum / 1).toFixed(4)}}{{scope.row.commodityTypeName}}</div>
						<div style="font-size: 12px;" class="fl">
							1{{scope.row.commodityTypeName}} = <span
								v-if="scope.row.piecesNum != 0">{{Number(1 / scope.row.piecesNum).toFixed(4)}}</span>
							<span v-else>0</span>元
						</div>
					</template>
				</el-table-column> -->
				<el-table-column prop="address" width="120">
					<template slot="header">
						<div class="cardPrice">单笔最小收货量</div>
					</template>
					<template slot-scope="scope">
						<div class="totleInput">
							<el-input v-prevent-space v-model="scope.row.minNum"></el-input>
						</div>
					</template>
				</el-table-column>
				<el-table-column prop="address" width="120">
					<template slot="header">
						<div class="cardPrice">单笔最大收货量</div>
					</template>
					<template slot-scope="scope">
						<div class="totleInput">
							<el-input v-prevent-space v-model="scope.row.maxNum"></el-input>
						</div>
					</template>
				</el-table-column>


				<el-table-column prop="address" width="80">
					<template slot="header">
						<div class="cardPrice">状态</div>
					</template>
					<template slot-scope="scope">
						<div class="fl" v-if="scope.row.isOffShelf == 0">
							<div class="stateColor1">&emsp;</div> 收货中
						</div>
						<div class="fl" v-if="scope.row.isOffShelf == 1">
							<div class="stateColor2">&emsp;</div> 已下架
						</div>
					</template>
				</el-table-column>

				<el-table-column prop="address" width="80" align="right">
					<template slot="header">
						<div class="cardPrice">操作</div>
					</template>
					<template slot-scope="scope">
						<div style="text-align: right;display: flex;flex-wrap: wrap;flex-direction: column;">
							<span slot="reference" @click="txtblur(scope.row)" style="color: #85CE61;">保存</span>

							<el-popconfirm v-if="scope.row.isOffShelf == 1" @confirm="txtblur(scope.row,scope.$index,0)"
								class="idItemHover" title="确定上架么？">
								<span slot="reference" style="color: #000;">上架</span>
							</el-popconfirm>
							<el-popconfirm v-if="scope.row.isOffShelf == 0" @confirm="txtblur(scope.row,scope.$index,1)"
								class="idItemHover" title="确定下架么？">
								<span slot="reference" style="color: #0085FF;">下架</span>
							</el-popconfirm>

							<el-popconfirm @confirm="shopDelete(scope.row)" class="idItemHover" title="确定删除此订单么？">
								<span slot="reference" style="color: red;">删除</span>
							</el-popconfirm>

						</div>
					</template>
				</el-table-column>
			</el-table>

		</div>
		<div class="fl" style="justify-content: space-between;font-size: 18px;margin-top: 20px;padding:0 20px;">
			<div>
				<div class="all">
					<el-button @click="offShelfBatch(0)" class="allIn" style="margin-left: 24px;">批量上架</el-button>
					<el-button @click="offShelfBatch(1)" class="allIn">批量下架</el-button>
				</div>
			</div>
			<el-pagination @size-change="getSizeList" @current-change="getCurrentList" :current-page="search.pageNo"
				:page-sizes="[10, 20, 30, 40]" :page-size="search.pageSize"
				layout="total, sizes, prev, pager, next, jumper" :total="totalNumber">
			</el-pagination>
		</div>


		<el-dialog title="发布商品" :visible.sync="dialogVisible" width="30%" :before-close="handleClose">
			<div class="dialogCenter ">
				<div class="fl formItem">
					<div class="submitLable">
						<span class="lableText must">游戏区服：</span>
					</div>
					<div style="position: relative;">
						<el-cascader ref="areaRef" clearable :append-to-body="false" v-model="areaId"
							class="submitValue" :options="gameList" :props="districtProps"></el-cascader>
					</div>
				</div>
				<div class="fl formItem">
					<div class="submitLable">
						<span class="lableText must">商品类型：</span>
					</div>
					<div style="position: relative;">
						<el-cascader ref="typeRef" @change="typeChange" clearable :append-to-body="false"
							v-model="typeId" class="submitValue" :options="gameList1"
							:props="districtProps1"></el-cascader>
					</div>
				</div>
				<div class="fl formItem" v-if="childCommodityList.length != 0">
					<div class="submitLable">
						<span class="lableText must">商品子类：</span>
					</div>
					<div style="position: relative;">
						<el-select :popper-append-to-body="false" class="submitValue" v-model="items.childCommodityType"
							placeholder="请选择">
							<el-option v-for="item in childCommodityList" :key="item" :label="item" :value="item">
							</el-option>
						</el-select>
					</div>
				</div>
				<div class="fl formItem">
					<div class="submitLable">
						<span class="lableText must">交易备注：</span>
					</div>
					<div class="submitValue">
						<el-input v-prevent-space v-model="items.title" placeholder="请输入"></el-input>
					</div>
				</div>
				<div class="fl formItem">
					<div class="submitLable">
						<span class="lableText must">交易方式：</span>
					</div>
					<div class="submitValue">
						<!-- <el-checkbox-group v-model="items.tradeType" @change="payType"> -->
							<el-checkbox label="1" v-model="payType1">邮寄</el-checkbox>
							<el-checkbox label="2" v-model="payType2">拍卖</el-checkbox>
							<el-checkbox label="3" v-model="payType3">摆摊</el-checkbox>
							<el-checkbox label="4" v-model="payType4">当面</el-checkbox>
						<!-- </el-checkbox-group> -->

					</div>
				</div>
				<div class="fl formItem">
					<div class="submitLable">
						<span class="lableText must">交易时间：</span>
					</div>
					<div class="submitValue dealTime" style="position: relative;">

						<el-time-select style="width: 158px;" :append-to-body="false" v-model="items.startTime"
							:picker-options="{
						    start: '00:00',
						    step: '00:30',
						    end: '24:00'
						  }" placeholder="选择时间">
						</el-time-select>
						<div>-</div>
						<el-time-select style="width: 158px;position: re;" :append-to-body="false"
							v-model="items.endTime" :picker-options="{
						    start: '00:00',
						    step: '00:30',
						    end: '24:00'
						  }" placeholder="选择时间">
						</el-time-select>
					</div>
				</div>
			</div>
			<span slot="footer" class="dialog-footer">
				<el-button @click="dialogVisible = false">取 消</el-button>
				<el-button style="background-color: #0085FF;color: #fff;" @click="shopAdd">确 定</el-button>
			</span>
		</el-dialog>


		<el-dialog title="设置商品" :visible.sync="dialogVisible1" width="30%" :before-close="handleClose">
			<div class="dialogCenter ">
				<div class="fl formItem">
					<div class="submitLable">
						<span class="lableText must">交易备注：</span>
					</div>
					<div class="submitValue">
						<el-input v-prevent-space v-model="setItems.title" placeholder="请输入"></el-input>
					</div>
				</div>
				<div class="fl formItem">
					<div class="submitLable">
						<span class="lableText must">交易方式：</span>
					</div>
					<div class="submitValue">
						<el-checkbox label="1" v-model="payType1">邮寄</el-checkbox>
						<el-checkbox label="2" v-model="payType2">拍卖</el-checkbox>
						<el-checkbox label="3" v-model="payType3">摆摊</el-checkbox>
						<el-checkbox label="4" v-model="payType4">当面</el-checkbox>
					</div>
				</div>
				<div class="fl formItem">
					<div class="submitLable">
						<span class="lableText must">交易时间：</span>
					</div>
					<div class="submitValue dealTime" style="position: relative;">
						<el-time-select style="width: 158px;" :append-to-body="false" popper-class="popperClass"
							v-model="setItems.startTime" :picker-options="{
						    start: '00:00',
						    step: '00:30',
						    end: '24:00'
						  }" placeholder="选择时间">
						</el-time-select>
						<div>-</div>
						<el-time-select style="width: 158px;" :append-to-body="false" popper-class="popperClass"
							v-model="setItems.endTime" :picker-options="{
						    start: '00:00',
						    step: '00:30',
						    end: '24:00'
						  }" placeholder="选择时间">
						</el-time-select>
					</div>
				</div>
			</div>
			<span slot="footer" class="dialog-footer">
				<el-button @click="dialogVisible1 = false">取 消</el-button>
				<el-button style="background-color: #0085FF;color: #fff;" @click="txtblur(setItems)">确 定</el-button>
			</span>
		</el-dialog>
	</div>
</template>

<script>
	import {
		receiveGoodsList,
		receiveGoodsAdd,
		gameList,
		districtList,
		gameCommodityTypes,
		receiveGoodsEdit,
		receiveGoodsDelete,
		receiveGoodsOffShelfBatch
	} from '@/api/index.js'
	export default {
		data() {
			return {
				payType1:false,
				payType2:false,
				payType3:false,
				payType4:false,
				
				actionIndex: 0,
				dialogVisible: false,
				dialogVisible1: false,
				gameList: [],
				gameList1: [],
				searchAreaId: [],
				searchTypeId: [],
				areaId: [],
				typeId: [],
				ids: [],
				cities: [],
				isIndeterminate: false,
				checkAll: false,
				districtProps1: {
					value: 'id',
					label: 'name',
					lazy: true,
					lazyLoad(node, resolve) {

						gameCommodityTypes({
							id: node.value.toString(),
							isReceive: 1
						}).then(res => {
							const {
								level
							} = node;
							let newList = []
							res.result.forEach(data => {

								data.leaf = level

								newList.push(data)
							})
							resolve(newList)
						})
					},
				},
				districtProps: {
					value: 'id',
					label: 'name',
					// leaf:'hasChild',
					lazy: true,
					lazyLoad(node, resolve) {
						console.log(node)
						districtList({
							id: node.value.toString()
						}).then(res => {
							const {
								level
							} = node;
							let newList = []
							res.result.forEach(data => {
								if (data.hasChild == 0) {
									data.leaf = level
								}
								newList.push(data)
							})
							resolve(newList)
						})
					},
				},

				tableList: [],

				search: {
					pageNo: 1,
					pageSize: 10,
					isOffShelf: '',
					gameId: null,
					commodityTypeId: null,

				},
				totalNumber: 0,
				childCommodityList: [],
				setItems: {},
				items: {
					gameId: '',
					districtId: '',
					districtName: '',
					commodityTypeId: '',
					commodityTypeName: '',
					childCommodityType: '',
					gameCommodityCategory: '',

					maxNum: 0,
					minNum: 0,
					num: 0,
					orderNum: 0,
					piecesNum: 0,
					receiveGoodsType: 1,
					tradeType: '',
					startTime: '',
					endTime: '',
					title: ''

				}
			}
		},
		created() {
			this.getList()
			this.getGameList()
		},
		methods: {
			getSizeList(e) {
				this.search.pageSize = e
				this.getList()
			},
			getCurrentList(e) {
				this.search.pageNo = e
				this.getList()
			},
			// 发布商品
			publishShop() {
				this.areaId = []
				this.typeId = []
				// this.items = {}
				this.items.gameId = ''
				this.items.districtId = ''
				this.items.districtName = ''
				this.items.commodityTypeId = ''
				this.items.commodityTypeName = ''
				this.items.childCommodityType = ''
				this.items.gameCommodityCategory = ''
				this.items.startTime = ''
				this.items.endTime = ''
				this.items.title = ''

				this.items.tradeType = ''
				this.items.maxNum = 0
				this.items.minNum = 0
				this.items.num = 0
				this.items.orderNum = 0
				this.items.piecesNum = 0
				this.items.receiveGoodsType = 1
				this.payType1 = false
				this.payType2 = false
				this.payType3 = false
				this.payType4 = false
				this.dialogVisible = true
			},
			// 设置type
			setType(type) {
				this.search.isOffShelf = type
				this.getList()
			},
			// 设置商品
			setItem(item) {
				this.payType1 = false
				this.payType2 = false
				this.payType3 = false
				this.payType4 = false
				if(item.tradeType.indexOf('1') != -1){
					this.payType1 = true
				}
				if(item.tradeType.indexOf('2') != -1){
					this.payType2 = true
				}
				if(item.tradeType.indexOf('3') != -1){
					this.payType3 = true
				}
				if(item.tradeType.indexOf('4') != -1){
					this.payType4 = true
				}
				this.setItems = JSON.parse(JSON.stringify(item))
				this.dialogVisible1 = true
			},
			payType(e){
				console.log(e)
			},
			getList() {
				console.log(this.searchAreaId)
				if (this.searchAreaId.length != 0) {
					this.search.gameId = this.searchAreaId.slice(1, this.searchAreaId.length).join('/')
				} else {
					this.search.gameId = null
				}
				if (this.searchTypeId.length != 0) {
					this.search.commodityTypeId = this.searchTypeId[this.searchTypeId.length - 1]
				} else {
					this.search.commodityTypeId = null
				}

				receiveGoodsList(this.search).then(res => {
					if (res.code == 200) {
						this.tableList = JSON.parse(JSON.stringify(res.result.records))
						
						// res.result.records.forEach((item, index) => {
						
						// 	this.$set(this.tableList[index], 'surplus', Number(item.num) - Number(item.orderNum));
						// })
						this.cities = res.result.records
						this.totalNumber = res.result.total
					}
				})
			},
			shopAdd() {
				this.items.gameId = this.$refs.areaRef.getCheckedNodes()[0].path[0]
				let path = this.$refs.areaRef.getCheckedNodes()[0].path
				let pathLabels = this.$refs.areaRef.getCheckedNodes()[0].pathLabels

				this.items.districtId = path.slice(1, path.length + 1).join('/')
				this.items.districtName = pathLabels.slice(1, pathLabels.length).join('/')
				if (this.$refs.typeRef.getCheckedNodes()[0].data.gameCommodityCategory == undefined) {
					this.message('请选择商品类型', 'error')
					return
				}

				this.items.gameCommodityCategory = this.$refs.typeRef.getCheckedNodes()[0].data.gameCommodityCategory
				this.items.commodityTypeId = this.$refs.typeRef.getCheckedNodes()[0].data.id
				this.items.commodityTypeName = this.$refs.typeRef.getCheckedNodes()[0].data.name
				
				let tradeType=[]
				if(this.payType1){
					tradeType.push('1')
				}
				if(this.payType2){
					tradeType.push('2')
				}
				if(this.payType3){
					tradeType.push('3')
				}
				if(this.payType4){
					tradeType.push('4')
				}
				this.items.tradeType = tradeType.join(',')
				receiveGoodsAdd(this.items).then(res => {
					if (res.code == 200) {
						this.message('添加成功', 'success')
						this.dialogVisible = false
						this.getList()
					}
				})

			},
			// 删除订单
			shopDelete(item) {
				receiveGoodsDelete({
					id: item.id
				}).then(res => {
					if (res.code == 200) {
						this.message('删除成功', 'success')
						this.getList()
					}
				})
			},
			getGameList() {
				// 获取游戏列表
				gameList({
					pageNo: 1,
					pageSize: 1000
				}).then(res => {
					if (res.code == 200) {
						this.gameList = res.result.records
						this.gameList1 = res.result.records

					}
				})
			},
			typeChange() {
				if (this.$refs.typeRef.getCheckedNodes()[0].data.childrenName != null) {
					this.childCommodityList = this.$refs.typeRef.getCheckedNodes()[0].data.childrenName.split(" ")
				} else {
					this.childCommodityList = []
				}
			},
			txtblur(item, index, value) {
				
				if (index != undefined) {
					item.isOffShelf = value
				}else{
					
					let tradeType=[]
					if(this.payType1){
						tradeType.push('1')
					}
					if(this.payType2){
						tradeType.push('2')
					}
					if(this.payType3){
						tradeType.push('3')
					}
					if(this.payType4){
						tradeType.push('4')
					}
					item.tradeType = tradeType.join(',')
				}
				// if (item.surplus == 0 || item.surplus == '') {
				// 	this.message('请填写总收货量', 'error')
				// 	return
				// }
				if (item.piecesNum == 0 || item.piecesNum == '') {
					this.message('请填写单价', 'error')
					return
				}
				if (item.minNum == 0 || item.minNum == '') {
					this.message('请填写最小收货量', 'error')
					return
				}
				if (item.maxNum == 0 || item.maxNum == '') {
					this.message('请填写最大收货量', 'error')
					return
				}

				if (Number(item.maxNum) > Number(item.num)) {
					console.log('true')
					this.message('单笔收货量不能大于总收货量', 'error')
					return
				}

				if (Number(item.minNum) >= Number(item.maxNum)) {
					this.message('单笔最大收货量不得少于单笔最小收货量', 'error')
					return
				}
				// item.num = Number(item.surplus)+Number(item.orderNum)
				
				receiveGoodsEdit(item).then(res => {
					if (res.code == 200) {
						this.message('保存成功', 'success')
						this.dialogVisible1 = false
						this.getList()
					}
				})
				console.log(item)
			},
			handleCheckedCitiesChange(value) {

				this.ids = []
				value.forEach(res => {
					this.ids.push(res.id)
				})
				let checkedCount = value.length;
				this.checkAll = checkedCount === this.cities.length;
				this.isIndeterminate = checkedCount > 0 && checkedCount < this.cities.length;
			},

			offShelfBatch(type) {
				console.log(this.ids)
				receiveGoodsOffShelfBatch({
					ids: this.ids.join(','),
					isOffShelf: type
				}).then(res => {
					if (res.code == 200) {
						this.getList()
						this.message(res.message, 'success')
					} else {
						this.message(res.message, 'error')
					}
				})
			},


		}

	}
</script>

<style lang="scss" scoped>
	::v-deep .el-dialog__header {
		border-bottom: 1px solid #ECECEC;
	}

	::v-deep .el-dialog__footer {
		border-top: 1px solid #ECECEC;
	}

	.roleTitle {
		font-weight: 600;
		font-size: 24px;
		color: #1D223C;
		width: 950px;
		border-bottom: 1px solid #ECECEC;
		height: 60px;
		line-height: 60px;
		padding-left: 42px;
	}

	.search {

		padding: 24px 42px;

		.searchText {
			font-weight: 400;
			font-size: 14px;
			color: #1D223C;
		}
	}

	.roleTitles {

		width: 906px;
		border-bottom: 1px solid #ECECEC;
		height: 67px;
		// line-height: 87px;
		padding-left: 42px;
		padding-right: 42px;
		display: flex;
		align-items: center;

		.titleItem {
			font-weight: 400;
			font-size: 16px;
			position: relative;
			color: #1D223C;
			height: 20px;
			margin-right: 50px;
		}

		.titleItem::after {
			position: absolute;
			width: 1px;
			height: 20px;
			content: '';
			top: 2px;
			right: -25px;
			background-color: #DEDEDE;
		}

		.titleItem:last-child::after {
			width: 0px;
		}
	}

	.action {
		color: #0085FF;
		position: relative;
		// border-bottom: 4px solid #0085FF;
	}

	.action::after {
		position: absolute;
		content: '';
		width: 60px;
		height: 4px;
		background: #0085FF;
		left: 0;
		bottom: -21px;
	}

	.publish {
		padding-left: 42px;
		padding-top: 22px;
	}

	.all {
		margin-top: 16px;

		::v-deep .el-button {
			width: 88px;
			height: 34px;
			padding: 0;
		}
	}

	.tableList {
		margin: 20px 12px;
	}

	::v-deep .el-pagination span:not([class*=suffix]) {
		font-size: 16px;
	}

	::v-deep .el-pager li {
		font-size: 16px;
	}

	.totleInput {

		// border-radius: 4px 4px 4px 4px;
		// border: 1px solid #DEDEDE;
		// font-weight: 400;
		// font-size: 16px;
		// color: #777A8A;
		// text-align: center;
		// line-height: 36px;
		::v-deep .el-input__inner {
			width: 85px;
			height: 30px;
			text-align: center;
		}
	}

	.stateColor1 {
		width: 8px;
		height: 8px;
		background: #19E382;
		border-radius: 50%;
		margin-right: 6px;
	}

	.stateColor2 {
		width: 8px;
		height: 8px;
		background: #777A8A;
		border-radius: 50%;
		margin-right: 6px;
	}

	.formItem {
		margin-bottom: 15px;


		::v-deep .el-select-dropdown {
			position: absolute !important;
			top: auto !important;
			left: auto !important;
		}

		::v-deep .el-cascader__dropdown {
			position: absolute !important;
			top: auto !important;
			left: auto !important;
		}

		::v-deep .el-popper {
			position: absolute !important;
			top: auto !important;
			left: auto !important;
		}

		.submitLable {
			width: 100px;
			text-align: right;

			.lableText {
				font-weight: 400;
				font-size: 16px;
				color: #1D223C;
				line-height: 30px;
				position: relative;
			}

		}

		.submitValue {
			width: 336px;
			margin: 0 10px;
			position: relative;
		}

		.lableNumber {
			margin: 0 20px;


			font-weight: bold;
			font-size: 24px;
			color: #FF5C00;


		}

		.submitRemark {
			// width: 40%;
			color: #A5A7B1;
		}

		.must {
			position: relative;
		}

		.must::after {
			position: absolute;
			content: '*';
			color: #FF5C00;
			top: -5px;
			left: -10px
		}

		.dealTime {
			display: flex;
			align-items: center;
			justify-content: space-between;
		}
	}
	.cardPrice {
		font-weight: bold;
		font-size: 12px;
		color: #1D223C;
	}
</style>