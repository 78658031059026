<template>
	<div style="background-color: #F8F8F8;height: 100vh;">
		<head-bar></head-bar>
		<el-row class="content">
			<el-col :span="2">
				<div>&emsp;</div>
			</el-col>
			<el-col :span="20">
				<div class="main">
					<div style="display: flex;">
						<div class="leftMenu">
							<div class="leftLable" style="height: 10px;">&ensp;</div>
							<el-menu :unique-opened="true" :default-active="defaultActive" class="el-menu-vertical-demo"
								@select="menuSelect" active-text-color="#0085FF">
								<div v-for="(item,index) in routerList" :key="item.id">
									<el-menu-item :index="item.id">
										<span slot="title">{{item.text}}</span>
									</el-menu-item>
								</div>
							</el-menu>
							<div class="leftLable">账户设置</div>
						</div>
						<div>
							<userInfo @change="changeIndex" v-if="defaultActive == 1"></userInfo>
							<realName v-if="defaultActive == 2"></realName>
							<loginPassword v-if="defaultActive == 3"></loginPassword>
							<paymentCode v-if="defaultActive == 4"></paymentCode>
							<phoneBind v-if="defaultActive == 5"></phoneBind>
							<loginType v-if="defaultActive == 6"></loginType>
							
						</div>
					</div>
				</div>
			</el-col>

			<el-col :span="2">
				<div>&emsp;</div>
			</el-col>
		</el-row>

		<shoppingCard></shoppingCard>
		<footer-bars></footer-bars>
	</div>
</template>

<script>
	import headBar from '@/components/HeadBar.vue';
	import shoppingCard from '@/components/shoppingCart.vue';
	import userInfo from '@/views/setting/components/userInfo.vue'
	import realName from '@/views/setting/components/realName.vue'
	import loginPassword from '@/views/setting/components/loginPassword.vue'
	import paymentCode from '@/views/setting/components/paymentCode.vue'
	import phoneBind from '@/views/setting/components/phoneBind.vue'
	import loginType from '@/views/setting/components/loginType.vue'
	import footerBars from '@/components/footerBars.vue';
	import {
		realInfo,
		fush
	
	} from '@/api/index.js'
	export default {
		components: {
			realName,
			userInfo,
			headBar,
			shoppingCard,
			loginPassword,
			paymentCode,
			phoneBind,
			loginType,
			footerBars
		},
		data() {
			return {
				defaultActive: 0,
				routerList: [{
						id: 1,
						text: '个人信息',
						child: []
					},
					{
						id: 2,
						text: '实名认证',
						child: []
					},
					{
						id: 3,
						text: '登录密码',
						child: []
					},
					{
						id: 4,
						text: '支付密码',

					},
					{
						id: 5,
						text: '手机绑定',
						child: []
					},
					{
						id: 6,
						text: '绑定账户',
						child: []
					},


				],
				isReal:false,
				realInfo:{},
				payPassword:'',
				openId:'',
				bindPhone:''
			}
		},
		created() {
			this.defaultActive = Number(this.$route.params.index)
			
		},
		methods: {
			changeIndex(e) {
				this.defaultActive = e
			},
			menuSelect(e) {
				this.defaultActive = e
			}
		}
	}
</script>

<style scoped lang="scss">
	::v-deep .el-menu {
		border: none;
	}

	::v-deep .el-submenu__title {
		font-weight: 400;
		font-size: 18px;
		color: #1D223C;
		background-color: #F8F8F8;
	}





	.dot {
		width: 6px;
		height: 6px;
		background: #777A8A;
		border-radius: 50%;
		margin-right: 11px;
	}





	.main {
		height: 1154px;
		background: #FFFFFF;
		box-shadow: 0px 6px 24px 0px rgba(0, 0, 0, 0.08);
		border-radius: 20px 20px 20px 20px;
		overflow: hidden;
		margin: 13px 0;

		::v-deep .el-menu-item {
			font-weight: 400;
			font-size: 18px;
			height: 68px;
			line-height: 68px;
			color: #777A8A;
			background-color: #F8F8F8;
		}

		::v-deep .el-menu-item.is-active {
			color: #0085FF;
			font-size: 18px;
			background-color: #fff;
			border-left: 4px solid #0085FF;

			.dot {
				background: #0085FF;
			}
		}
	}

	.leftMenu {
		width: 280px;
		height: 1154px;
		background: #F8F8F8;

		.leftLable {


			font-weight: 600;
			font-size: 22px;
			color: #1D223C;
			text-align: center;
			line-height: 94px;

			width: 280px;
			height: 94px;
			border-radius: 0px 0px 0px 0px;
		}

	}

	
</style>