<template>
	<div>
		<div class="roleTitle">
			<el-badge :value="statusNum.notPayNum == 0 ? '' : statusNum.notPayNum" class="titleItem">
				<div @click="flagClick(0)" :class="{'action':search.flag === 0}">待付款</div>
			</el-badge>
			<el-badge :value="statusNum.payNum == 0 ? '' : statusNum.payNum" class="titleItem">
				<div @click="flagClick(1)" :class="{'action':search.flag === 1}">待发货</div>
			</el-badge>
			<el-badge :value="statusNum.notConfirmNum == 0 ? '' : statusNum.notConfirmNum" class="titleItem">
				<div @click="flagClick(2)" :class="{'action':search.flag === 2}">待确认</div>
			</el-badge>
			<el-badge :value="statusNum.notReceiveNum == 0 ? '' : statusNum.notReceiveNum " class="titleItem">
				<div @click="flagClick(3)" :class="{'action':search.flag === 3}">待收货</div>
			</el-badge>
			<el-badge :value="statusNum.completeNum == 0 ?  '' : statusNum.completeNum  " class="titleItem">
				<div @click="flagClick(4)" :class="{'action':search.flag === 4}">已完成</div>
			</el-badge>
			<el-badge :value="statusNum.cancelNum == 0 ? '' : statusNum.cancelNum " class="titleItem">
				<div @click="flagClick(-1)" :class="{'action':search.flag === -1}">已取消</div>
			</el-badge>
		</div>
		<div class="fl search">
			<div class="fl">
				<div class="searchText">游戏区服：</div>
				<div style="position: relative;">
					<el-cascader clearable :append-to-body="false" v-model="districtId" style="width: 206px;"
						:options="gameList" :props="districtProps"></el-cascader>
				</div>
			</div>
			<div class="fl">
				<div class="searchText">商品类型：</div>
				<div style="position: relative;">
					<el-cascader clearable :append-to-body="false" v-model="commodityTypeId" style="width: 206px;"
						:options="gameList1" :props="districtProps1"></el-cascader>
				</div>
			</div>
			<div class="fl">
				<div class="searchText">交易类型：</div>
				<el-select :popper-append-to-body="false" style="width: 200px;" v-model="search.deliveryType"
					placeholder="请选择">
					<el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
					</el-option>
				</el-select>
			</div>
		</div>

		<div class="fl search">
			<div class="fl">
				<div class="searchText">关键字：</div>
				<el-input v-prevent-space v-model="search.title" style="width: 206px;" placeholder="请输入关键字"></el-input>
				<!-- <el-select :popper-append-to-body="false" style="width: 286px;" v-model="value" placeholder="请选择">
					<el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
					</el-option>
				</el-select> -->
			</div>
			<div class="fl">
				<div class="searchText">创建时间：</div>
				<div style="position: relative;">
					<el-date-picker popper-class="popperClass" format="yyyy-MM-dd" style="width: 188px;" value-format="yyyy-MM-dd HH:mm:ss"
						:append-to-body="false" v-model="search.createTimeBegin" type="date" placeholder="选择日期">
					</el-date-picker>
				</div>
				<span style="margin: 0 10px; color: #000;">-</span>
				<div style="position: relative;">
					<el-date-picker popper-class="popperClass" format="yyyy-MM-dd" style="width: 188px;" value-format="yyyy-MM-dd HH:mm:ss"
						:append-to-body="false" v-model="search.createTimeEnd" type="date" placeholder="选择日期">
					</el-date-picker>
				</div>
			</div>
			<div style="margin-left: 11px;">
				<el-button type="primary" @click="getList" icon="el-icon-search">搜索</el-button>
			</div>
		</div>
		<div class="fl" style="justify-content: space-between;font-size: 18px;margin-top: 20px;">
			<div>
			</div>
			<el-pagination @size-change="sizeChange" @current-change="currentChange" :current-page="search.pageNo"
				:page-sizes="[20, 30,50]" :page-size="search.pageSize"
				layout="total, sizes, prev, pager, next, jumper" :total="totalNumber">
			</el-pagination>
		</div>
		<div style="scrollbar-width: none; ">
			<div class="mineOrder" v-for="(item,index) in tableList">
				<div class="orderTop fl">
					<div class="fl" style="padding-right: 24px;">
						<div class="fl" style="margin-left: 24px;">
							<span class="credit">订单编号：</span>
							<span class="credit" style="color: #1D223C;">{{item.id}}</span>
						</div>
						<div class="fl" style="margin-left: 24px;">
							<span class="credit">下单时间：</span>
							<span class="credit" style="color: #1D223C;">{{item.createTime}}</span>
						</div>
					</div>
					<div>
						<div class="fl">
							<span class="credit">保证金：</span>
							<span class="credit"
								style="color: #FF5C00;font-size: 24px;font-weight: bold;">¥{{item.totalPrice}}</span>
						</div>
					</div>
				</div>

				<div class="fl orderBottom">
					<div style="display: flex;margin-left: 24px;">
						<img :src="item.icon" style="width: 80px;height: 80px;border-radius: 12px;" />
						<div style="margin-left: 16px;">
							<div class="infoTitle el1" @click="$router.push('/gameDetaile/'+item.id)">
								<span
									v-if="item.gameCommodityCategory == 5 || item.gameCommodityCategory == 6"
									class="infoAccount">账户</span>
									<span v-else-if="item.deliveryType == 2" class="infoImage">寄售</span>
								<span v-else class="infoImage">担保</span>
								<span>{{item.title}}</span>
							</div>
							<div class="idItem">
								<span>游戏区服：</span>
								<span style="color: #1D223C;">{{item.districtName}}</span>
							</div>
							<div class="idItem">
								<span>商品类型：</span>
								<span style="color: #1D223C;">{{item.commodityTypeName}}</span>
							</div>
						</div>
					</div>
					<div class="idItem infoItem">
						<span v-if="item.status == -1">已取消</span>
						<span v-if="item.status == -2">已拒绝</span>
						<span v-if="item.status == 0">待支付</span>
						<span v-if="item.status == 1">待发货</span>
						<span v-if="item.status == 2">待确认</span>
						<span v-if="item.status == 3">进行中</span>
						<span v-if="item.status == 4">已完成</span>
						<span v-if="item.status == 5">售后中</span>
						<span v-if="item.status == 6">已赔付</span>
						<span v-if="item.status == 7">已退款</span>
					</div>
					<div class="idItem infoItem">保证金￥{{item.practicalPrice}}</div>
					<div class="idItem infoItem">代练佣金¥{{item.price}}</div>
					<div class="state" v-if="item.status == 0">
						<span class="btn1" @click="toPay(item)">立即支付</span>
						<!-- 跳转到客服聊天 -->
						<span class="btn3" @click="toChat(item)">订单详情</span>

						<el-popconfirm @confirm="orderCancel(item)" class="idItem"
							style="width: 128px;text-align: center;margin: 0;" title="确定取消此订单么？">
							<span slot="reference">取消订单</span>
						</el-popconfirm>

					</div>
					<div class="state" v-if="item.status == 1">
						<span class="btn2" @click="remind(item,3)">提醒发货</span>
						<!-- 跳转到客服聊天 -->
						<span class="btn3" @click="toChat(item)">订单详情</span>

						<el-popconfirm @confirm="orderCancel(item)" class="idItem"
							style="width: 128px;text-align: center;margin: 0;" title="确定取消此订单么？">
							<span slot="reference">取消订单</span>
						</el-popconfirm>
					</div>
					<div class="state" v-else-if="item.status == 3 ">
						<span class="btn2" @click="remind(item,2)">提醒收货</span>
						<span class="btn3" @click="toChat(item)">订单详情</span>
					</div>
				</div>
			</div>

			<el-empty v-if="tableList.length == 0" :image-size="200"></el-empty>

		</div>



		


		<!-- <div>
			<noDate></noDate>
		</div> -->
	</div>
</template>

<script>
	import {
		getOrderList,
		getOrderNum,
		gameList,
		districtList,
		gameCommodityTypes,
		cancelOrder,
		voiceCall
	} from '@/api/index.js'
	import noDate from '@/components/noData.vue'
	export default {
		components: {
			noDate
		},
		data() {
			return {
				actionIndex: 0,
				options: [{
					value: '1',
					label: '担保'
				}, {
					value: '2',
					label: '寄售'
				}],
				districtId: [],
				commodityTypeId: [],
				search: {
					pageNo: '1',
					pageSize: '20',
					orderType: '4',
					isTrain: 1,
					flag: '',
					title: '',
					createTimeBegin: '',
					createTimeEnd: '',
					// // 交易类型
					deliveryType: '',
					// // 区服id
					districtId: '',
					// // 商品分类id
					commodityTypeId: null
				},
				totalNumber: 0,
				tableList: [],
				statusNum: {},
				gameList: [],
				gameList1: [],
				districtProps1: {
					value: 'id',
					label: 'name',
					// leaf:'hasChild',
					lazy: true,
					lazyLoad(node, resolve) {
						console.log(node)
						gameCommodityTypes({
							id: node.value.toString()
						}).then(res => {
							const {
								level
							} = node;
							let newList = []
							res.result.forEach(data => {

								data.leaf = level

								newList.push(data)
							})
							resolve(newList)
						})
					},
				},
				districtProps: {
					value: 'id',
					label: 'name',
					// leaf:'hasChild',
					lazy: true,
					lazyLoad(node, resolve) {
						console.log(node)
						districtList({
							id: node.value.toString()
						}).then(res => {
							const {
								level
							} = node;
							let newList = []
							res.result.forEach(data => {
								if (data.hasChild == 0) {
									data.leaf = level
								}
								newList.push(data)
							})
							resolve(newList)
						})
					},
				}
			}
		},
		created() {
			this.getList()
			this.getOrderNums()
			this.getGameList()
		},
		methods: {
			getGameList() {
				// 获取游戏列表
				gameList({
					pageNo: 1,
					pageSize: 1000
				}).then(res => {
					if (res.code == 200) {
						this.gameList = res.result.records
						this.gameList1 = res.result.records

					}
				})
			},
			sizeChange(e) {
				this.search.pageSize = e
				this.search.pageNo = 1
				this.getList()
			},
			currentChange(e) {
				this.search.pageNo = e
				this.getList()
			},
			getOrderNums() {
				getOrderNum({
					orderType: 4,
					isTrain: 1,
				}).then(res => {
					if (res.code == 200) {
						this.statusNum = res.result
					}

				})
			},
			getList() {
				if (this.districtId.length != 0) {
					this.search.districtId = this.districtId[this.districtId.length - 1]
				} else {
					this.search.districtId = null
				}
				if (this.commodityTypeId.length != 0) {
					this.search.commodityTypeId = this.commodityTypeId[this.commodityTypeId.length - 1]
				} else {
					this.search.commodityTypeId = null
				}
				console.log(this.search)
				getOrderList(this.search).then(res => {
					this.tableList = res.result.records
					this.totalNumber = res.result.total
				})
			},
			// 状态搜索
			flagClick(type) {
				this.search.flag = type
				this.getList()
			},
			// 取消订单
			orderCancel(item) {
				console.log(item)
				cancelOrder({
					orderId: item.id,
					reasonType: 1
				}).then(res => {
					if (res.code == 200) {
						this.message('订单取消成功', 'success')
						this.getList()
					}
				})
			},
			toPay(item) {
				console.log(item)
				let info = {
					id: item.payId,
					districtName: item.districtName,
					title: item.title,
					price: item.totalPrice
				}
				// localStorage.setItem('orderPay', JSON.stringify(info));
				this.$router.push({
					path: '/orderPay',
					query: {
						info: JSON.stringify(info)
					}
				})
			},
			// 提醒发货
			remind(item,type) {
				voiceCall({
					id: item.id,
					type: type
				}).then(res => {
					if (res.code == 200) {
						this.message('提醒成功', 'success')
					}
				})
			},
			// 跳转到聊天
			toChat(item) {
				this.$router.push(`/chat/${item.id}`)
			},


		}
	}
</script>

<style scoped lang="scss">
	::v-deep .el-pagination span:not([class*=suffix]) {
		font-size: 16px;
	}

	::v-deep .el-pager li {
		font-size: 16px;
	}

	.roleTitle {

		width: 856px;
		border-bottom: 1px solid #ECECEC;
		height: 57px;
		// line-height: 87px;
		padding-left: 42px;
		padding-right: 42px;
		display: flex;
		align-items: center;
		
		.titleItem {
			font-weight: 400;
			font-size: 16px;
			position: relative;
			color: #1D223C;
			height: 30px;
			margin-right: 50px;
			cursor:pointer;
		}

		.titleItem::after {
			position: absolute;
			width: 1px;
			height: 20px;
			content: '';
			top: 2px;
			right: -25px;
			background-color: #DEDEDE;
		}

		.titleItem:last-child::after {
			width: 0px;
		}
	}

	.search {
		padding: 24px 0 0 0;

		.searchText {
			width: 100px;
			text-align: right;
			font-weight: 400;
			font-size: 14px;
			color: #1D223C;
		}

		::v-deep .popperClass {
			position: absolute !important;
			top: auto !important;
			left: auto !important;
		}

		::v-deep .el-select-dropdown {
			position: absolute !important;
			top: auto !important;
			left: auto !important;
		}

		::v-deep .el-cascader__dropdown {
			position: absolute !important;
			top: auto !important;
			left: auto !important;
		}

		// ::v-deep .el-select{
		// 	position: absolute !important;
		// 	top: auto !important;
		// 	left: auto !important;
		// }
	}

	.cardPrice {
		font-weight: bold;
		font-size: 18px;
		color: #1D223C;
	}

	.action {
		color: #0085FF;
	}

	.mineOrder {
		border: 1px solid #DEDEDE;
		width: 930px;
		border-radius: 8px;
		margin-top: 24px;
		margin-left: 30px;
	}

	.orderTop {
		// width: 1232px;
		padding-right: 24px;
		height: 64px;
		background: #FCFCFC;
		border-radius: 8px 8px 0px 0px;
		border-bottom: 1px solid #DEDEDE;
		justify-content: space-between;

		.credit {
			font-weight: 400;
			font-size: 14px;
			color: #A5A7B1;
			margin-right: 5px;
		}
	}

	.infoTitle {
		font-weight: 600;
		font-size: 14px;
		line-height: 30px;
		color: #000000;
		width: 280px;
		cursor: pointer;
		.infoAccount {
			font-size: 12px;
			background: #FF5C00;
			border-radius: 4px;
			color: #fff;
			padding: 4px 6px;
		}
	}

	.idItem {
		font-weight: 400;
		font-size: 12px;
		color: #A5A7B1;
		margin-top: 8px;
		cursor:pointer;
	}

	.orderBottom {
		height: 108px;
	}

	.infoItem {
		margin: 0;
		width: 130px;
		text-align: center;
	}

	.price {
		font-weight: bold;
		font-size: 20px;
		color: #1D223C;
		width: 130px;
		text-align: center;
	}

	.state {
		width: 128px;
		display: flex;
		flex-direction: column;
		justify-content: center;

		.btn1 {
			width: 128px;
			height: 35px;
			background: #FF5C00;
			border-radius: 6px 6px 6px 6px;

			font-weight: 500;
			font-size: 14px;
			color: #FFFFFF;
			text-align: center;
			line-height: 35px;
			margin-bottom: 8px;
			cursor:pointer;
		}

		.btn2 {
			width: 128px;
			height: 35px;
			background: #19D972;
			border-radius: 6px 6px 6px 6px;
			font-weight: 500;
			font-size: 14px;
			color: #FFFFFF;
			text-align: center;
			line-height: 35px;
			margin-bottom: 8px;
			cursor:pointer;
		}

		.btn3 {
			width: 128px;
			height: 35px;
			background: #fff;
			border-radius: 6px 6px 6px 6px;
			font-weight: 500;
			font-size: 14px;
			color: #000;
			border: 1px solid #DEDEDE;
			text-align: center;
			line-height: 35px;
			margin-bottom: 8px;
			cursor:pointer;
		}
	}
	.infoImage{
		font-size: 12px;
		background: #0085FF;
		border-radius: 4px;
		color: #fff;
		padding: 4px 6px;
		margin-left: 5px;
	}
</style>