<template>
	<div>
		<div class="roleTitle">设置个人信息
			<span class="balance">｜为保护账户安全，建议您设置登录密码</span>
		</div>
		<div style="overflow-y: auto;height: 1100px; scrollbar-width: none; ">
			<div class="fl" style="margin-top: 60px;">
				<div class="formLable">头像：</div>
				<div style="position: relative;">
					<el-image :src="userInfo.portrait || require('@/static/image/index/profile.png')"
						style="width: 88px;height: 88px;border-radius: 50%;" fit="cover">
					</el-image>
			
					<el-upload class="replace" ref="upload" action="https://api.cc007.com/jeecg-boot/sys/common/upload"
						:on-success="handlePictureCardPreview" :show-file-list="false">
						<span>更换</span>
					</el-upload>
			
				</div>
			</div>
			
			<!-- <div class="fl" style="margin-top: 30px;">
				<div class="formLable">用户名：</div>
				<div style="position: relative;font-size: 18px;">
					u_1701076862843_143557
				</div>
			</div> -->
			
			<div class="fl" style="margin-top: 30px;">
				<div class="formLable">昵称：</div>
				<div style="position: relative;font-size: 18px;">
					<el-input v-prevent-space :disabled="nameDisbale" v-model="userInfo.name" placeholder="请输入昵称"></el-input>
					<!-- u_1701076862843_143557 -->
				</div>
				<div class="edit" @click="nameDisbale = false">编辑</div>
			</div>
			
			<div class="fl" style="margin-top: 30px;">
				<div class="formLable">性别：</div>
				<div style="position: relative;font-size: 18px;">
					<el-radio size="medium" v-model="userInfo.sex" :label="1">男生</el-radio>
					<el-radio v-model="userInfo.sex" :label="2">女生</el-radio>
					<el-radio v-model="userInfo.sex" :label="0">保密</el-radio>
				</div>
				<div style="position: relative;font-size: 14px;color: #A5A7B1;margin-left: 16px;">
					仅能设置一次，请认真填写！
				</div>
			</div>
			
			<div class="fl" style="margin-top: 30px;">
				<div class="formLable">生日：</div>
				<div class="fl birthday" style="position: relative;font-size: 18px;">
					<el-input v-prevent-space v-model="year" type="number" placeholder="年份"></el-input>
					<span class="birthdayLable">年</span>
					<el-input v-prevent-space v-model="month" type="number" placeholder="月份"></el-input>
					<span class="birthdayLable">月</span>
					<el-input v-prevent-space v-model="day" type="number" placeholder="日"></el-input>
					<span class="birthdayLable">日</span>
				</div>
			</div>
			
			<div class="fl" style="margin-top: 30px;">
				<div class="formLable">应急手机：</div>
				<div class="fl formPhone" style="position: relative;font-size: 18px;">
					<el-input v-prevent-space v-model="userInfo.phone" type="number" placeholder="请输入您的应急手机号"></el-input>
					<div style="position: relative;font-size: 14px;color: #A5A7B1;margin-left: 16px;white-space: nowrap;">
						当订单预留信息联系不上您时，我们可通过应急联系方式联系您
					</div>
				</div>
			</div>
			<div class="fl" style="margin-top: 30px;">
				<div class="formLable">应急QQ：</div>
				<div class="fl formPhone" style="position: relative;font-size: 18px;">
					<el-input v-prevent-space v-model="userInfo.qq"  placeholder="请输入您的应急QQ"></el-input>
					<div style="position: relative;font-size: 14px;color: #A5A7B1;margin-left: 16px;white-space: nowrap;">
						当订单预留信息联系不上您时，我们可通过应急联系方式联系您
					</div>
				</div>
			</div>
			<div class="fl" style="margin-top: 30px;">
				<div class="formLable">&ensp;</div>
				<div class="fl formPhone" style="position: relative;font-size: 14px;">
					<el-button type="primary" @click="submit">确定修改</el-button>
			
				</div>
			</div>
			
			
		</div>
		
			
		

	</div>

</template>

<script>
	import {
		portrait,
		userUpEdit,
		getUserMessage,
		
	} from '@/api/setting.js'
	import {
		realInfo,
		fush
	
	} from '@/api/index.js'
	export default {
		data() {
			return {
				radio: '1',
				// 生日
				year: '',
				month: '',
				day: '',
				nameDisbale:true,
				userInfo: {
					name:'',
					// 头像
					portrait: '',
					// 性别
					sex: '',
					birthday:'',
					// 应急手机号
					phone: '',
					// 应急qq号
					qq: ''
				},
				isReal:false,
				realInfo:{},
				payPassword:'',
				openId:'',
				bindPhone:''
			}
		},
		created() {
			if (this.$store.state.userInfo != undefined) {
				getUserMessage({
					id: this.$store.state.userInfo.id
				}).then(res => {
					if(res.result.birthday != null && res.result.birthday != ''){
						this.year = res.result.birthday.split('-')[0]
						this.month = res.result.birthday.split('-')[1]
						this.day = res.result.birthday.split('-')[2]
						this.birthday = res.result.birthday
					}
					this.userInfo.portrait = res.result.portrait
					this.userInfo.qq = res.result.qq
					this.userInfo.phone = res.result.phone
					this.userInfo.sex = res.result.sex
					this.userInfo.name = res.result.name
				})
				
				// // 判断是否实名
				// realInfo().then(res => {
				// 	if (res.result == null || res.result.idCardAudit == 0) {
				// 		this.isReal = false
				// 	}else{
				// 		this.realInfo = res.result
				// 		this.isReal = true
				// 	}
				// })
				// fush().then(res => {
				// 	if (res.result.payPassword != undefined && res.result.payPassword != null && res.result.payPassword != '') {
				// 		this.payPassword = res.result.payPassword
				// 	}
				// 	if (res.result.openId != undefined && res.result.openId != null && res.result.openId != '') {
				// 		this.openId = res.result.openId
				// 	}
				// 	this.bindPhone = res.result.phone
				// })
				
			}
			console.log(this.userInfo)

		},
		methods: {
			// 修改用户信息
			submit() {
				if(this.year != '' && this.month  != '' && this.day  != ''){
					this.userInfo.birthday = this.year+'-'+this.month +'-'+this.day
				}
				userUpEdit(this.userInfo).then(res => {
					if(res.code == 200){
						this.message('信息更新成功','success')
					}
					
				})
			},
			// 修改头像
			handlePictureCardPreview(e) {
				portrait({
					portraitUrl: e.result
				}).then(res => {
					this.userInfo.portrait = e.result
					this.message('头像设置成功', 'success')
					this.$store.commit('saveUserInfo', this.userInfo)
				})
			},
			menuSelect(e) {
				this.$emit('change',e)
			}
		}

	}
</script>

<style lang="scss" scoped>
	.roleTitle {
		font-weight: 600;
		font-size: 24px;
		color: #1D223C;
		width: 950px;
		border-bottom: 1px solid #ECECEC;
		height: 60px;
		line-height: 60px;
		padding-left: 42px;
	}

	.balance {
		font-weight: 400;
		font-size: 16px;
		color: #A5A7B1;
	}

	.formLable {
		font-weight: 400;
		font-size: 14px;
		color: #A5A7B1;
		width: 180px;
		text-align: right;
		margin-right: 10px;
	}

	.replace {
		position: absolute;
		bottom: 8px;
		left: 0;
		width: 88px;
		text-align: center;
		font-weight: 400;
		font-size: 16px;
		color: #FFFFFF;
	}

	.edit {
		font-weight: 400;
		font-size: 18px;
		color: #0085FF;
		margin-left: 24px;
		cursor:pointer;
	}

	::v-deep .el-radio__inner {
		width: 20px;
		height: 20px;
	}

	.birthday {
		::v-deep .el-input__inner {
			width: 178px;
			height: 46px;
		}

		.birthdayLable {
			margin: 0 12px;
		}
	}

	.formPhone {
		::v-deep .el-input__inner {
			width: 252px;
			height: 46px;
		}

		::v-deep .el-button--primary {
			width: 160px;
			height: 44px;
			font-weight: 600;
			font-size: 14px;
		}

	}
	.infoList {
		border: 1px solid #EDEDED;
		margin: 50px;
	
		.infoItem {
			width: 1000px;
			justify-content: space-between;
			padding: 30px 30px;
			border-bottom: 1px solid #EDEDED;
	
			.title {
				font-size: 22px;
				color: #000;
				margin-bottom: 15px;
				line-height: 16px;
			}
	
			.detaile {
				color: #888888;
				font-size: 14px;
			}
		}
	
		.itemType {
			color: #666666;
	
			font-size: 14px;
	
		}
	}
</style>