<template>
	<div>
		<div class="roleTitle">收货角色管理</div>
		<div class="fl search">
			<div class="fl">
				<span class="searchText">游戏区服：</span>
				<el-cascader clearable :append-to-body="false" v-model="districtId" style="width: 206px;"
					:options="gameList" :props="districtProps"></el-cascader>
					<el-button @click="getList" type="primary" style="margin-left: 20px;" icon="el-icon-search">搜索</el-button>
			</div>
			
		</div>
		<div class="tableList" style="overflow-y: auto;height: 800px; scrollbar-width: none; ">
			<el-table ref="multipleTable" :data="tableList" tooltip-effect="dark" style="width: 900px"
				>
				<el-table-column type="index" :index="indexMethod" width="50">
					<template slot="header">
						<div class="cardPrice">序号</div>
					</template>
				</el-table-column>
				<el-table-column width="230">
					<template slot="header">
						<div class="cardPrice">游戏名称</div>
					</template>
					<template slot-scope="scope">
						<div>
							{{scope.row.gameName}}
						</div>
					</template>
				</el-table-column>
				<el-table-column prop="name" width="230" align="left">
					<template slot="header">
						<div class="cardPrice">运营商/区服</div>
					</template>
					<template slot-scope="scope">
						<div >{{scope.row.districtName}}</div>
					</template>
				</el-table-column>
				
				<el-table-column prop="address" width="130" align="center">
					<template slot="header">
						<div class="cardPrice">收货角色</div>
					</template>
					<template slot-scope="scope">
						<div >{{scope.row.receiveRole}}</div>
					</template>
				</el-table-column>
				<!-- <el-table-column prop="address" width="230" align="center">
					<template slot="header">
						<div class="cardPrice">角色等级</div>
					</template>
					<template slot-scope="scope">
						<div >{{scope.row.grade}}</div>
					</template>
				</el-table-column> -->
				<el-table-column prop="address"  align="right">
					<template slot="header">
						<div class="cardPrice">操作</div>
					</template>
					<template slot-scope="scope">
						<div  style="text-align: right;">
							<span class="cardDelete" style="color: #0085FF;cursor:pointer;" @click="deleteRole(scope.row)">删除</span>
						</div>
					</template>
				</el-table-column>
			</el-table>
			
			
			
		</div>
		<div class="fl" style="justify-content: space-between;font-size: 18px;margin-top: 20px;padding:0 20px">
			<div>
			</div>
			<el-pagination @size-change="sizeChange" @current-change="currentChange"
				:page-sizes="[10, 20, 30, 40]" :page-size="search.pageSize"
				layout="total, sizes, prev, pager, next, jumper" :total="totalNumber">
			</el-pagination>
		</div>
		
	</div>
</template>

<script>
	import {
		receiveRoleList,
		gameList,
		districtList,
		roleDelete,
		gameCommodityTypes
	} from '@/api/index.js'
	export default {
		data() {
			return {
				
				tableList:[],
				gameList:[],
				districtId:[],
				totalNumber:0,
				search:{
					pageNo:1,
					pageSize:10,
				},
				districtProps: {
					value: 'id',
					label: 'name',
					lazy: true,
					lazyLoad(node, resolve) {
						districtList({
							id: node.value.toString()
						}).then(res => {
							const {
								level
							} = node;
							let newList = []
							res.result.forEach(data => {
								if (data.hasChild == 0) {
									data.leaf = level
								}
								newList.push(data)
							})
							resolve(newList)
						})
					},
				},
			}
		},
		created() {
			this.getList()
			this.getGameList()
		},
		
		methods:{
			getList(){
				let districtId = this.districtId.slice(1,this.districtId.length)
				console.log(districtId)
				receiveRoleList(Object.assign(this.search,{'districtId':districtId.join('/')})).then(res=>{
					if(res.code ==200){
						this.tableList = res.result.records
						this.totalNumber = res.result.total
					}
				})
			},
			// 获取游戏列表
			getGameList() {
				gameList({
					pageNo: 1,
					pageSize: 1000
				}).then(res => {
					if (res.code == 200) {
						this.gameList = res.result.records
			
					}
				})
			},
			// 删除角色
			deleteRole(item){
				console.log(item)
				roleDelete({
					id:item.id
				}).then(res=>{
					if(res.code ==200){
						this.message('删除成功','success')
						this.getList()
					}
				})
			},
			indexMethod(index){
				return index+1
			},
			sizeChange(e) {
				this.search.pageSize = e
				this.search.pageNo = 1
				this.getList()
			},
			currentChange(e) {
				this.search.pageNo = e
				this.getList()
			},
		}
	}
</script>

<style scoped lang="scss">
	.roleTitle {
		font-weight: 600;
		font-size: 24px;
		color: #1D223C;
		width: 906px;
		border-bottom: 1px solid #ECECEC;
		height: 60px;
		line-height: 60px;
		padding-left: 42px;
	}
	.search{
		justify-content: space-between;
		padding: 24px 42px;
		width: 906px;
		.searchText{
			font-weight: 400;
			font-size: 14px;
			color: #1D223C;
		}
	}
	.cardPrice {
		font-weight: bold;
		font-size: 14px;
		color: #1D223C;
	}
	.tableList{
		margin: 0 42px;
	}
	
	::v-deep .el-pagination span:not([class*=suffix]) {
		font-size: 16px;
	}
	
	::v-deep .el-pager li {
		font-size: 16px;
	}
	::v-deep .el-cascader__dropdown {
		position: absolute !important;
		top: auto !important;
		left: auto !important;
	}
</style>