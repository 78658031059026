<template>
	<div style="background-color: #F7F7F7;">
		<div v-if="headShow">
			<head-bar ref="headBar" v-if="headShow"></head-bar>
		</div>

		<!-- 搜索 -->
		<!-- <div style="background-color: #fff;padding-bottom: 80px;">
			
		</div> -->
		<div style="background-color: #fff;padding-bottom: 80px;">
			<el-row style="width: 1200px;margin: 0 auto;">
				<el-col :span="24">
					<div>
						<el-row style="display: flex;align-items: center;">
							<el-col :span="8">
								<div>
									<img :src="require('@/static/image/newImage/logo.png')" style="width: 204px;" />
								</div>
							</el-col>
							<el-col :span="16">
								<div class="fl" style="justify-content: flex-end;">
									<div>
										<div class="search">
											<div class="downList" ref="dialogBoxs">
												<div @click="selectGameShow('selectGame')">
													<div class="searchItem">
														{{selectGame.name || '游戏名称'}}
														<i class="el-icon-caret-bottom"></i>
													</div>
												</div>
												<div @click="selectGameShow('selectGameTypeList')">
													<div class="searchItem">
														{{selectGameTypeList.name || '商品类型'}}

														<i class="el-icon-caret-bottom"></i>
													</div>
												</div>
												<div v-if="sonGameTypeList.length !=0"
													@click="selectGameShow('selectSonGameTypeList')">
													<div class="searchItem">
														{{selectSonGameTypeList.name || '商品子类'}}

														<i class="el-icon-caret-bottom"></i>
													</div>
												</div>
												<div v-if="systemList.length !=0">
													<div class="searchItem">
														{{selectSystem.name || '系统'}}
														<i class="el-icon-caret-bottom"></i>
													</div>
												</div>
												<div v-if="districtList.length !=0">
													<div class="searchItem">
														{{selectDistrict.name || '游戏区服'}}
														<i class="el-icon-caret-bottom"></i>
													</div>
												</div>
												<div v-if="sonDistrictList.length !=0">
													<div class="searchItem">
														{{sonSelectDistrict.name || '游戏大区'}}
														<i class="el-icon-caret-bottom"></i>
													</div>
												</div>


												<div class="dialogBox" v-if="selectShow">
													<div class="searchName">
														<div>
															<div class="typeTop fl">
																<div class="fl">
																	<div class="gameType"
																		@click="selectSearch('','type')"
																		:class="{'gameTypeAction':gameSearch.terminalType == ''}">
																		全部游戏</div>
																	<div class="gameType"
																		@click="selectSearch('1','type')"
																		:class="{'gameTypeAction':gameSearch.terminalType == '1'}">
																		网络游戏</div>
																	<div class="gameType"
																		@click="selectSearch('2','type')"
																		:class="{'gameTypeAction':gameSearch.terminalType == '2'}">
																		手机游戏</div>
																</div>
																<div class="fl">
																	<el-input v-prevent-space placeholder="请输入内容"
																		v-model="gameSearch.name">
																		<i slot="prefix"
																			class="el-input__icon el-icon-search"></i>
																	</el-input>
																	<span @click="getGameList1"
																		style="width: 120px;text-align: center;cursor:pointer;font-size: 14px;">快速搜索</span>
																</div>
															</div>
															<div class="initialList">
																<el-link class="initialItem"
																	@click="selectSearch(item,'initial')"
																	v-for="(item,index) in initial"
																	target="_blank">{{item}}</el-link>

															</div>
														</div>
														<div class="selectList">
															<div class="selectItem" v-for="item in gameList">
																<i class="el-icon-caret-right" style="color: #ccc;"></i>
																<span @click="gameSelect(item)"
																	class="selectSpan">{{item.name}}</span>
															</div>
														</div>
													</div>
												</div>

												<div class="dialogBox" v-if="selectShow1">
													<div class="searchName">
														<div class="fl" style="margin-bottom: 10px;">
															<span class="searchLable">请选择商品类型：</span>
															<span class="searchLable" style="color: #0085FF;">不限</span>
														</div>

														<div class="selectList">
															<div class="selectItem" v-for="item in gameTypeList">
																<i class="el-icon-caret-right" style="color: #ccc;"></i>
																<span @click="gameTypeSelect(item)"
																	class="selectSpan">{{item.name}}</span>
															</div>
															<div style="color: #0085FF;"
																v-if="gameTypeList.length == 0">
																请先选择游戏
															</div>
														</div>
													</div>
												</div>
												<div class="dialogBox" v-if="selectShow2">
													<div class="searchName">
														<div class="fl" style="margin-bottom: 10px;">
															<span class="searchLable">请选择商品子类：</span>
															<span class="searchLable" style="color: #0085FF;">不限</span>
														</div>

														<div class="selectList">
															<div class="selectItem" v-for="item in sonGameTypeList">
																<i class="el-icon-caret-right" style="color: #ccc;"></i>
																<span @click="sonGameTypeSelect(item)"
																	class="selectSpan">{{item}}</span>
															</div>
														</div>
													</div>
												</div>
												<div class="dialogBox" v-if="selectShow3">
													<div class="searchName">
														<div class="fl" style="margin-bottom: 10px;">
															<span class="searchLable">游戏区服：</span>
															<span class="searchLable" style="color: #0085FF;">不限</span>
														</div>

														<div class="selectList">
															<div class="selectItem" v-for="item in districtList">
																<i class="el-icon-caret-right" style="color: #ccc;"></i>
																<span @click="districtSelect(item)"
																	class="selectSpan">{{item.name}}</span>
															</div>
														</div>
													</div>
												</div>
												<div class="dialogBox" v-if="selectShow4">
													<div class="searchName">
														<div class="fl" style="margin-bottom: 10px;">
															<span class="searchLable">游戏大区：</span>
															<span class="searchLable" style="color: #0085FF;">不限</span>
														</div>

														<div class="selectList">
															<div class="selectItem" v-for="item in sonDistrictList">
																<i class="el-icon-caret-right" style="color: #ccc;"></i>
																<span @click="sonDistrictSelect(item)"
																	class="selectSpan">{{item.name}}</span>
															</div>
														</div>
													</div>
												</div>
												<div class="dialogBox" v-if="selectShow5">
													<div class="searchName">
														<div class="fl" style="margin-bottom: 10px;">
															<span class="searchLable">系统：</span>
															<span class="searchLable" style="color: #0085FF;">不限</span>
														</div>

														<div class="selectList">
															<div class="selectItem" v-for="item in systemList">
																<i class="el-icon-caret-right" style="color: #ccc;"></i>
																<span @click="systemSelect(item)"
																	class="selectSpan">{{item.name}}</span>
															</div>
														</div>
													</div>
												</div>
												<div class="searchInput">
													<input v-prevent-space
														style="border: none;font-size: 14px;margin-left: 30px;min-width:100px;background: none;"
														v-model="searchKey" placeholder="搜索商品关键词"></input>
												</div>
											</div>
											<div class="submitSearch" @click="searchShop">
												搜索
											</div>
										</div>
										<div class="hotTag">
											<div class="hotList">
												<div style="margin-right: 5px;">热门搜索:</div>
												<div class="hotItem" @click="toShopLists(item)"
													v-for="(item,index) in hotTagList" :key="index">
													{{item.name}}
												</div>
											</div>
										</div>
									</div>
								</div>
							</el-col>

						</el-row>

					</div>
				</el-col>

			</el-row>
		</div>

		<!-- 热门游戏 -->
		<!-- style="background: linear-gradient( 180deg, #8FB6FF 0%, rgba(143,182,255,0.3) 47%, rgba(143,182,255,0) 100%);background-position: 0px 55px; "> -->
		<div
			:style="{ background: 'linear-gradient('+bannerList[initialIndex].color+',rgba(143,182,255,0) 60%)',backgroundPosition: '0px 55px ',backgroundRepeat:'no-repeat'}">
			<div class="content" style="margin-top: -55px;">
				<el-row>
					<el-col :span="24">
						<div style="display: flex;width: 1200px;justify-content: space-between;">
							<div style="width: 220px;">
								<div class="sort" @mouseleave="handleMouseLeave">
									<div class="sortTop" @click="$router.push('/game/select/0')">
										<p class="topLeft">热门游戏</p>
										<p class="topRight">全部游戏
											<i class="el-icon-arrow-right"></i>
										</p>
									</div>
									<div class="sortItem" @mouseenter="$event=>handleMouseEnter($event,index)"
										v-for="(item,index) in hotSort" :key="index">
										<div style="display: flex;align-items: center;">
											<img :src="item.icon" style="width: 24px;height: 24px;" />
											<div style="margin-left: 5px;">
												<div class="sortTitle el1">{{item.name}}</div>
												<div v-if="item.games.length != 0" style="margin-top: 5px;">
													<span @click="toShopList(item,items)" class="sortTag el1"
														v-for="(items,indexs) in item.games.slice(0, 2)"
														:key="indexs">{{items.name}}</span>
												</div>
											</div>
										</div>
										<i style="color: #dbdbdb;" class="el-icon-arrow-right"></i>
									</div>
									<div class="hoverList" v-show="hoverShow">
										<div class="hoverTitle">
											{{hotSort[hoverIndex].name}}
										</div>
										<div class="hoverTag" v-if="hotSort[hoverIndex].games.length != 0">
											<div class="tagItem el1" @click="toShopList(hotSort[hoverIndex],item)"
												v-for="(item,index) in hotSort[hoverIndex].games">{{item.name}}
											</div>
										</div>
									</div>
								</div>
							</div>
							<div style="width: 710px;">
								<div>
									<div class="centerTop">

										<!-- <div class="centerItem" @click="$router.push('/accountReclamation/recycle')">
											账号回收
										</div> -->
										<div class="centerItem">
											<a href="https://www.cc007.com/#/game/select/0"
												style="text-decoration: none; color:inherit;">挑选商品</a>
										</div>
										<div class="centerItem">
											<a href="https://www.cc007.com/#/game/add/add"
												style="text-decoration: none; color:inherit;">上架商品</a>
										</div>
										<div class="centerItem">
											<a href="https://www.cc007.com/#/gameLeveling"
												style="text-decoration: none; color:inherit;">找代练</a>
										</div>
										<div class="centerItem">
											<a href="https://www.cc007.com/#/serviceCenter/2/0"
												style="text-decoration: none; color:inherit;">帮助中心</a>
										</div>
									</div>
									<div class="centerImage" style="margin-top: 10px;">
										<el-carousel :initial-index="initialIndex" @change="bannerChange"
											:interval="4000" height="450px" arrow="always">
											<el-carousel-item v-for="item in bannerList" :key="item.id">
												<img @click="toLink(item)" :src="item.pic"
													style="border-radius: 5px;width: 100%;height:450px;" />
											</el-carousel-item>
										</el-carousel>
									</div>
								</div>
								<div style="display: flex;margin-top: 20px;justify-content: space-between;">
									<div @click="toLink1(item)" class="functionImage" v-for="(item,index) in funList">
										<img :src="item.pic" />
									</div>
								</div>
							</div>
							<div style="width: 250px;">
								<div style="margin-top: 65px;" v-if="token == undefined">
									<el-card shadow="hover" style="height: 320px;padding: 10px 20px;">
										<div class="loginText">

											<div class="login" :class="{'actionLogin' : loginType == 1}"
												@click="swiperType(1)">账户登录
											</div>
											<div class="login1" :class="{'actionLogin' : loginType == 0}"
												@click="swiperType(0)">短信登录
											</div>
										</div>

										<div v-if="loginType == 0">
											<el-form :validate-on-rule-change="false" :model="loginForm"
												:rules="loginRule" ref="loginForm">
												<el-form-item label="" prop="account">
													<el-input v-prevent-space v-model="loginForm.account" type="number"
														placeholder="手机号">
														<template slot="prepend">+86</template>
													</el-input>
												</el-form-item>
												<el-form-item label="" prop="code">
													<div class="send">
														<el-input v-prevent-space v-model="loginForm.code" type="number"
															placeholder="请输入验证码">
														</el-input>
														<span @click="getCodes('1',loginForm.account)"
															class="getCode">{{ countDown || '获取验证码'}}</span>
													</div>
												</el-form-item>
											</el-form>
										</div>
										<div v-if="loginType == 1">
											<el-form :validate-on-rule-change="false" :model="loginPassForm"
												:rules="loginPassRule" ref="loginPassForm">
												<el-form-item label="" prop="account">
													<el-input v-prevent-space placeholder="手机号" type="number"
														v-model="loginPassForm.account">
														<template slot="prepend">+86</template>
													</el-input>
												</el-form-item>
												<el-form-item label="" prop="password">
													<el-input v-prevent-space v-model="loginPassForm.password"
														show-password placeholder="请输入登录密码">
													</el-input>
												</el-form-item>
											</el-form>
										</div>
										<div class="feature">
											<p class="feature1" @click="$router.push('/login/forget');">忘记密码</p>
											<p class="feature2" @click="$router.push('/login/register');">立即注册</p>
										</div>
										<el-button @click="login"
											style="width: 100%;background-color: #0085FF;border-radius: 8px;height: 40px;font-size: 14px;"
											type="primary">立即登录</el-button>
										<div class="agreement">
											<el-checkbox v-model="checked"></el-checkbox>
											<div style="font-size: 14px;white-space:normal;margin-left: 10px;">
												我已阅读并同意<span style="color: #0085FF;"
													@click="$router.push('/serviceCenter/3/1804450566674083841')">《用户协议》</span>和<span
													style="color: #0085FF;"
													@click="$router.push('/serviceCenter/3/1806708534589366274')">《隐私政策》</span>
											</div>
										</div>

									</el-card>
								</div>

								<div style="margin-top: 65px;height: 340px;display: flex;flex-direction: column;justify-content: space-between;"
									v-else>
									<el-card shadow="hover" style="height: 340px;padding: 10px 20px;">
										<div class="userInfo">
											<div class="fl" style="width:100%;">
												<img @click="$router.push('/mineIndex/1')"
													:src="userInfo.portrait || require('@/static/image/index/profile.png')"
													class="infoImage" />
												<div @click="$router.push('/mineIndex/1')" class="userName "
													style="margin-left: 10px;">
													<div style="color: #0085FF;max-width: 200px;" class="el1">
														{{userInfo.name}}
													</div>
													欢迎回来
												</div>
											</div>




											<div class="userList">
												<div class="userItem">
													<span class="userNumber"
														@click="$router.push('/mineIndex/8')">{{userInfo.allNum || 0}}</span>
													<span class="userText"
														@click="$router.push('/mineIndex/8')">我的买单</span>
													<div class="userBtn" @click="$router.push('/game/select/0')">挑选商品
													</div>
												</div>
												<div class="userItem">
													<span class="userNumber"
														@click="$router.push('/mineIndex/13')">{{userInfo.allNumSale || 0}}</span>
													<span class="userText"
														@click="$router.push('/mineIndex/13')">我的卖单</span>
													<div class="userBtn1" @click="$router.push('/game/add/add')">上架商品
													</div>
												</div>
											</div>

										</div>

										<div class="equityList">
											<div class="equityItem" v-for="(item,index) in equityList">
												<img :src="item.image" style="width: 30px;height: 30px;" />
												<span style="margin-top: 3px;">{{item.text}}</span>
											</div>
										</div>

									</el-card>

								</div>


								<div class="functions">
									<div class="function">
										<div class="lables">
											<span v-if="token == undefined" class="lable" @click="toServiceCenter(0)"
												:class="{'lableAction':lableAction == 0}">网站公告</span>
											<span v-else class="lable" @click="toServiceCenter(0)"
												:class="{'lableAction':lableAction == 0}">新手指南</span>

											<span class="lable" @click="toServiceCenter(2)"
												:class="{'lableAction':lableAction == 2}">用户协议</span>
											<span class="lable" @click="toServiceCenter(1)"
												:class="{'lableAction':lableAction == 1}">帮助中心</span>
										</div>
										<div class="noticeList">
											<div v-if="lableAction == 0 && token == undefined" class="noticeItem"
												v-for="item in noticeList"
												@click="$router.push('/serviceCenter/4/'+item.id)">
												<div class="el1" style="width: 170px;">{{item.title}}</div>
												<span>{{sjVal(item.createTime,6)}}</span>
											</div>


											<div v-if="lableAction == 0 && token != undefined"
												style="margin-top: 10px;">
												<div class="fl">
													<span class="newTitle">买家</span>
													<div class="newItem1">选择</br>商品</div>
													<img :src="require('@/static/image/newImage/icon7.png')"
														style="width:16px;height: 4px;margin-left: 6px;" alt="">
													<div class="newItem1">下单</br>购买</div>
													<img :src="require('@/static/image/newImage/icon7.png')"
														style="width:16px;height: 4px;margin-left: 6px;" alt="">
													<div class="newItem1">收货</div>
												</div>
												<div class="fl" style="margin-top: 30px;">
													<span class="newTitle">卖家</span>
													<div class="newItem">发布</br>商品</div>
													<img :src="require('@/static/image/newImage/icon6.png')"
														style="width:16px;height: 4px;margin-left: 6px;" alt="">
													<div class="newItem">买家</br>下单</div>
													<img :src="require('@/static/image/newImage/icon6.png')"
														style="width:16px;height: 4px;margin-left: 6px;" alt="">
													<div class="newItem">发货</div>
												</div>
											</div>
											
											<div v-if="lableAction == 1" class="noticeItem" v-for="item in commonProblemList"
												@click="$router.push('/serviceCenter/2/'+item.id)">
												<div class="el1" style="width: 170px;">{{item.title}}</div>
												<span>{{sjVal(item.createTime,6)}}</span>
											</div>
											<div v-if="lableAction == 2" class="noticeItem" v-for="item in protocolList"
												@click="$router.push('/serviceCenter/3/'+item.id)">
												<div class="el1" style="width: 170px;">{{item.name}}</div>
												<span>{{sjVal(item.createTime,6)}}</span>
											</div>

										</div>
									</div>
								</div>
							</div>

						</div>
					</el-col>

				</el-row>
			</div>
		</div>
		<div class="content">
			<el-row>

				<el-col :span="24">
					<div class="TopCard ">
						<div class="fl">
							<span class="noticeTh">交易快报</span>
							<img :src="require('@/static/image/newImage/noticeBox.png')"
								style="width:64px;margin-right: 2px;" />
						</div>

						<!-- <div class="noticeBox"
							:style="'width:100%;height:30px;position:relative;overflow:hidden;display:inline-block;vertical-align:middle;'">
							<div :style="'margin-left:'+marginLeft+'px;white-space:nowrap'">
								<span class="showNotice">{{showNotice}}</span>
								<span class="notice">{{notice}}</span>
							</div>
						</div> -->

						<div class="notice-bar-container">
							<div class="notice-bar__wrap">
								<div v-for="(item, index) in noticeData" :key="index" class="notice-bar__wrap_text">
									<span>交易快报:{{ item[0] }}</span>
									<span style="color: #FF5C00;">{{ item[1] }}</span>
								</div>
							</div>
						</div>
					</div>
				</el-col>

			</el-row>
		</div>





		<!-- 热门游戏 -->
		<div style="background-color: #fff;margin-top: 10px;">
			<div class="content">
				<div style="width: 1200px;">
					<el-row>
						<el-col :span="24">
							<div class="title">
								<div class="titleH1">
									<img :src="require('@/static/image/index/left.png')" />
									<span style="margin: 0 20px;">热门游戏</span>
									<img :src="require('@/static/image/index/right.png')" />
								</div>
								<div class="titleH2">告别高手续费，跨时代新平台</div>
							</div>
							<div class="selectGames fl">
								<div class="fl gamedItem" @click="selectTypeGames = 1"
									:class="{'gamedSelct' : selectTypeGames == 1}">
									<img v-if="selectTypeGames == 2" :src="require('@/static/image/newImage/icon1.png')"
										style="width:20px;height: 20px;margin-right: 10px;" />
									<img v-if="selectTypeGames == 1" :src="require('@/static/image/newImage/icon3.png')"
										style="width:20px;height: 20px;margin-right: 10px;" />
									<span>热门端游</span>
								</div>
								<div class="fl gamedItem" @click="selectTypeGames = 2"
									:class="{'gamedSelct' : selectTypeGames == 2}">
									<img v-if="selectTypeGames == 1" :src="require('@/static/image/newImage/icon2.png')"
										style="width:20px;height: 20px;margin-right: 10px;" />
									<img v-if="selectTypeGames == 2" :src="require('@/static/image/newImage/icon4.png')"
										style="width:20px;height: 20px;margin-right: 10px;" />

									<span>热门手游</span>
								</div>
							</div>

							<div class="game" v-if="selectTypeGames == 1">

								<div class="gameRight">
									<div class="rightItem" v-for="item in onlineGameList">
										<img @click="toShopLists(item)"
											style="border-radius: 10px;width:80px;height:80px;" :src="item.icon" />
										<div @click="toShopLists(item)" class="itemName el1">{{item.name}}</div>
									</div>
								</div>
							</div>
							<div class="game" v-if="selectTypeGames == 2">
								<div class="gameRight">
									<div class="rightItem" v-for="item in handGameList">
										<img @click="toShopLists(item)"
											style="border-radius: 10px;width:80px;height:80px;" :src="item.icon" />
										<div @click="toShopLists(item)" class="itemName el1">{{item.name}}</div>
									</div>
								</div>
							</div>


							<div class="showMore">
								查看更多游戏
							</div>
						</el-col>

					</el-row>

				</div>

			</div>
		</div>


		<div style="margin-top: 10px;">
			<div class="content">
				<div style="width: 1200px;">
					<el-row>

						<el-col :span="24">
							<div class="title">
								<div class="titleH1">
									<img :src="require('@/static/image/index/left.png')" />
									<span style="margin: 0 20px;">猜你喜欢</span>
									<img :src="require('@/static/image/index/right.png')" />
								</div>
								<div class="titleH2">海量新商品，好价天天有</div>
							</div>
							<el-carousel height="300px" arrow="always" :autoplay="false">
								<el-carousel-item>
									<div class="fl " style="justify-content: space-between;">
										<div class="xhCard" v-for="item in onelikeList"
											@click="$router.push('/gameDetaile/'+item.id)">
											<img :src="item.banner" style="width: 287px;border-radius: 12px 12px 0 0"
												alt="" />
											<div class="fl xhTitle">
												<div class="el1" style="width:200px;">
													{{item.title}}
												</div>
												<div class="xhArea el1" style="width:80px;">
													{{item.districtName}}
												</div>
											</div>
											<div class="xhPrice">
												¥{{item.price}}
											</div>
										</div>
									</div>
								</el-carousel-item>
								<el-carousel-item>
									<div class="fl " style="justify-content: space-between;">
										<div class="xhCard" v-for="item in twolikeList"
											@click="$router.push('/gameDetaile/'+item.id)">
											<img :src="item.banner" style="width: 287px;border-radius: 12px 12px 0 0"
												alt="" />
											<div class="fl xhTitle">
												<div class="el1" style="width:200px;">
													{{item.title}}
												</div>
												<div class="xhArea el1" style="width:80px;">
													{{item.districtName}}
												</div>
											</div>
											<div class="xhPrice">
												¥{{item.price}}
											</div>
										</div>
									</div>
								</el-carousel-item>
								<el-carousel-item>
									<div class="fl " style="justify-content: space-between;">
										<div class="xhCard" v-for="item in threelikeList"
											@click="$router.push('/gameDetaile/'+item.id)">
											<img :src="item.banner" style="width: 287px;border-radius: 12px 12px 0 0"
												alt="" />
											<div class="fl xhTitle">
												<div class="el1" style="width:200px;">
													{{item.title}}
												</div>
												<div class="xhArea el1" style="width:80px;">
													{{item.districtName}}
												</div>
											</div>
											<div class="xhPrice">
												¥{{item.price}}
											</div>
										</div>
									</div>
								</el-carousel-item>
								<el-carousel-item>
									<div class="fl " style="justify-content: space-between;">
										<div class="xhCard" v-for="item in fourlikeList"
											@click="$router.push('/gameDetaile/'+item.id)">
											<img :src="item.banner" style="width: 287px;border-radius: 12px 12px 0 0"
												alt="" />
											<div class="fl xhTitle">
												<div class="el1" style="width:200px;">
													{{item.title}}
												</div>
												<div class="xhArea el1" style="width:80px;">
													{{item.districtName}}
												</div>
											</div>
											<div class="xhPrice">
												¥{{item.price}}
											</div>
										</div>
									</div>
								</el-carousel-item>

							</el-carousel>



						</el-col>

					</el-row>

				</div>

			</div>
		</div>








		<shopping-card></shopping-card>
		<slidingBlock :shoWData="shoWData" @submit="verifySubmit"></slidingBlock>
	</div>

</template>

<script>
	import {
		login
	} from '@/api/login.js'
	import {
		noticeList,
		gameCommodityCategories,
		gameList,
		gameCommodityTypes,
		districtList,
		userCenter,
		hotList,
		gameBanner,
		tradeList,
		queryPic,
		commonProblemList,
		protocolList,
		likeList
	} from '@/api/index.js'
	import shoppingCard from '@/components/shoppingCart.vue';
	import headBar from '@/components/HeadBar.vue';
	import {
		getToken,
		setToken,
		setCookie,
		getCookie,
		removeToken
	} from '@/utils/auth.js'
	import slidingBlock from '@/components/slidingBlock.vue';
	import {
		EventBus
	} from '@/static/js/event-bus.js';
	export default {
		name: 'home',
		components: {
			shoppingCard,
			headBar,
			slidingBlock
		},
		activated() {
			setTimeout(() => {

				if (getToken() != undefined && getToken() != '' && getToken() != null) {
					this.token = getToken()
					// this.userInfo = this.$store.state.userInfo
					userCenter().then(res => {
						if (res.code == 200) {
							this.userInfo = res.result
						}
					})
				} else {
					this.token = undefined
				}
			}, 200)
		},
		data() {
			return {

				shoWData: false,
				lableAction: 0,
				headShow: true,
				hoverIndex: 0,
				hoverShow: false,
				userInfo: {},
				token: undefined,
				selectShow: false,
				selectShow1: false,
				selectShow2: false,
				selectShow3: false,
				selectShow4: false,
				selectShow5: false,
				// 验证码登录表单
				initialIndex: 0,
				loginForm: {
					account: '',
					code: ''
				},
				// 密码登录表单
				loginPassForm: {
					account: '',
					password: ''
				},
				// 倒计时
				countDown: 0,
				countdownInterval: null,
				// 密码登录验证
				loginPassRule: {
					account: [{
							required: true,
							message: '请输入登录手机号',
							trigger: 'blur'
						},
						{
							required: true,
							pattern: /^1\d{10}$/,
							message: '手机号格式不正确',
							trigger: 'blur'
						}
					],
					password: [{
						required: true,
						message: '请输入密码',
						trigger: 'blur'
					}]
				},
				// 验证码登录验证
				loginRule: {
					account: [{
							required: true,
							message: '请输入登录手机号',
							trigger: 'blur'
						},
						{
							required: true,
							pattern: /^1\d{10}$/,
							message: '手机号格式不正确',
							trigger: 'blur'
						}
					],
					code: [{
						required: true,
						message: '请输入验证码',
						trigger: 'blur'
					}]
				},
				loginType: 1,
				searchKey: '',
				equityList: [{
						image: require('@/static/image/index/equity1.png'),
						text: '交易安全',

					},
					{
						image: require('@/static/image/index/equity2.png'),
						text: '账号包赔'
					},
					{
						image: require('@/static/image/index/equity3.png'),
						text: '24H客服'
					},
					{
						image: require('@/static/image/index/equity4.png'),
						text: '闪电发货'
					},
					{
						image: require('@/static/image/index/equity5.png'),
						text: '资金保证'
					},
					{
						image: require('@/static/image/index/equity6.png'),
						text: '安全注册'
					}
				],
				funList: [],
				// 热门标签
				hotTagList: [],

				// 热门游戏列表
				hotSort: [],
				// 公告列表
				noticeList: [],
				//首字母
				initial: ['热门游戏', 'A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R',
					'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z'
				],
				gameSearch: {
					initial: '',
					isHot: '',
					name: '',
					pageNo: 1,
					pageSize: 1000,
					terminalType: ''
				},
				gameList: [],
				selectGame: {},

				gameTypeList: [],
				selectGameTypeList: {},

				sonGameTypeList: [],
				selectSonGameTypeList: {},

				// 区服列表
				districtList: [],
				selectDistrict: {},

				// 大区
				sonDistrictList: [],
				sonSelectDistrict: {},
				// 系统
				systemList: [],
				selectSystem: {},

				// 轮播图列表
				bannerList: [],


				// 适应屏幕分辨率
				noticeWidth: 700,
				// 公告展示（过长时会重复两遍）
				showNotice: '',
				// 用于公告过长时，获取重复两遍中第一遍的长度
				notice: '',
				// 公告初始位置
				marginLeft: 150,
				noticeData: [],
				checked: false,


				// 网游数据
				onlineGameList: [],
				// 手游数据
				handGameList: [],
				queryPicList: {
					f1: {},
					f1Pics: [],
					f2: {},
					f2Pics: []
				},
				commonProblemList: [],
				protocolList: [],
				selectTypeGames: 1,
				onelikeList: [],
				twolikeList: [],
				threelikeList: [],
				fourlikeList: [],

			}
		},
		created() {
			// 获取公告列表
			this.toServiceCenter(0)
			this.getGameList()
			this.getGameList1()
			this.getHotList()
			this.getGameBanner()
			this.getOnlineGameList()
			this.getQueryPic()
			this.likeList()
		},
		mounted() {
			// ref="dialogBox"
			// 监听全局点击事件
			document.addEventListener('click', (e) => {
				if (e.target.className == 'selectSpan') return
				if (this.$refs.dialogBoxs && !this.$refs.dialogBoxs.contains(e.target)) {
					this.selectShow = false
					this.selectShow1 = false
					this.selectShow2 = false
					this.selectShow3 = false
					this.selectShow4 = false
					this.selectShow5 = false
				}
			});




			tradeList().then(res => {
				if (res.code == 200) {
					this.noticeData = res.result
					// setTimeout(()=>{
					// 	this.noticeData= [...this.noticeData,...this.noticeData]
					// },60000)

					// this.noticeData.forEach((val, index) => {
					// 	if (index === 0) {
					// 		this.showNotice += '交易快报:' + val + 
					// 			'\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0'
					// 	} else {
					// 		this.showNotice += '交易快报:' + val +
					// 			'\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0'
					// 	}
					// })

					// // 公告上面先赋值，再获取宽度
					// this.$nextTick(() => {
					// 	setTimeout(() => {

					// 		if (document.getElementsByClassName('showNotice')[0] == undefined ||
					// 			document.getElementsByClassName('noticeBox')[0] == undefined) {
					// 			return
					// 		}
					// 		// 公告div长度
					// 		var oneNoticeWidth = document.getElementsByClassName('showNotice')[0]
					// 			.offsetWidth

					// 		// 公告外层div长度
					// 		var noticeBoxWidth = document.getElementsByClassName('noticeBox')[0]
					// 			.offsetWidth
					// 		// 一条公告长度太大时，才滚动

					// 		if (oneNoticeWidth > noticeBoxWidth) {
					// 			// 滚动公告需要将公告重复两遍进行滚动，两遍中间需要加空格
					// 			this.notice = this.showNotice +
					// 				'\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0'
					// 			// 上面先赋值，再获取宽度
					// 			setTimeout(() => {
					// 				// 获取一遍加中间空格的长度，即左移时第二遍与第一遍完全重合时的长度
					// 				var oneNoticeAddEmptyWidth = document
					// 					.getElementsByClassName('notice')[0].offsetWidth
					// 				// 公告内容重复两遍
					// 				this.showNotice = this.notice + this.showNotice
					// 				this.timer = setInterval(() => {
					// 					this.marginLeft -= 1
					// 					// 第二遍与第一遍起始位置重合时（第一条已完全移到左侧隐藏），marginLeft置0，即回到第一条
					// 					if (this.marginLeft === (-1) *
					// 						oneNoticeAddEmptyWidth) {
					// 						this.marginLeft = 0
					// 					}
					// 				}, 40)
					// 			}, 10)
					// 		} else { //公告并没有很长时不滚动
					// 			this.marginLeft = 0
					// 		}
					// 	}, 1000)

					// })
				}
			})







		},

		methods: {
			likeList() {
				likeList().then(res => {
					if (res.code == 200) {

						this.onelikeList = res.result.slice(0, 4)
						this.twolikeList = res.result.slice(4, 8)
						this.threelikeList = res.result.slice(8, 12)
						this.fourlikeList = res.result.slice(12, 16)

					}
				})
			},
			toServiceCenter(type) {
				if (type == 0) {
					noticeList({
						pageNo: '1',
						pageSize: '5'
					}).then(res => {
						if (res.code == 200) {
							this.noticeList = res.result.records
						}
					})
				} else if (type == 1) {
					commonProblemList({
						pageNo: '1',
						pageSize: '5'
					}).then(res => {
						if (res.code == 200) {
							this.commonProblemList = res.result.records
						}
					})
				} else if (type == 2) {
					protocolList({
						pageNo: '1',
						pageSize: '5'
					}).then(res => {
						if (res.code == 200) {
							this.protocolList = res.result.records
						}
					})
				}
				this.lableAction = type
			},
			getQueryPic() {
				queryPic().then(res => {
					if (res.code == 200) {
						this.funList = res.result.banner.splice(0, 3)
						// this.queryPicList = res.result
						// this.queryPicList.f1.terminalType = 1
						// this.queryPicList.f2.terminalType = 2
					}
				})
			},
			// 获取网络游戏和手机游戏
			getOnlineGameList() {
				gameList({
					// isHot:0,
					pageNo: 1,
					pageSize: 12,
					terminalType: 1
				}).then(res => {
					if (res.code == 200) {
						this.onlineGameList = res.result.records
					}
				})
				gameList({
					// isHot:0,
					pageNo: 1,
					pageSize: 12,
					terminalType: 2
				}).then(res => {
					if (res.code == 200) {
						this.handGameList = res.result.records
					}
				})
			},
			// 获取轮播图
			getGameBanner() {
				gameBanner().then(res => {
					if (res.code == '200') {
						this.bannerList = res.result
					}
				})
			},
			// 获取热门搜索
			getHotList() {
				hotList({
					pageNo: 1,
					pageSize: 6
				}).then(res => {
					this.hotTagList = res.result.records
				})

			},
			getGameList() {
				gameCommodityCategories({
					pageNo: 1,
					pageSize: 10
				}).then(res => {
					if (res.code == 200) {
						this.hotSort = res.result
					}

				})
			},
			getGameList1() {
				gameList(this.gameSearch).then(res => {
					if (res.code == 200) {
						this.gameList = res.result.records
					}
				})
			},

			// 获取验证码
			getCodes(type, account) {
				if (this.countDown > 0) {
					return
				}
				this.getCode(type, account).then(res => {
					// 开始倒计时
					this.startCountdown();
				})
			},
			startCountdown() {
				this.countDown = 60; // 初始倒计时时间（秒）  
				this.countdownInterval = setInterval(() => {
					this.countDown--;
					if (this.countDown <= 0) {
						clearInterval(this.countdownInterval);
						this.countDown = 0;
						// 可以选择是否自动重新生成验证码  
						// this.generateCode();  
					}
				}, 1000);
			},
			// 切换登录方式
			swiperType(type) {

				this.loginType = type
			},

			// 登录
			login() {
				if (!this.checked) {
					this.message('请阅读并勾选协议', 'error')
					return
				}
				this.shoWData = true

			},
			verifySubmit(e, verSuccess) {
				if (verSuccess) {
					this.shoWData = false
					// 短信登录
					if (this.loginType == 0) {
						this.$refs.loginForm.validate((valid) => {
							if (valid) {
								this.$store.dispatch('loginCode', this.loginForm).then(() => {
									this.$refs.headBar.isLogin()
									this.message('登陆成功', 'success')
									this.token = getToken()
									userCenter().then(res => {
										if (res.code == 200) {
											this.userInfo = res.result
										}
									})

								})
							}
						})

						// 验证码登录
					} else {
						this.$refs.loginPassForm.validate((valid) => {
							if (valid) {
								this.$store.dispatch('login', this.loginPassForm).then(() => {
									this.$refs.headBar.isLogin()
									this.message('登陆成功', 'success')
									this.token = getToken()
									userCenter().then(res => {
										if (res.code == 200) {
											this.userInfo = res.result
										}
									})

								})
							}
						})
					}
				}

			},

			loadMore() {
				const lastIndex = this.noticeList.length - 1;
				const lastItem = this.noticeList[lastIndex];
				const nextIndex = lastItem.id + 1;

				setTimeout(() => {
					const newData = this.noticeList.slice(nextIndex - 1, nextIndex + 9).map(item => {
						return {
							id: item.id,
							content: item.content
						};
					});

					this.noticeList = [...this.noticeList, ...newData];
				}, 1000);
			},
			// 选择游戏的时候清空其他数据
			selectGameShow(type) {
				if (type == 'selectGame') {
					this.selectShow = true
					this.selectShow1 = false
					this.selectShow2 = false
					this.selectShow3 = false
					this.selectShow4 = false
					this.selectShow5 = false
				} else if (type == 'selectGameTypeList') {
					this.selectShow = false
					this.selectShow1 = true
					this.selectShow2 = false
					this.selectShow3 = false
					this.selectShow4 = false
					this.selectShow5 = false
				} else if (type == 'selectSonGameTypeList') {
					this.selectShow = false
					this.selectShow1 = false
					this.selectShow2 = true
					this.selectShow3 = false
					this.selectShow4 = false
					this.selectShow5 = false
				}

			},
			// 选择游戏
			gameSelect(item) {
				this.selectGame = {
					id: item.id,
					name: item.name,
					terminalType: item.terminalType,
					icon: item.icon
				}
				// 获取商品类型
				gameCommodityTypes({
					id: item.id
				}).then(res => {
					if (res.result.length != 0) {
						this.gameTypeList = res.result
						this.selectShow = false
						this.selectShow1 = true

						this.selectGameTypeList = {}

						this.sonGameTypeList = []
						this.selectSonGameTypeList = {}

						this.districtList = []
						this.selectDistrict = {}

						this.sonDistrictList = []
						this.sonSelectDistrict = {}

						this.systemList = []
						this.selectSystem = {}
					} else {
						this.searchShop()
					}

				})
			},
			bannerChange(e) {
				this.initialIndex = e

			},
			// 选择游戏类型
			gameTypeSelect(item) {
				this.selectGameTypeList = {
					id: item.id,
					name: item.name,
				}
				this.systemList = []
				this.selectSystem = {}
				// 如果是手游的话先获取系统
				if (this.selectGame.terminalType == 2) {
					if (item.childrenName != null) {
						this.sonGameTypeList = item.childrenName.split(' ')
						// this.sonGameTypeList=['111','2222']
						this.selectShow1 = false
						this.selectShow2 = true
					} else {
						districtList({
							id: this.selectGame.id
						}).then(res => {
							if (res.result.length != 0) {
								this.systemList = res.result
								this.selectShow1 = false
								this.selectShow2 = false
								this.selectShow5 = true
							} else {
								this.searchShop()
							}

						})
					}
				} else {
					if (item.childrenName != null && item.childrenName != '') {
						this.sonGameTypeList = item.childrenName.split(' ')
						// this.sonGameTypeList=['111','2222']
						this.selectShow1 = false
						this.selectShow2 = true
					} else {
						districtList({
							id: this.selectGame.id
						}).then(res => {
							if (res.result.length != 0) {
								this.districtList = res.result
								this.selectShow2 = false
								this.selectShow1 = false
								this.selectShow3 = true
							} else {
								this.searchShop()
							}

						})
					}
				}


			},
			// 选择系统
			systemSelect(item) {
				this.selectSystem = {
					id: item.id,
					name: item.name,
				}
				districtList({
					id: this.selectSystem.id
				}).then(res => {
					if (res.result.length != 0) {
						this.districtList = res.result
						this.selectShow5 = false
						this.selectShow3 = true
					} else {
						this.searchShop()
					}

				})
			},
			// 选择商品子类
			sonGameTypeSelect(item) {
				this.selectSonGameTypeList = {
					name: item
				}
				if (this.selectGame.terminalType == 2) {
					districtList({
						id: this.selectGame.id
					}).then(res => {
						if (res.result.length != 0) {
							this.systemList = res.result
							this.selectShow2 = false
							this.selectShow5 = true
						} else {
							this.searchShop()
						}

					})
				} else {
					districtList({
						id: this.selectGame.id
					}).then(res => {
						if (res.result.length != 0) {
							this.districtList = res.result
							this.selectShow2 = false
							this.selectShow3 = true
						} else {
							this.searchShop()
						}

					})
				}


			},
			// 选择区服
			districtSelect(item) {
				this.selectDistrict = {
					id: item.id,
					name: item.name,
				}
				districtList({
					id: this.selectDistrict.id
				}).then(res => {
					if (res.result.length != 0) {
						this.sonDistrictList = res.result
						this.selectShow3 = false
						this.selectShow4 = true
					} else {
						this.searchShop()
					}

				})
			},
			// 选择大区
			sonDistrictSelect(item) {
				this.sonSelectDistrict = {
					id: item.id,
					name: item.name,
				}
				this.selectShow4 = false
				this.searchShop()
			},
			// 筛选游戏名称
			selectSearch(value, type) {
				if (type == 'type') {
					this.gameSearch.terminalType = value
				}
				if (type == 'initial') {
					if (value == '热门游戏') {
						this.gameSearch.initial = ''
					} else {
						this.gameSearch.initial = value
					}
				}
				this.getGameList1()
			},

			// 鼠标划过事件
			handleMouseEnter(event, index) {
				this.hoverShow = true
				this.hoverIndex = index
			},
			handleMouseLeave() {
				this.hoverShow = false
			},
			// 微信登录
			weixinLogin() {

				// let url = 'http://'+window.location.host+ '/#/login/login'
				let redirect_uri = encodeURIComponent('http://www.cc007.com/#/login/login')
				let appid = 'wx34ce80f4fc983974'
				window.location.href =
					`https://open.weixin.qq.com/connect/qrconnect?appid=${appid}&redirect_uri=${redirect_uri}&response_type=code&scope=snsapi_login&state=123#wechat_redirect`

			},
			// 跳转到商品列表
			toShopList(item1, item2) {
				// orderType
				let info = {
					id: item2.id,
					terminalType: item2.terminalType,
					name: item2.name,
					icon: item2.icon,
					gameTypeSelect: {
						id: item2.gameTypeId,
						name: item2.gameTypeName
					}
				}
				if (item1.gameCommodityCategory == 1 || item1.gameCommodityCategory == 12) {
					info.orderType = 3
				}else{
					info.orderType = 1
				}
				// localStorage.setItem('gameList', JSON.stringify(info));
				window.open(this.$router.resolve({
					path: '/gameList',
					query: {
						info: JSON.stringify(info)
					}
				}).href, '_blank');
			},
			toShopLists(item) {
				let info = {
					id: item.id,
					terminalType: item.terminalType,
					name: item.name,
					icon: item.icon,
				}
				// localStorage.setItem('gameList', JSON.stringify(info));
				window.open(this.$router.resolve({
					path: '/gameList',
					query: {
						info: JSON.stringify(info)
					}
				}).href, '_blank');

			},
			toShopLists1(item, item1) {

				let info = {
					id: item.id,
					terminalType: item.terminalType,
					name: item.name,
					icon: item.icon,
					gameTypeSelect: {
						id: item1.id,
						name: item1.name
					}
				}
				// localStorage.setItem('gameList', JSON.stringify(info));
				window.open(this.$router.resolve({
					path: '/gameList',
					query: {
						info: JSON.stringify(info)
					}
				}).href, '_blank');
			},
			toShopLists2(item) {
				let info = {
					id: item.gameId,
					terminalType: item.terminalType,
					name: item.name,
					icon: item.icon,
				}
				// localStorage.setItem('gameList', JSON.stringify(info));
				window.open(this.$router.resolve({
					path: '/gameList',
					query: {
						info: JSON.stringify(info)
					}
				}).href, '_blank');

			},
			toLink(item) {

				if (!this.isEmpty(item.url) && item.bannerType == 1) {
					window.open(item.url)
				}

			},
			toLink1(item) {
				if (item.url == 'serve') {
					this.$confirm('是否需要联系在线客服？')
						.then(_ => {

							EventBus.$emit('sericeCard', 'true');

						})
						.catch(_ => {});
					return
				}
				if (!this.isEmpty(item.url)) {
					window.open(item.url)
				}

			},
			searchShop() {
				if (this.searchKey != '') {
					window.open(this.$router.resolve({
						path: '/gameDetaile/' + this.searchKey
					}).href, '_blank');
					return
				}
				if (Object.keys(this.selectGame).length == 0) {
					this.message('请先选择游戏', 'error')
					return
				}

				let info = {
					id: this.selectGame.id,
					name: this.selectGame.name,
					terminalType: this.selectGame.terminalType,
					icon: this.selectGame.icon,
					title: this.searchKey
				}
				if (Object.keys(this.selectGameTypeList).length != 0) {
					info.gameTypeSelect = {
						id: this.selectGameTypeList.id,
						name: this.selectGameTypeList.name
					}
				}
				if (Object.keys(this.selectSonGameTypeList).length != 0) {
					info.gameTypeSonSelect = {
						name: this.selectSonGameTypeList.name
					}
				}

				if (Object.keys(this.selectDistrict).length != 0) {
					info.selectDistrict = {
						id: this.selectDistrict.id,
						name: this.selectDistrict.name
					}
				}
				if (Object.keys(this.sonSelectDistrict).length != 0) {
					info.sonSelectDistrict = {
						id: this.sonSelectDistrict.id,
						name: this.sonSelectDistrict.name
					}
				}
				if (Object.keys(this.selectSystem).length != 0) {
					info.selectSystem = {
						id: this.selectSystem.id,
						name: this.selectSystem.name
					}
				}
				// localStorage.setItem('gameList', JSON.stringify(info));
				window.open(this.$router.resolve({
					path: '/gameList',
					query: {
						info: JSON.stringify(info)
					}
				}).href, '_blank');

			}

		}

	}
</script>

<style lang="scss" scoped>
	::v-deep .el-card {
		border-radius: 10px;
		border: none;
	}

	::v-deep .el-card__body,
	.el-main {
		padding: 0;
	}

	// ::v-deep .el-checkbox__inner {
	// 	width: 20px;
	// 	height: 20px;
	// }

	// ::v-deep .el-input-group{
	// 	border: 1px solid #DEDEDE;
	// }

	.search {
		width: 830px;
		height: 40px;
		background-color: #0085FE;
		border-radius: 5px;
		padding: 4px;
		display: flex;
		align-items: center;
		margin-top: 27px;
		justify-content: space-between;
	}

	.submitSearch {
		width: 100px;
		height: 50px;
		line-height: 50px;
		text-align: center;
		color: #fff;
		font-weight: 600;
		font-size: 16px;
		letter-spacing: 5px;
		cursor: pointer;
	}

	.el-dropdown-link {
		font-size: 18px;
		color: #000;
	}

	.downList {
		display: flex;
		align-items: center;
		background-color: #fff;
		height: 40px;
		// margin-left: 2px;
		// margin-right: 2px;

		width: calc(100% - 80px);
		border-radius: 5px;
		position: relative;
	}

	.dialogBox {
		position: absolute;
		top: 60px;
		width: 800px;
		background-color: #fff;
		z-index: 99999 !important;
		border-radius: 5px;
		padding: 20px;
		box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.5);
	}

	.downItem {
		width: 140px;
		display: flex;
		align-items: center;
		justify-content: center;
	}

	::v-deep .el-input__inner {
		height: 40px;
	}

	::v-deep .el-form-item {
		margin-bottom: 15px;
	}

	.hotList {
		color: #A5A7B1;
		display: flex;
		font-size: 14px;
		margin-top: 10px;
		align-items: center;
		white-space: nowrap;

		.hotItem {
			margin-right: 10px;
			border-radius: 5px;
			border: 1px solid #ECECEC;
			padding: 2px 8px;
			font-size: 12px;
			white-space: nowrap;
			cursor: pointer;
		}

		.hotItem:hover {
			background-color: #0085FE;
			color: #fff;
			border: none;
		}
	}

	.sort {
		background-color: #fff;
		height: 643px;
		// overflow: hidden;
		width: 100%;
		border-radius: 5px;
		position: relative;

		.hoverList {
			position: absolute;
			right: -708px;
			top: 55px;
			background-color: #fff;
			width: 700px;
			height: 583px;
			z-index: 999;
			border: 4px solid #0085FE;

			.hoverTitle {
				font-size: 20px;
				font-weight: 600;
				padding: 20px 30px;
			}

			.hoverTag {
				padding: 20px 40px;
				display: flex;
				flex-wrap: wrap;

				.tagItem {
					width: 16%;
					margin-bottom: 30px;
					margin-right: 5%;
					font-weight: bold;
					font-size: 14px;
					color: #888;
					cursor: pointer;
				}

				.tagItem:nth-child(5n) {
					margin-right: 0;
				}

				.tagItem:hover {
					color: #0085FE;
				}

			}
		}


		.sortTop {
			color: #fff;
			height: 35px;
			background-color: #0085FE;
			border-top-right-radius: 5px;
			border-top-left-radius: 5px;
			display: flex;
			justify-content: space-between;
			align-items: center;
			padding: 10px;

			.topLeft {
				font-weight: bold;
				font-size: 16px;
				letter-spacing: 2px;
			}

			.topRight {
				font-size: 12px;
				opacity: 0.8;
				cursor: pointer;
			}
		}

		.sortItem {
			display: flex;
			align-items: center;
			justify-content: space-between;
			padding: 15px 10px;
			border-bottom: 1px solid #F6F6F6;

			.sortTitle {
				font-size: 16px;
				font-weight: 600;
			}

			.sortTag {
				color: #888;
				font-size: 12px;
				// font-weight: 600;
				margin-right: 6px;
				white-space: nowrap;
				display: inline-block;
				max-width: 100px;
				cursor: pointer;
			}

			.sortTag:hover {
				color: #0085FE;
			}

			// .sortTitle:hover {
			// 	color: #0085FE;
			// }
		}

	}

	.centerTop {
		color: #000;
		height: 25px;
		// width: 300px;
		border-top-right-radius: 5px;
		border-top-left-radius: 5px;
		display: flex;
		// justify-content: space-between;
		// align-items: center;
		padding: 10px;
		font-weight: bold;
		font-size: 18px;

		.centerItem {
			margin-right: 30px;
			font-size: 18px;
		}

		.centerItem:hover {
			color: #0085FE;
			cursor: pointer;
		}

	}

	.loginText {
		display: flex;
		align-items: center;
		font-size: 18px;
		color: #A5A7B1;
		margin-bottom: 20px;
		margin-top: 10px;

		.login {
			margin-right: 20px;
			font-weight: 500;
			cursor: pointer;
		}

		.login1 {
			padding-left: 20px;
			border-left: 3px solid #ECECEC;
			cursor: pointer;
		}

		.actionLogin {
			color: #0085FF;
		}
	}

	.feature {
		display: flex;
		align-items: center;
		justify-content: space-between;
		color: #777A8A;
		font-size: 14px;
		margin-top: -15px;

		.feature2 {
			cursor: pointer;
		}

		.feature2 {
			color: #0085FF;
			cursor: pointer;
		}
	}

	.agreement {
		margin-top: 15px;
		font-size: 16px;
		display: flex;
	}

	.functionImage img {
		border-radius: 5px;
		width: 216px;
		// height: 160px;
		transition: all 0.5s; //控制速度，也可不加
		cursor: pointer;
	}

	.functionImage img:hover {
		transform: scale(1.05); //放大倍数
	}

	.functions {
		margin-top: 20px;
		display: flex;
		justify-content: space-between;



		.function {
			width: 403px;
			height: 221px;
			background-color: #fff;
			border-radius: 5px;
			transition: all 0.5s; //控制速度，也可不加
			overflow: hidden;

			.lables {
				padding: 16px;
				border-bottom: 1px solid #ECECEC;
			}

			.lable {
				font-weight: 600;
				font-size: 14px;
				cursor: pointer;

				margin-right: 15px;

			}

			.lableAction {
				color: #0085FF;
				position: relative;
			}

			.lableAction::after {
				content: "";
				position: absolute;
				height: 2px;
				width: 70px;
				bottom: -16px;
				left: 0px;
				background-color: #0085FE;
			}

			.noticeList {
				padding: 16px;
				overflow-y: scroll;
				height: 211px;

				.noticeItem {
					display: flex;
					justify-content: space-between;
					margin-bottom: 10px;
					font-size: 12px;
					cursor: pointer;
				}

				.noticeItem:hover {
					color: #0085FF;
				}
			}

			.noticeList::-webkit-scrollbar {
				display: none;
			}
		}

		.function:hover {
			transform: scale(1.05); //放大倍数
		}
	}

	.title {
		text-align: center;
		margin-top: 20px;
		margin-bottom: 46px;

		.titleH1 {
			display: flex;
			align-items: center;
			justify-content: center;
			font-weight: bold;
			font-size: 20px;
			color: #1D223C;
			line-height: 42px;

		}

		.titleH2 {

			font-weight: 400;
			font-size: 14px;
			color: #777A8A;
			line-height: 21px;
			margin-top: 8px;

		}
	}

	.gameImage img {
		border-radius: 5px;
		width: 317px;
		height: 460px;
		transition: all 0.5s; //控制速度，也可不加
	}

	.gameImage img:hover {
		transform: scale(1.04); //放大倍数
	}

	::v-deep .el-carousel__button {
		background-color: #0085FF;
		height: 4px;
		// border-radius: 10px;
		// color: #0085FF;
	}

	// ::v-deep .el-carousel__indicators--outside button {
	// 	background-color: #0085FF;
	// 	height: 4px;
	// 	border-radius: 10px;
	// }

	.game {

		display: flex;
		justify-content: space-between;
		// width: 1920px;
		// height: 536px;
		// align-items: center;

		// .gameLeft {
		// 	// width: 320px;
		// 	height: 536px;

		// }

		.gameRight {
			display: flex;
			flex-wrap: wrap;
			// align-items: center;
			// justify-content: space-between;
			// margin-left: 20px;
			// height: 400px;
			// width: 1200px;

			.rightItem {
				width: 200px;
				height: 124px;
				// margin-right: 12px;
				background: #FFFFFF;
				border-radius: 12px 12px 12px 12px;
				// border: 1px solid #ECECEC;
				display: flex;
				flex-direction: column;
				align-items: center;
				// justify-content: center;
				margin-top: 15px;
				cursor: pointer;

				.rightItem img {
					width: 50px;
					height: 50px;
					border-radius: 50px;
				}

				.itemName {
					margin: 12px;
					font-weight: bold;
					font-size: 14px;
					width: 160px;
					text-align: center;
				}

				.itemTag {
					font-weight: 400;
					font-size: 14px;
					color: #A5A7B1;

					.itemTags:hover {
						color: #FF5B01;
					}

				}
			}

			// .rightItem:hover {
			// 	background: linear-gradient(180deg, #03B5FF 0%, #0085FF 100%);

			// }

			// .rightItem:hover div {
			// 	color: #fff;

			// }
		}
	}

	.showMore {
		border: 1px solid #0085FF;
		color: #0085FF;
		font-size: 15px;
		width: 158px;
		height: 39px;
		border-radius: 8px 8px 8px 8px;
		text-align: center;
		line-height: 39px;
		margin: 10px auto;

	}

	.showMore:hover {
		background-color: rgba(102, 177, 255, .2);
		// color: #fff;
	}

	.sellItem {
		img {
			transition: all 0.5s; //控制速度，也可不加
		}

		img:hover {
			transform: scale(1.04); //放大倍数
		}

		.sellTitle {
			padding: 20px;
			display: flex;
			justify-content: space-between;
			font-size: 18px;
			font-weight: bold;
			border-bottom: 1px solid #ECECEC;
		}

		.sellPrice {
			padding: 20px;
			font-weight: bold;
			font-size: 20px;
			color: #FF5C00;
			line-height: 28px;

		}
	}

	.getCode {
		position: absolute;
		// top: 7px;
		right: 5px;
		// line-height: 80px;
		font-size: 12px;
		color: #0085FF;
	}

	.getCode::after {
		content: '';
		position: absolute;
		width: 2px;
		height: 20px;
		left: -10px;
		top: 10px;
		background-color: #ECECEC;
	}

	.userInfo {
		display: flex;
		flex-direction: column;
		align-items: center;
		cursor: pointer;

		.infoImage {
			width: 50px;
			height: 50px;
			border-radius: 50%;
		}

		.userName {
			font-weight: 400;
			font-size: 14px;
			margin-top: 12px;
		}

		.userList {
			display: flex;
			justify-content: space-between;
			width: 100%;

			.userItem {
				display: flex;
				flex-direction: column;
				align-items: center;
				width: 50%;
				margin-top: 10px;

				.userNumber {
					font-weight: bold;
					font-size: 20px;
					color: #1D223C;
				}

				.userText {
					font-weight: 400;
					font-size: 14px;
					color: #777A8A;
					margin-top: 6px;
				}

				.userBtn {
					margin-top: 14px;
					width: 94px;
					height: 38px;
					background: #0085FF;
					border-radius: 8px 8px 8px 8px;
					font-weight: 600;
					font-size: 14px;
					color: #FFFFFF;
					line-height: 38px;
					text-align: center;

				}

				.userBtn1 {
					margin-top: 14px;
					width: 94px;
					height: 38px;
					background: #00E0B5;
					border-radius: 8px 8px 8px 8px;
					font-weight: 600;
					font-size: 14px;
					color: #FFFFFF;
					line-height: 38px;
					text-align: center;
				}
			}
		}
	}

	.equityList {
		display: flex;
		justify-content: space-between;
		flex-wrap: wrap;
		align-items: center;
		margin-top: 30px;

		.equityItem {
			width: 30%;
			display: flex;
			font-size: 12px;
			flex-direction: column;
			align-items: center;
			margin-bottom: 10px;
		}
	}

	.searchItem {
		min-width: 90px;
		text-align: center;
		white-space: nowrap;
		font-size: 14px;
		cursor: pointer;
	}



	.searchName {
		padding: 0 20px;

		.searchLable {
			color: #000;
			opacity: 0.8;
			font-size: 14px;
		}

		.searchTag {
			font-size: 14px;
			margin-left: 10px;
		}
	}

	.typeTop {
		height: 35px;
		background-color: #FAFAFA;
		justify-content: space-between;

		::v-deep .el-input__inner {
			height: 35px;
		}

		.gameType {
			width: 150px;
			font-size: 14px;
			line-height: 35px;
			text-align: center;
			cursor: pointer;
		}

		.gameTypeAction {
			background-color: #0085FE;
			color: #fff;
		}
	}

	.selectList {
		margin-top: 10px;
		display: flex;
		flex-wrap: wrap;

		.selectItem {
			width: 190px;
			margin-bottom: 20px;
			// color: #0085FE;
			font-size: 14px;
		}

		.selectSpan {
			cursor: pointer;
		}

		.selectSpan:hover {
			background-color: #0085FE;
			color: #fff;
		}

	}

	.initialList {
		padding: 10px 0;
		display: flex;
		justify-content: space-between;

		.initialItem {
			font-size: 14px;

		}
	}


	/* 公告card */
	.TopCard .el-card__body {
		padding: 0 10px
	}

	.showNotice {
		font-weight: 500;
		font-size: 14px;
		color: red;
	}

	.TopCard {
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: center;
		height: 52px;
		// width: 2000px;
	}

	.floor {
		display: flex;
		justify-content: space-between;
		align-items: center;

		.floorLeft {
			font-weight: bold;
			display: flex;
			font-size: 26px;

		}

		.floorRight {
			display: flex;
			align-items: center;
			// margin-right: 80px;
			color: #666;
		}

		.floorRight:hover {
			color: #FF5B01;
		}
	}

	.noticeTh {
		font-weight: bold;
		font-size: 14px;
		color: #1D223C;
		white-space: nowrap;
		margin-right: 20px;
	}

	// /* 主内容宽度 */
	// .content {
	// 	margin: 0 auto;
	// 	width: 1920px;

	// 	font-family: 'PingFangSC';
	// }

	// /* 宽度低于1920，给主体内容左右加留白 */
	// @media screen and (max-width: 1920px) {
	// 	.content {
	// 		overflow: hidden;
	// 		overflow-x: auto;
	// 	}
	// }
	.selectGames {
		width: 556px;
		height: 58px;
		border-radius: 12px 12px 12px 12px;
		margin: 0 auto;
		border: 1px solid #ECECEC;
		margin-bottom: 30px;
		margin-top: -20px;

		.gamedItem {
			width: 278px;
			display: flex;

			justify-content: center;
		}

		.gamedSelct {
			background: #0085FF;
			border-radius: 12px 12px 12px 12px;
			height: 58px;
			color: #fff;
		}
	}

	.xhCard {
		width: 287px;
		// height: 360px;
		background: #FFFFFF;
		border-radius: 12px 12px 12px 12px;

		.xhTitle {
			font-weight: 400;
			font-size: 14px;
			color: #1D223C;
			padding: 10px;
			justify-content: space-between;
			border-bottom: 1px solid #ECECEC;
		}

		.xhArea {
			font-weight: 400;
			font-size: 14px;
			color: #A5A7B1;
		}

		.xhPrice {
			font-weight: bold;
			font-size: 24px;
			color: #FF5C00;
			padding: 10px;
		}
	}

	.mians {
		background-position: 0px 55px;
	}

	.newTitle {
		font-weight: 400;
		font-size: 13px;
		color: #777A8A;
	}

	.newItem {
		width: 40px;
		height: 40px;
		background: #FFFAF4;
		border-radius: 8px 8px 8px 8px;
		border: 1px solid #FF7104;
		display: flex;
		align-items: center;
		font-size: 12px;
		justify-content: center;
		margin-left: 5px;
	}

	.newItem1 {
		width: 40px;
		height: 40px;
		background: #F1F9FF;
		border-radius: 8px 8px 8px 8px;
		border: 1px solid #0992FF;
		font-size: 12px;
		display: flex;
		align-items: center;
		justify-content: center;
		margin-left: 5px;
	}



	.notic-bar {
		display: flex;
		background: #67c23a;
		margin: 5px;
		border-radius: 5px;
		padding: 2px 5px;
	}

	.notice-bar-container {
		display: flex;
		width: calc(100% - 30px);
		height: 20px;
		overflow: hidden;
		margin-left: 5px;
	}

	.notice-img {
		width: 20px;
		height: 20px;
	}

	.notice-bar__wrap {
		margin-left: 10px;
		display: flex;
		flex-wrap: nowrap;
		animation: move 80s linear infinite;
		line-height: 20px;
		color: #000;

		.notice-bar__wrap_text {
			width: max-content;
			min-width: 100px;
			margin-right: 150px;
		}
	}

	@keyframes move {
		0% {
			transform: translateX(30%);
		}

		100% {
			transform: translateX(-100%);
		}
	}
</style>