<template>
	<div style="display: flex;flex-direction: column;">
		<layout-top></layout-top>
		<div class="lableTop">
			<img class="lableImage" :src="require('@/static/image/index/classBg.png')" />
			<el-row>
				<el-col :span="2">
					<div>&emsp;</div>
				</el-col>
				<el-col :span="20">
					<div class="lableBg">
						<img style="width: 100px;height: 100px;border-radius: 10px;margin-right: 15px;"
							:src="require('@/static/testImage/gameItem.png')" />
						<div class=" gameClass">
							<div class="lableTitle">游戏分类</div>
							<div class="lableDetaile">海量新游戏 等您来选</div>
						</div>
					</div>
				</el-col>
				<el-col :span="2">
					<div>&emsp;</div>
				</el-col>
			</el-row>
		</div>

		<div class="lableTop">
			<el-row>
				<el-col :span="2">
					<div>&emsp;</div>
				</el-col>
				<el-col :span="20">
					<div class="screen">
						<div class="fl screenTop">
							<div class="fl breadcrumb">
								<span>你的位置:</span>
								<el-breadcrumb>
									<el-breadcrumb-item>首页</el-breadcrumb-item>
									<el-breadcrumb-item>游戏分类</el-breadcrumb-item>
									<el-breadcrumb-item>商品列表</el-breadcrumb-item>
								</el-breadcrumb>
							</div>
							<div class="fl">
								<div>为您找到: 地下城与勇士-陕西区-陕西2/3区-游戏账号交易相关记录<span style="color: #FF5C00;">518</span>条</div>
								<div class="screenDown" @click="screenShow =  !screenShow">
									收起筛选<i class="el-icon-arrow-down"></i>
								</div>
							</div>
						</div>
						<el-collapse-transition>
							<div v-if="screenShow">
								<div class="screenItem fl">
									<div class="screenLable">您的选择:</div>
									<div class="screenOptions">
										<el-tag type="info" closable>游戏账户</el-tag>
										<el-tag type="info" closable>新疆区</el-tag>
										<el-tag type="info" closable>新疆1区</el-tag>
										<el-tag type="info" closable>85-99级</el-tag>
									</div>
								</div>
								<div class="screenItem fl">
									<div class="screenLable">商品类型:</div>
									<div class="screenOptions">
										<span class="optionsItem" :class="{'optionsAction':shopTypeIndex == index}"
											v-for="(item,index) in shopType">{{item}}</span>
									</div>
								</div>
								<div class="screenItem fl">
									<div class="screenLable">跨区选择:</div>
									<div class="screenOptions">
										<span class="optionsItem" :class="{'optionsAction':acrossTypeIndex == index}"
											v-for="(item,index) in acrossType">{{item}}</span>
									</div>
								</div>

								<div class="screenItem fl">
									<div class="screenLable">其他选项:</div>
									<div class="screenOptions">
										<div class="fl ">
											<div class="otherItem" v-for="(item,index) in otherList" :key="index">
												<el-dropdown>
													<span class="el-dropdown-link">
														{{item.title}}<i class="el-icon-arrow-down el-icon--right"></i>
													</span>
													<el-dropdown-menu :append-to-body="false" slot="dropdown"
														placement="bottom">
														<el-dropdown-item v-for="(items,indexs) in item.child"
															:key="indexs">{{items}}</el-dropdown-item>
													</el-dropdown-menu>
												</el-dropdown>
											</div>
										</div>
									</div>
								</div>
							</div>
						</el-collapse-transition>

					</div>

				</el-col>
				<el-col :span="2">
					<div>&emsp;</div>
				</el-col>
			</el-row>
		</div>


		<div class="lableTop">
			<el-col :span="2">
				<div>&emsp;</div>
			</el-col>
			<el-col :span="20">
				<div style="display: flex;justify-content: space-between;">
					
					<!-- 平台收货 -->
					<div class="mainRight">
						<div class="rightTop fl">
							<div class="th1 fl">
								<img :src="require('@/static/image/index/hotShop1.png')"
									style="width: 32px;height: 32px;margin-right: 8px;" />
								<span class="th1Text">平台收货</span>
					
							</div>
							<div class="th2">
								<span class="thText">比例</span>
							</div>
							<div class="th3">
								<span class="thText">收货数量</span>
							</div>
							<div class="th4">
								<span class="thText">出货数量</span>
							</div>
							<div class="th5">
								<span class="thText">服务保证</span>
							</div>
							<!-- <div class="th6">
								<span class="th6Text">我要入驻</span>
							</div> -->
						</div>
						<div class="hotGoodList">
							<div class="hotGoodItem fl">
								<div class="th1 ">
									
									<div class="gotArea" style="text-align: left;margin-top: 0;">湖北区｜湖北1区
										<span class="serverSide">跨一</span>
									</div>
									<div class="hotInfoTag">
										<span>发货均时：</span>
										<span style="color:#FF5C00;font-weight: 600;">5分钟</span>
									</div>
									<div class="hotInfoTag">
										<span>无货赔付率：</span>
										<span style="color:#FF5C00;font-weight: 600;">0.00%</span>
									</div>
									
								</div>
								<div class="th2">
									<div class="tagList">
										<span class="tagItem">拍卖</span>
										<span class="tagItem">摆摊</span>
									</div>
									<div class="discount">1元=75.0385万金币</div>
									<div class="discount1">0.0133元/万金币</div>
								</div>
								<div class="th3">
									<div class="hotInfoTag" style="color:#A5A7B1;"><span style="color: #FF5C00;font-weight: 600;">9999999</span>万金币</div>
									<div class="hotInfoTag" style="color:#A5A7B1;">起收：≥29100万金币</div>
									<div class="hotInfoTag" style="color:#A5A7B1;">最高：≤400000万金币</div>
								</div>
								<div class="th4">
									
									<div class="fl">
										<div class="inputNumber" style="margin:10px 0;">
											<input v-prevent-space type="text" />
											<span class="unit">万金币</span>
										</div>
										<div>&emsp;=</div>
										<div class="hotInfoTag" style="margin-top: 0;">
											<span style="color:#FF5C00;font-weight: 600;">&emsp;20.00元</span>
										</div>
									</div>
								</div>
								<div class="th5">
									<div class="th5Text fl">
										<img :src="require('@/static/image/index/pei1.png')"
											style="width: 24px;height: 24px;margin-right: 8px;" />
										<span>无货赔付</span>
									</div>
									<div class="th5Text fl">
										<img :src="require('@/static/image/index/chao.png')"
											style="width: 24px;height: 24px;margin-right: 8px;" />
										<span>超时赔付</span>
									</div>
								</div>
								<div class="th6" @click="$router.push('/gameDetaile')">
									<div class="th6Submit">立即购买</div>
								</div>
							</div>
						</div>
					</div>
					
					
					
					
					<!-- 热卖商城 -->
					<!-- <div class="mainRight">
						<div class="rightTop fl">
							<div class="th1 fl">
								<img :src="require('@/static/image/index/hotShop1.png')"
									style="width: 32px;height: 32px;margin-right: 8px;" />
								<span class="th1Text">热卖商城</span>

							</div>
							<div class="th2">
								<span class="thText">商品信息</span>
							</div>
							<div class="th3">
								<span class="thText">比例</span>
							</div>
							<div class="th4">
								<span class="thText">购买数量</span>
							</div>
							<div class="th5">
								<span class="thText">服务保证</span>
							</div>
							<div class="th6">
								<span class="th6Text">我要入驻</span>
							</div>
						</div>
						<div class="hotGoodList">
							<div class="hotGoodItem fl">
								<div class="th1 ">
									<img :src="require('@/static/image/index/hotGoodImage.png')"
										style="width: 86px;height: 86px;" />
									<div class="hotGoodTitle el1">商家名称超商家名称超商家名称超商家名称超...</div>
								</div>
								<div class="th2">
									<div class="gotArea" style="text-align: left;margin-top: 0;">湖北区｜湖北1区
										<span class="serverSide">跨一</span>
									</div>
									<div class="hotInfoTag">
										<span>发货均时：</span>
										<span style="color:#FF5C00;font-weight: 600;">5分钟</span>
									</div>
									<div class="hotInfoTag">
										<span>无货赔付率：</span>
										<span style="color:#FF5C00;font-weight: 600;">0.00%</span>
									</div>
								</div>
								<div class="th3">
									<div class="th3top">
										<div class="th3Image">
											<img :src="require('@/static/image/index/ratio.png')"
												style="width: 24px;height:24px;" />
										</div>
										<span style="margin-left: 10px;">比例最佳</span>
									</div>
									<div class="hotInfoTag" style="color:#FF5C00;font-weight: 600;">1元=75.0385万金币</div>
									<div class="hotInfoTag" style="color:#777A8A;font-weight: 600;">0.0133元/万金币</div>
								</div>
								<div class="th4">
									<div class="hotType fl">
										<div class="typeAction">按数量买</div>
										<div>按金额买</div>
									</div>
									<div class="fl">
										<div class="inputNumber" style="margin:10px 0;">
											<input type="text" />
											<span class="unit">万金币</span>
										</div>
										<div>&emsp;=</div>
										<div class="hotInfoTag" style="margin-top: 0;">
											<span style="color:#FF5C00;font-weight: 600;">&emsp;20.00元</span>
										</div>
									</div>

									<div class="hotInfoTag">
										<span>库存数量：</span>
										<span style="color:#FF5C00;font-weight: 600;">220000万金币</span>
									</div>
								</div>
								<div class="th5">
									<div class="th5Text fl">
										<img :src="require('@/static/image/index/pei1.png')"
											style="width: 24px;height: 24px;margin-right: 8px;" />
										<span>无货赔付</span>
									</div>
									<div class="th5Text fl">
										<img :src="require('@/static/image/index/chao.png')"
											style="width: 24px;height: 24px;margin-right: 8px;" />
										<span>超时赔付</span>
									</div>
								</div>
								<div class="th6">
									<div class="th6Submit">立即购买</div>
								</div>
							</div>
						</div>
					</div> -->
					
				</div>
				<div class="fl" style="justify-content: space-between;font-size: 18px;margin-top: 20px;">
					<div>为您找到
						<span style="color:#FF5C00;">518</span>
						条记录
					</div>
					<el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
						:current-page="currentPage4" :page-sizes="[10, 20, 30, 40]" :page-size="10"
						layout="total, sizes, prev, pager, next, jumper" :total="400">
					</el-pagination>
				</div>
			</el-col>
			<el-col :span="2">
				<div>&emsp;</div>
			</el-col>
		</div>

		<shopping-card></shopping-card>
	</div>
</template>

<script>
	import layoutTop from '@/components/layoutTop.vue';
	import shoppingCard from '@/components/shoppingCart.vue';
	export default {
		components: {
			layoutTop,
			shoppingCard
		},
		data() {
			return {
				screenShow: true,
				shopType: ['全部', '游戏账号', '游戏币', '矛盾的结晶体', '2023金秋礼包', '2023夏日套', '无瑕的协调结晶体'],
				shopTypeIndex: 1,
				acrossType: ['跨一', '跨二', '跨三', '跨四', '跨五', '跨六'],
				acrossTypeIndex: 1,
				otherList: [{
						title: '其他选项1',
						child: ['菜单1', '菜单2', '菜单3', '菜单4', '菜单5']
					},
					{
						title: '其他选项2',
						child: ['菜单1', '菜单2', '菜单3', '菜单4', '菜单5']
					}
				]
			}
		},
		mounted() {
			this.$nextTick(() => {
				this.$refs.dcPopover.updatePopper() // 注意主要是这里
			})
		}

	}
</script>

<style lang="scss" scoped>
	.lableTop {
		position: relative;

		.lableImage {
			width: 100%;
			height:300px;
			position: absolute;
		}
	}

	.lableBg {
		padding-top: 40px;
		height: 220px;
		display: flex;

		.lableTitle {
			font-weight: bold;
			font-size: 32px;
			color: #FFFFFF;
		}

		.lableDetaile {
			font-weight: 400;
			font-size: 20px;
			color: #FFFFFF;
			margin-top: 12px;
		}
	}

	.breadcrumb {
		align-items: center;
		font-size: 18px;
	}

	::v-deep .el-breadcrumb {
		font-size: 18px;
	}

	::v-deep .el-breadcrumb__separator {
		margin: 0 5px;
	}

	::v-deep .el-breadcrumb__item:last-child .el-breadcrumb__inner {
		color: #000;
		font-weight: bold;
	}

	.screen {
		color: #777A8A;
		background: #FFFFFF;

		margin-top: -80px;
		border-radius: 20px 20px 20px 20px;
		// padding: 0 0 24px;
	}

	.screenTop {
		justify-content: space-between;
		border-bottom: 1px solid #ECECEC;
		padding: 20px;
	}

	.screenDown {
		border-radius: 4px 4px 4px 4px;
		border: 1px solid #ECECEC;
		padding: 4px 8px;
		margin-left: 10px;
	}

	::v-deep .el-tag.el-tag--info .el-tag__close {
		color: #FF5C00;
	}

	::v-deep .el-tag.el-tag--info .el-tag__close:hover {
		background-color: rgba(255, 92, 0, .3);
	}

	.screenItem {
		color: #A5A7B1;
		align-items: center;
		min-height: 64px;

		.screenLable {
			width: 140px;

			background: #F7F7F7;
			text-align: center;
			line-height: 64px;
			font-size: 18px;
		}

		.screenOptions {
			margin-left: 20px;
		}
	}

	.el-tag {
		margin-right: 20px;
		font-size: 18px;
	}

	.optionsItem {
		margin-right: 25px;
		font-size: 18px;
		color: #1D223C;
		padding: 5px 8px;
	}

	.optionsAction {
		background: #0085FF;
		border-radius: 24px 24px 24px 24px;
		color: #fff;
	}

	.el-dropdown-link {
		font-size: 18px;
		color: #000;
	}

	.otherItem {
		margin-right: 25px;
	}

	.sortList {
		height: 33px;
		background: #FFFFFF;
		border-radius: 4px;
		border: 1px solid #ECECEC;
		overflow: hidden;

		.sortItem {
			font-weight: 400;
			font-size: 18px;
			color: #1D223C;
			height: 33px;
			line-height: 33px;
			padding: 0 8px;
			border-right: 1px solid #ECECEC;
			display: flex;
			align-items: center;
			justify-content: center;
		}

		.sortItem:nth-child(3) {
			border: none;
		}

		.sortActin {
			background: #0085FF;
			color: #fff;
		}
	}

	.screenPrice {
		display: flex;
		align-items: center;
		margin-left: 24px;

		.price1 {
			width: 128px;
			background-color: #fff;
			border: 1px solid #ECECEC;
		}

		.price {
			width: 76px;
			border-right: 1px solid #ECECEC;
			background-color: #fff;
			height: 30px;
			line-height: 30px;
			border-radius: 4px 4px 4px 4px;

			input {
				width: 50px;
			}
		}

		.price2 {
			width: 76px;
			border: 1px solid #ECECEC;
			height: 30px;
			line-height: 30px;
			border-radius: 4px 4px 4px 4px;
			background-color: #fff;

			input {
				width: 50px;
			}
		}
	}

	.publish {
		display: flex;
		flex-direction: column;
		width: 164px;
		height: 48px;
		background: #FF5C00;
		border-radius: 4px 4px 4px 4px;
		align-items: center;
		justify-content: center;
		color: #fff;
	}

	.guarantee {
		padding: 20px;
		width: 100%;
		font-size: 18px;
		background: #FFFFFF;
		border-bottom: 1px solid #ececec;
		align-items: center;
	}

	.infoTitle {
		font-weight: 600;
		font-size: 22px;
		line-height: 30px;
		color: #000000;
		width: 100%;
		.infoAccount {
			font-size: 16px;
			background: #FF5C00;
			border-radius: 4px;
			color: #fff;
			padding: 4px 6px;
		}

		.infoImage {
			font-size: 16px;
			background: #0085FF;
			border-radius: 4px;
			color: #fff;
			padding: 4px 6px;
			margin-left: 5px;
		}
	}

	.infoTitle:hover {
		color: #FF5C00;
	}

	.infoSpan {
		line-height: 34px;

		.spanItem {
			height: 32px;
			font-weight: 400;
			font-size: 16px;
			color: #777A8A;
			background: #F5F5F5;
			border-radius: 2px 2px 2px 2px;
			padding: 5px 4px;
			margin-right: 10px;
		}
	}

	.serverSide {
		font-size: 16px;
		color: #FFE350;
		line-height: 19px;
		background-color: #1B2948;
		padding: 2px 4px;
		border-radius: 2px;
		position: relative;
		margin-left: 10px;

	}

	.serverSide::before {
		content: "";
		position: absolute;
		top: 8px;
		left: -4px;
		transform: rotate(-45deg);
		border-top: 10px solid #1B2948;
		/* 三角形的高度和颜色 */
		border-right: 10px solid transparent;
		/* 三角形的宽度 */
	}


	.area {
		font-weight: 400;
		font-size: 16px;
		color: #777A8A;
		margin-top: 10px;
	}

	.accountPrice {
		color: #FF5C00;
		font-weight: bold;
		font-size: 28px;
		width: 15%;
		text-align: center;
	}

	.accountNumber {
		color: #000;
		font-weight: bold;
		font-size: 28px;
		text-align: center;
		width: 15%;
	}

	.accountService {
		width: 15%;
		margin: 0 auto;
		font-size: 18px;
		color: #1D223C;
	}

	.accountPay {
		display: flex;

		.accountDetaile {
			width: 128px;
			height: 44px;
			background: #FFFFFF;
			border-radius: 4px 4px 4px 4px;
			border: 1px solid #FF5C00;
			font-size: 20px;
			color: #FF5C00;
			text-align: center;
			line-height: 44px;
		}

		.accountDetaile:hover {
			background-color: #FF5C00;
			color: #fff;
		}

		.addCard {
			width: 24px;
			height: 24px;
			background: #FFFFFF;
			border-radius: 4px 4px 4px 4px;
			border: 1px solid #FF5C00;
			padding: 10px;
			margin-left: 10px;
		}
	}

	::v-deep .el-pagination span:not([class*=suffix]) {
		font-size: 18px;
	}

	::v-deep .el-pager li {
		font-size: 18px;
	}

	::v-deep .el-dropdown-menu {
		min-width: 120px;
		position: absolute !important;
		top: 35px !important;
		// left: -45px !important;
	}


	.popTitle {
		font-weight: 600;
		font-size: 20px;
		color: #000000;
		border-bottom: 1px solid #ECECEC;
		padding-bottom: 10px;
	}

	.popLable {
		font-weight: 400;
		font-size: 18px;
		color: #A5A7B1;
		line-height: 32px;
		text-align: left;
		width: 200px;
	}

	.popValue {
		font-weight: 600;
		font-size: 18px;
		color: #1D223C;
		line-height: 32px;
		text-align: left;
	}

	.shopLeft {
		// width: 161px;
		// height: 872px;
		// background: #FFFFFF;
		// border-radius: 8px 8px 8px 8px;
		// border: 1px solid #FF5C00;
	}



	.hotMain{
		width: 280px;
		background: #FFFFFF;
		border-radius: 8px 8px 8px 8px;
		border: 1px solid #FF5C00;

		.mainLeft {
			height: 32px;
			background: #FFF4E8;
			padding: 16px;
			justify-content: space-between;
			border-radius: 8px 8px 0 0;
			font-weight: 600;
			font-size: 18px;
			color: #FF5C00;
		}

		.topRight {
			width: 88px;
			height: 33px;
			background: #FF5C00;
			border-radius: 6px 6px 6px 6px;
			font-weight: 400;
			font-size: 16px;
			color: #FFFFFF;
			line-height: 21px;
			text-align: center;
			line-height: 33px;

		}
	}
	


	.gotArea {
		font-weight: 600;
		font-size: 18px;
		color: #000000;
		text-align: center;
		margin-top: 24px;
	}

	.tagList {
		// display: flex;
		// justify-content: center;
		margin-top: 8px;

		.tagItem {
			font-weight: 500;
			font-size: 16px;
			color: #0085FF;
			padding: 1px 5px;
			border-radius: 4px 4px 4px 4px;
			border: 1px solid #0085FF;
			margin-right: 8px;
		}
	}

	.discount {
		font-weight: 600;
		font-size: 18px;
		color: #FF5C00;
		// text-align: center;
		margin-top: 16px;
	}

	.discount1 {
		font-weight: 500;
		font-size: 16px;
		color: #777A8A;
		// text-align: center;
		margin-top: 8px;
	}

	.collectNumber {
		margin: 10px 16px 8px;
		font-weight: 400;
		font-size: 16px;
		color: #A5A7B1;
		line-height: 19px;

	}

	.inputNumber {
		border: 1px solid #DEDEDE;
		width: 249px;
		display: flex;
		justify-content: space-between;
		margin: 0 auto;

		input {
			border: none;
		}

		.unit {
			width: 74px;
			height: 46px;
			background: #F7F7F7;
			border-radius: 0px 4px 4px 0px;
			border: 1px solid #DEDEDE;
			text-align: center;
			line-height: 46px;

			font-weight: 600;
			font-size: 16px;
			color: #000000;
		}
	}

	.sellSubmit {
		width: 248px;
		height: 42px;
		background: #FF5C00;
		border-radius: 6px 6px 6px 6px;
		font-weight: 500;
		font-size: 18px;
		color: #FFFFFF;
		text-align: center;
		margin: 16px;
	}

	.takeItem {
		position: relative;
	}

	.takeItem::after {
		position: absolute;
		bottom: 0;
		left: 16px;
		content: '';
		width: 248px;
		height: 1px;
		background-color: #ECECEC;
	}

	.mainRight {
		width: 100%;
		border: 1px solid #FF5C00;
		border-radius: 8px 8px 8px 8px;

		.th1 {
			width: 15%;
		}

		.th2 {
			width: 20%;
		}

		.th3 {
			width: 15%;
		}

		.th4 {
			width: 30%;
		}

		.th5 {
			width: 10%;
		}

		.th6 {
			text-align: right;
			width: 10%;
		}

		.rightTop {
			// width: 100%;
			height: 64px;
			background: #FFF4E8;
			padding: 0 20px;
			border-radius: 8px 8px 0px 0px;
		}

		.th1Text {
			font-weight: 600;
			font-size: 20px;
			color: #FF5C00;
			// margin-right: 24px;
		}

		.th6Text {
			width: 88px;
			height: 33px;
			background: #FF5C00;
			border-radius: 6px 6px 6px 6px;
			font-size: 16px;
			color: #FFFFFF;
			padding: 3px 8px;


		}

		.thText {
			font-weight: 600;
			font-size: 20px;
			color: #1D223C;
		}
	}

	.hotGoodList {
		.hotGoodItem {
			padding: 20px;
			border-bottom: 1px solid #ECECEC;

			.hotGoodTitle {
				width: 105px;
				margin-top: 12px;
				font-weight: 600;
				font-size: 16px;
				color: #1D223C;
			}

			.hotInfoTag {
				font-weight: 400;
				font-size: 16px;
				color: #A5A7B1;
				margin-top: 10px;
			}

			.th5Text {
				font-weight: 400;
				font-size: 18px;
				color: #1D223C;
				margin-bottom: 12px;
			}

			.th6Submit {
				width: 128px;
				height: 42px;
				background: #FF5C00;
				border-radius: 6px 6px 6px 6px;
				font-weight: 500;
				font-size: 18px;
				color: #FFFFFF;
				line-height: 42px;
				text-align: center;

			}

			.th3top {
				display: flex;
				width: 119px;
				height: 35px;
				background: #FFFFFF;
				border-radius: 4px 4px 4px 4px;
				border: 1px solid #FF5C00;
				font-weight: 600;
				font-size: 16px;
				color: #FF5C00;
				line-height: 35px;

			}

			.th3Image {
				width: 35px;
				height: 35px;
				background: #FF5C00;
				border-radius: 3px 0px 0px 3px;
				display: flex;
				align-items: center;
				justify-content: center;
			}

			.hotType {
				width: 152px;
				height: 32px;
				background: #FFFFFF;
				border-radius: 4px 4px 4px 4px;
				border: 1px solid #FF5C00;

				div {
					width: 50%;
					text-align: center;
					font-weight: 500;
					font-size: 16px;
					color: #A5A7B1;

				}

				.typeAction {
					background-color: #FF5C00;
					color: #fff;
					height: 32px;
					line-height: 32px;
				}
			}
		}
		
		.hotGoodItem:nth-child(n){
			border: none;
		}
	}

	.hotLeft {
		width: 141px;
		border: 1px solid #FF5C00;
		background: #FFFFFF;
		border-radius: 8px 8px 8px 8px;
		margin-left: 10px;

		.leftTop {
			width: 121px;
			height: 64px;
			background: #FFF4E8;
			border-radius: 8px 8px 0 0;


			font-weight: 600;
			font-size: 18px;
			color: #FF5C00;
			line-height: 64px;
			padding-left: 20px;


		}
	}

	.shopItem {
		padding: 16px;

		.shopName {
			font-weight: 600;
			width: 110px;
			font-size: 16px;
			color: #000000;

		}
	}

	.toDetaile {
		display: flex;
		justify-content: center;
		align-items: center;
		font-weight: 400;
		font-size: 16px;
		color: #0085FF;
		text-align: center;

	}
	.seeAll{
		font-weight: 400;
		font-size: 16px;
		color: #FF5C00;
		text-align: center;
	}
</style>