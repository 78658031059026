<template>
	<div>
		<div class="roleTitle">第三方快捷登录
			<span class="balance">｜绑定后，可以使用它们登录到统一的平台账户</span>
		</div>
		<div class="bindItem fl">
			<div class="itemLeft fl" v-if="userInfo.qqOpenId == null">
				<img :src="require('@/static/image/mine/qq.png')" style="width: 50px;height: 50px;" />
				<div class="leftText">
					<span class="leftTitle">绑定QQ</span>
					<span class="leftDetaile">绑定QQ以后，可以使用QQ快速登录至平台</span>
				</div>
			</div>
			<div class="itemLeft fl" v-else>
				<img :src="require('@/static/image/mine/qqA.png')" style="width: 50px;height: 50px;" />
				<div class="leftText">
					<span class="leftTitle">绑定QQ</span>
					<span class="leftDetaile">绑定QQ以后，可以使用QQ快速登录至平台</span>
				</div>
			</div>
			<div class="itemRight fl" v-if="userInfo.qqOpenId == null">
				<div class="itemType">
					<i style="color: #FF800E;margin-right: 8px;" class="el-icon-warning"></i>
					<span>未绑定</span>
				</div>
				<el-button type="primary">立即绑定</el-button>
			</div>
			<div class="itemRight fl" v-else>
				<div class="itemType">
					<i style="color: #19D972;margin-right: 8px;" class="el-icon-success"></i>
					<span>已绑定</span>
				</div>
				<el-button>解除绑定</el-button>
			</div>
		</div>
		<div class="bindItem fl">
			<div class="itemLeft fl" v-if="userInfo.openId == null">
				<img :src="require('@/static/image/mine/wechat.png')" style="width: 50px;height: 50px;" />
				<div class="leftText">
					<span class="leftTitle">绑定微信</span>
					<span class="leftDetaile">绑定微信以后，可以使用微信快速登录至平台</span>
				</div>
			</div>
			<div class="itemLeft fl" v-else>
				<img :src="require('@/static/image/mine/wechatA.png')" style="width: 50px;height: 50px;" />
				<div class="leftText">
					<span class="leftTitle">绑定微信</span>
					<span class="leftDetaile">绑定微信以后，可以使用微信快速登录至平台</span>
				</div>
			</div>
			<div class="itemRight fl" v-if="userInfo.openId == null">
				<div class="itemType">
					<i style="color: #FF800E;margin-right: 8px;" class="el-icon-warning"></i>
					<span>未绑定</span>
				</div>
				<el-button type="primary">立即绑定</el-button>
			</div>
			<div v-else class="itemRight fl">
				<div class="itemType">
					<i style="color: #19D972;margin-right: 8px;" class="el-icon-success"></i>
					<span>已绑定</span>
				</div>
				<el-button>解除绑定</el-button>
			</div>
		</div>
	</div>
</template>

<script>
	import {
		userUpEdit,
		getUserMessage
	} from '@/api/setting.js'
	export default {
		data() {
			return {
				userInfo: {}
			}
		},
		created() {
			if (this.$store.state.userInfo != undefined) {
				getUserMessage({
					id: this.$store.state.userInfo.id
				}).then(res => {
					this.userInfo = res.result
				})
				
			}
		}
	}
</script>

<style scoped lang="scss">
	.roleTitle {
		font-weight: 600;
		font-size: 24px;
		color: #1D223C;
		width: 950px;
		border-bottom: 1px solid #ECECEC;
		height: 60px;
		line-height: 60px;
		padding-left: 30px;
	}

	.balance {
		font-weight: 400;
		font-size: 16px;
		color: #A5A7B1;
	}

	.bindItem {
		margin: 20px auto 0;
		width: 950px;
		height: 94px;
		background: #FAFAFA;
		border-radius: 12px 12px 12px 12px;
		border: 1px solid #DEDEDE;
		justify-content: space-between;

		.itemLeft {
			padding-left: 40px;

			.leftText {
				display: flex;
				flex-direction: column;
				margin-left: 25px;
			}

			.leftTitle {
				font-weight: 600;
				font-size: 20px;
				color: #1D223C;
				line-height: 33px;
			}

			.leftDetaile {
				font-weight: 400;
				font-size: 14px;
				color: #A5A7B1;
				margin-top: 8px;
			}
		}

		.itemRight {
			padding-right: 40px;

			.itemType {
				margin-right: 100px;
			}
		}
	}
</style>