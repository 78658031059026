<template>
	<div>
		<div class="roleTitle">资金管理</div>
		<div style="overflow-y: auto;height: 980px; scrollbar-width: none; ">
			<div class="wallet">
				<div class="walletTop fl">
					<span class="walletText">我的钱包</span>
					<el-button @click="$emit('withdraw',16)" class="withdraw" type="primary" size="mini"
						plain>余额提现</el-button>
				</div>
				<div class="balanceList fl">
					<div class="balanceItem">
						<span class="balanceTitle">账户余额(元)</span>
						<span class="balanceNumber">{{users.earningsBalance}}</span>
						<span class="balanceDetail" @click="setType(1)">查看明细</span>
					</div>
					<div class="balanceItem">
						<span class="balanceTitle">保证金余额(元)</span>
						<span class="balanceNumber">{{users.deposit}}</span>
						<span class="balanceDetail" @click="setType(4)">查看明细</span>
					</div>
					<div class="balanceItem">
						<span class="balanceTitle">赔付金余额(元)</span>
						<span class="balanceNumber">{{users.paidMoney}}</span>
						<span class="balanceDetail" @click="setType(3)">查看明细</span>
					</div>
				</div>
			</div>
			<div class="fl search">
				<!-- <div class="fl">
					<div class="searchText">关键字：</div>
					<el-input style="width: 286px;" v-model="value" placeholder="请选择"></el-input>
					
				</div> -->
				<div class="fl" style="margin-left: 30px;">
					<div class="searchText">时间区间：</div>
					<el-date-picker style="width: 240px;" v-model="search.startTime" @change="startChange"
						value-format="yyyy-MM-dd" type="date" placeholder="选择日期">
					</el-date-picker>
					<span style="margin: 0 10px; color: #000;">-</span>
					<el-date-picker style="width: 240px;" v-model="search.endTime" @change="endChange"
						value-format="yyyy-MM-dd" type="date" placeholder="选择日期">
					</el-date-picker>
				</div>
				<div style="margin-left: 11px;">
					<el-button type="primary" icon="el-icon-search" @click="getTabbleList">搜索</el-button>
				</div>
			</div>

			<div class="roleTitles">
				<el-badge value="" class="titleItem">
					<div @click="setType('')" :class="{'action':searchIndex == 1}">收支明细</div>
				</el-badge>
				<el-badge value="" class="titleItem">
					<div @click="setType(2)" :class="{'action':searchIndex == 2}">提现记录</div>
				</el-badge>
				<el-badge value="" class="titleItem">
					<div @click="setType(3)" :class="{'action':searchIndex == 3}">赔付记录</div>
				</el-badge>
				<el-badge value="" class="titleItem">
					<div @click="setType(4)" :class="{'action':searchIndex == 4}">保证金</div>
				</el-badge>
				<el-badge value="" class="titleItem">
					<div @click="setType(5)" :class="{'action':searchIndex == 5}">分佣记录</div>
				</el-badge>
			</div>


			<div class="tableList">
				<el-table ref="multipleTable" :data="tableList" tooltip-effect="dark" style="width: 100%"
					@selection-change="handleSelectionChange">
					<el-table-column type="index" :index="indexMethod" width="60">
						<template slot="header">
							<div class="cardPrice">序号</div>
						</template>
					</el-table-column>
					<el-table-column width="180">
						<template slot="header">
							<div class="cardPrice">流水号</div>
						</template>
						<template slot-scope="scope">
							<div>
								{{scope.row.id}}
							</div>
						</template>
					</el-table-column>
					<el-table-column prop="name" width="140" align="center">
						<template slot="header">
							<div class="cardPrice">金额</div>
						</template>
						<template slot-scope="scope">
							<div >{{scope.row.price}}</div>
							
						</template>
					</el-table-column>
					<el-table-column prop="name" width="140" align="center">
						<template slot="header">
							<div class="cardPrice">当期余额</div>
						</template>
						<template slot-scope="scope">
							<div >{{scope.row.nowPrice}}</div>
							
						</template>
					</el-table-column>

					<el-table-column prop="address" width="180">
						<template slot="header">
							<div class="cardPrice">类型</div>
						</template>
						<template slot-scope="scope">
							<div >{{scope.row.detailMessage}}</div>
						</template>
					</el-table-column>

					<el-table-column prop="address" width="180">
						<template slot="header">
							<div class="cardPrice">时间</div>
						</template>
						<template slot-scope="scope">
							<div>{{scope.row.createTime}}</div>
						</template>
					</el-table-column>

				</el-table>
			</div>
		</div>
		<div class="fl" style="justify-content: space-between;font-size: 18px;margin-top: 20px;padding:0 20px;">
			<div>
			</div>
			<el-pagination @size-change="sizeChage" @current-change="currentChange" :current-page="search.pageNo"
				:page-sizes="[10, 20,30,40]" :page-size="search.pageSize"
				layout="total, sizes, prev, pager, next, jumper" :total="totleNumber">
			</el-pagination>
		</div>

	</div>
</template>

<script>
	import {
		userCenter,
		detailList,
		getPaidMoney
	} from '@/api/index.js'
	export default {
		data() {
			return {
				// actionIndex:0,
				tableList: [],
				users: {},
				searchIndex: 1,
				search: {
					detailType: '2',
					pageNo: 1,
					pageSize: 10,
					withdrawalState: '',
					sourceType: '',
					startTime: '',
					endTime: '',

				},
				totleNumber: 0
			}
		},
		created() {
			userCenter().then(res => {
				if (res.code == 200) {
					this.users = res.result
					this.$store.commit('saveUserInfo', res.result)
				}

			})
			if(localStorage.getItem('money') == 1){
				this.searchIndex = 4
				this.search.detailType = 1
				this.search.withdrawalState = ''
				this.search.sourceType = ''
				this.getList()
			}else if(localStorage.getItem('money') == 2){
				this.searchIndex = 3
				this.search.withdrawalState = 1
				this.search.detailType = 2
				this.search.sourceType = 10
				this.getList()
			}else{
				this.getList()
			}
			localStorage.setItem('money','');
			
		},
		methods: {
			getList() {
				detailList(this.search).then(res => {
					if (res.code == 200) {
						this.tableList = res.result.records
						this.totleNumber = res.result.total
					}
				})
			},
			getPaidMoney() {
				getPaidMoney(this.search).then(res => {
					if (res.code == 200) {
						this.tableList = res.result.records
						this.totleNumber = res.result.total
					}
				})
			},
			// 切换tabber
			setType(type) {
				this.search.pageNo = 1
				if(type == ''){
					this.searchIndex = 1
				}else{
					this.searchIndex = type
				}
				
				if (type == '' || type == 2) {
					this.search.detailType = 2
					this.search.withdrawalState = type
					this.search.sourceType = ''
					this.getList()
				} else if (type == 3) {
					this.search.withdrawalState = 1
					this.search.detailType = 2
					this.search.sourceType = 10
					this.getList()
				} else if (type == 4) {
					this.search.detailType = 1
					this.search.withdrawalState = ''
					this.search.sourceType = ''
					this.getList()
				}else if(type == 5){
					this.search.detailType = 2
					this.search.withdrawalState = ''
					this.search.sourceType = '25'
					this.getList()
				}


			},
			// 调整每页条数
			sizeChage(e) {
				this.search.pageSize = e
				if (this.searchIndex == 3) {
					this.getList()
				} else {
					this.getList()
				}
			},
			getTabbleList() {
				this.search.pageNo = 1
				if (this.searchIndex == 3) {
					this.getList()
				} else {
					this.getList()
				}
			},
			currentChange(e) {
				this.search.pageNo = e
				if (this.searchIndex == 3) {
					this.getList()
				} else {
					this.getList()
				}
			},
			startChange(e) {
				this.search.startTime = e + ' ' + '00:00:00'
			},
			endChange(e) {
				this.search.endTime = e + ' ' + '24:00:00'
			}

		}
	}
</script>

<style scoped lang="scss">
	.roleTitle {
		font-weight: 600;
		font-size: 24px;
		color: #1D223C;
		width: 950px;
		border-bottom: 1px solid #ECECEC;
		height: 60px;
		line-height: 60px;
		padding-left: 42px;
	}

	.wallet {
		width: 950px;
		height: 248px;
		background: linear-gradient(180deg, #FFFFFF 0%, #F9F9F9 100%);
		box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.1);
		border-radius: 0px 20px 20px 0px;
		margin: 24px 22px;

		.walletTop {
			justify-content: space-between;
			height: 75px;
			padding: 0 28px;
			border-bottom: 1px solid #ECECEC;

			.withdraw {
				width: 110px;
				height: 39px;

				font-weight: 600;
				font-size: 14px;


			}

			.walletText {
				font-weight: 600;
				font-size: 18px;
				color: #1D223C;
			}
		}
	}

	.balanceList {
		.balanceItem {
			display: flex;
			flex-direction: column;
			justify-content: center;
			height: 173px;
			width: 410px;
			padding-left: 28px;

			.balanceTitle {
				font-weight: 400;
				font-size: 18px;
				color: #A5A7B1;
			}

			.balanceNumber {
				font-weight: bold;
				font-size: 40px;
				color: #1D223C;
			}

			.balanceNumber:hover {
				color: #FF5C00;
			}

			.balanceDetail {
				font-weight: 400;
				font-size: 18px;
				color: #A5A7B1;
				cursor:pointer;
			}

			.balanceDetail:hover {
				color: #0085FF;
			}
		}
	}

	.search {


		.searchText {
			width: 100px;
			text-align: right;
			font-weight: 400;
			font-size: 14px;
			color: #1D223C;
		}
	}

	.roleTitles {

		width: 900px;
		border-bottom: 1px solid #ECECEC;
		height: 67px;
		// line-height: 87px;
		padding-left: 42px;
		padding-right: 42px;
		display: flex;
		align-items: center;

		.titleItem {
			font-weight: 400;
			font-size: 16px;
			position: relative;
			color: #1D223C;
			height: 20px;
			margin-right: 50px;
			cursor:pointer;
		}

		.titleItem::after {
			position: absolute;
			width: 1px;
			height: 20px;
			content: '';
			top: 2px;
			right: -25px;
			background-color: #DEDEDE;
		}

		.titleItem:last-child::after {
			width: 0px;
		}
	}

	.action {
		color: #0085FF;
		position: relative;
		// border-bottom: 4px solid #0085FF;
	}

	.action::after {
		position: absolute;
		content: '';
		width: 60px;
		height: 4px;
		background: #0085FF;
		left: 0;
		bottom: -21px;
	}

	.tableList {
		
		margin: 20px 42px;
	}

	.cardPrice {
		font-weight: bold;
		font-size: 14px;
		color: #1D223C;
	}

	::v-deep .el-pagination span:not([class*=suffix]) {
		font-size: 16px;
	}

	::v-deep .el-pager li {
		font-size: 16px;
	}

	.stateColor1 {
		width: 8px;
		height: 8px;
		background: #19E382;
		border-radius: 50%;
		margin-right: 6px;
	}
</style>