<template>
	<div style="background-color: #F8F8F8;min-height:100vh">
		<head-bar></head-bar>
		<div class="lableTop">
			<img class="lableImage" :src="require('@/static/image/index/classBg.png')" />
			<el-row class="content">
				
				<el-col :span="24">
					<div class="lableBg">
						<div class=" gameClass">
							<div class="lableTitle">服务中心</div>
							<div class="lableDetaile">您可查询搜索需要查看的服务</div>
						</div>
					</div>
				</el-col>
				
			</el-row>
		</div>

		<div style="margin-top: -90px;">
			<el-row class="content">
			
				<el-col :span="24">
					<div class="classify">
						<div class="tabList">
							<span class="gameClass" :class="{'action':action == index}"
								v-for="(item,index) in lableList" :key="index" @click="repaleTo(index,0)">{{item}}</span>
						</div>
						<div class="list" v-if="action == 0">
							<div ref="listItem"
								class="listItem" >
								<div class="noHover" ref="itemShow">
									<img :src="require('@/static/image/mine/service1.png')"
										style="width: 80px;height: 80px;" />
									<span class="noHoverTitle">客服QQ验证</span>
									<span class="noHoverDetaile">仅供验证客服QQ真伪使用<br />不作为客服身份验证依据</span>
								</div>
								<div class="noHover1" ref="ItemHide" >
									<span class="noHoverTitle1">客服QQ验证</span>
									<el-input v-prevent-space class="noHoverInput" v-model="qq" placeholder="请输入内容"></el-input>
									<span class="noHoverDetaile1">建议进入QQ资料里面获取QQ号进行验证</span>
									<el-button class="result" style="width: 300px;height: 60px;font-size: 14px;" type="primary" @click="verify('1')">开始验证</el-button>
								</div>
							</div>

							<div class="listItem1">
								<div class="noHover2" ref="itemShow1">
									<img :src="require('@/static/image/mine/service2.png')"
										style="width: 80px;height: 80px;" />
									<span class="noHoverTitle">平台网址验证</span>
									<span class="noHoverDetaile">不要随意点击别人发送的网址<br />小心电脑中毒被他人操纵</span>
								</div>
								<div class="noHover3" ref="ItemHide1" >
									<span class="noHoverTitle1">平台网址验证</span>
									<el-input style="width: 300px;" v-prevent-space class="noHoverInput" v-model="link" placeholder="请输入内容"></el-input>
									<el-button style="width: 300px;height: 60px;font-size: 14px;" class="result" type="primary" @click="verify('2')">开始验证</el-button>
								</div>
							</div>
							
							<div  class="listItem2"
								>
								<div class="noHover4" ref="itemShow2">
									<img :src="require('@/static/image/mine/service3.png')"
										style="width: 80px;height: 80px;" />
									<span class="noHoverTitle">官方微信验证</span>
									<span class="noHoverDetaile">不要轻信陌生人发送的消息<br />小心上当受骗</span>
								</div>
								<div class="noHover5" ref="ItemHide2" >
									<span class="noHoverTitle1">官方微信验证</span>
									<el-input style="width: 300px;" v-prevent-space class="noHoverInput" v-model="weixin"
										placeholder="请输入您要验证的官方微信号"></el-input>
									<el-button style="width: 300px;height: 60px;font-size: 14px;" class="result" type="primary" @click="verify('3')">开始验证</el-button>
								</div>
							</div>
							
							
							<div  class="listItem3"
								>
								<div class="noHover6" ref="itemShow3">
									<img :src="require('@/static/image/mine/service4.png')"
										style="width: 80px;height: 80px;" />
									<span class="noHoverTitle">举报假客服</span>
									<span class="noHoverDetaile">在这里你可以提交要举报的<br />假客服信息</span>
								</div>
								<div class="noHover7" ref="ItemHide3" >
									<span class="noHoverTitle1">举报假客服</span>
									<el-input v-prevent-space class="noHoverInput" v-model="input" placeholder="请输入假客服QQ号"></el-input>
									<div class="fl" style="justify-content: space-between;width: 460px;border: none;">
										<el-input v-prevent-space :rows="7" class="noHoverArea" type="textarea"
											placeholder="请输入假客服QQ号"></el-input>
										<el-upload action="https://jsonplaceholder.typicode.com/posts/"
											list-type="picture-card" :limit="1" :on-preview="handlePictureCardPreview"
											:on-remove="handleRemove">
											<i class="el-icon-plus"></i>
										</el-upload>
							
									</div>
									<el-button class="result" type="primary" disabled>开始验证</el-button>
								</div>
							</div>
						



						</div>
						<div v-if="action == 1" style="margin-top: 42px;margin-left: 30px;">
							<div class="formItem fl">
								<div class="item1"><span
										style="color:#1D223C;font-size: 24px;font-weight: bold;">账户解封申诉：</span></div>
								<div class="item2">
									<span style="color: #0085FF;">｜申诉结果查询</span>
								</div>
							</div>
							<el-form :model="accountAppeal" :rules="accountAppealRule" ref="ruleForm">
								<div class="formItem fl">
									<div class="item1"><span style="color:#FF3C3C;">*</span>您的用户名：</div>
									<div class="item2">
										<el-form-item label="" prop="account" style="margin-top: 20px;">
											<el-input v-prevent-space v-model="accountAppeal.account"
												placeholder="请输入您的用户名/手机号"></el-input>
										</el-form-item>
									</div>

								</div>
								<div class="formItem ">
									<div class="item1"><span style="color:#FF3C3C;">*</span>解封原因：</div>
									<div class="item2">
										<el-form-item label="" prop="unsealReason">
											<el-input  v-prevent-space v-model="accountAppeal.unsealReason" type="textarea" :rows="4"
												placeholder="请详细描述账户解封原因"></el-input>
										</el-form-item>
									</div>
								</div>

								<div class="formItem">
									<div style="display: flex;">
										<div class="item1"><span style="color:#FF3C3C;"></span>相关图片(选填)：</div>
										<div style="margin-left: 8px;">
											<div class="item4" style="border: none;">
												<el-upload
													action="http://192.168.1.10:8280/jeecg-boot/sys/common/upload"
													list-type="picture-card" :on-success="handlePictureCardPreview"
													:on-remove="handleRemove" :on-error="handleError" ref="uploadFile">
													<i class="el-icon-plus"></i>
												</el-upload>
											</div>
										</div>
									</div>
								</div>

								<div class="formItem fl">
									<div class="item1"><span style="color:#FF3C3C;">*</span>您的联系电话：</div>
									<div class="item2">
										<el-form-item label="" prop="phone">
											<el-input v-prevent-space style="margin-top: 20px;" v-model="accountAppeal.phone"
												placeholder="请输入您的联系电话"></el-input>
										</el-form-item>

									</div>
								</div>
								<div class="formItem fl">
									<div class="item1"><span style="color:#FF3C3C;">*</span>您的QQ号码：</div>
									<div class="item2">
										<el-form-item label="" prop="qq">
											<el-input v-prevent-space style="margin-top: 20px;" v-model="accountAppeal.qq"
												placeholder="请输入您的qq号码"></el-input>
										</el-form-item>
									</div>
								</div>

							</el-form>

							<div class="formItem fl">
								<div class="item1">&ensp;</div>
								<div class="item2">
									<el-button @click="submit" style="font-size: 14px;width: 288px;height: 46px;"
										type="primary">提交申诉</el-button>
								</div>
							</div>
						</div>

						<div v-if="action == 2" style="display: flex;">
							<div class="helpLeft">
								<div class="leftTitle">问题分类</div>
								<div v-for="(item,index) in commonProblemList"  @click="setInfoId('commonProblemIndex',index,item.id)"   class="leftItem el1"
									:class="{'helpIndex':commonProblemIndex == index}">{{item.title}}</div>
							</div>

							<div class="helpRight">
								<div class="detailes" v-html="commonProblemList[commonProblemIndex].content">
									
								</div>
								<!-- <el-collapse v-model="commonProblemIndex" @change="handleChange">
									<div class="rightItem" :class="{'rightItemAction':commonProblemIndex == index}"
										v-for="(item,index) in commonProblemList">
										<el-collapse-item :name="index">
											<template slot="title">
												<div :class="{'rightTitleAction':commonProblemIndex == index}"
													class="rightTitle">{{item.title}}</div>
											</template>
											
										</el-collapse-item>
									</div>
								</el-collapse> -->
							</div>
						</div>

						<div v-if="action == 3" style="display: flex;">
							<div class="helpLeft">
								<div class="leftTitle">协议条款</div>
								<div v-for="(item,index) in protocolList" @click="setInfoId('protocolIndex',index,item.id)" 
									class="leftItem el1" :class="{'helpIndex':protocolIndex == index}">{{item.name}}
								</div>
							</div>

							<div class="helpRight">
								<div class="detailes" v-html="protocolList[protocolIndex].content">
									
								</div>
								<!-- <el-collapse v-model="protocolIndex" @change="handleChange1">
									<div class="rightItem" v-for="(item,index) in protocolList">
										<el-collapse-item :name="index">
											<template slot="title">
												<div :class="{'rightTitleAction':protocolIndex == index}"
													class="rightTitle">{{item.name}}</div>
											</template>
											
										</el-collapse-item>
									</div>
								</el-collapse> -->
							</div>
						</div>
						<div v-if="action == 4" style="display: flex;">
							<div class="helpLeft">
								<div class="leftTitle">网站公告</div>
								<div v-for="(item,index) in noticeList" @click="setInfoId('noticeIndex',index,item.id)"  
									class="leftItem el1" :class="{'helpIndex':noticeIndex == index}">{{item.title}}
								</div>
							</div>

							<div class="helpRight">
								<div class="detailes" v-html="noticeList[noticeIndex].content">
								</div>
								<!-- <el-collapse v-model="noticeIndex" @change="handleChange1">
									<div class="rightItem" v-for="(item,index) in noticeList">
										<el-collapse-item :name="index">
											<template slot="title">
												<div :class="{'rightTitleAction':noticeIndex == index}"
													class="rightTitle">{{item.title}}</div>
											</template>
										
										</el-collapse-item>
									</div>
								</el-collapse> -->
							</div>
						</div>
					</div>
				</el-col>
				
			</el-row>
		</div>
		<!-- <shoppingCard></shoppingCard> -->
		<footer-bars></footer-bars>
	</div>
</template>

<script>
	import {
		accountAppealAdd,
		protocolList,
		noticeList,
		commonProblemList,
		validate
	} from '@/api/index.js'
	import headBar from '@/components/HeadBar.vue';
	import shoppingCard from '@/components/shoppingCart.vue';
	import footerBars from '@/components/footerBars.vue';
	export default {
		data() {
			return {
				action: 0,
				qq:'',
				weixin:'',
				link:'',
				// 申诉表单
				addImage: [],
				accountAppeal: {
					account: '',
					phone: '',
					pic: '',
					qq: '',
					unsealReason: ''
				},
				accountAppealRule: {
					account: [{
						required: true,
						message: '请输入账号',
						trigger: 'blur'
					}],
					phone: [{
							required: true,
							message: '请输入联系电话',
							trigger: 'blur'
						},
						{
							required: true,
							pattern: /^1\d{10}$/,
							message: '联系电话格式不正确',
							trigger: 'blur'
						}
					],
					qq: [{
						required: true,
						message: '请输入QQ号',
						trigger: 'blur'
					}],
					unsealReason: [{
						required: true,
						message: '请输入解封原因',
						trigger: 'blur'
					}],
				},
				lableList: ['安全中心', '申述中心', '帮助中心', '协议条款', '网站公告'],
				
				search: {
					pageNo: 1,
					pageSize: 100
				},
				protocolList: [],
				protocolIndex: 0,
				noticeList: [],
				noticeIndex: 0,
				commonProblemList:[],
				commonProblemIndex:0,
				infoId:0
			}
		},
		watch: {
			// 利用watch方法检测路由变化：
			$route: function(to, from) {
				if (to.params !== from.params) {
					this.action = this.$route.params.index
					this.accountAppeal.account = this.$store.state.userInfo.name
					this.accountAppeal.phone = this.$store.state.userInfo.account
					this.accountAppeal.qq = this.$store.state.userInfo.qq
					
					this.getProtocolList()
					this.getNoticeList()
					this.getCommonProblemList()
				}
			}
		},
		components: {
			headBar,
			shoppingCard,
			footerBars
		},
		created() {
			this.action = this.$route.params.index
			this.accountAppeal.account = this.$store.state.userInfo.name
			this.accountAppeal.phone = this.$store.state.userInfo.account
			this.accountAppeal.qq = this.$store.state.userInfo.qq

			this.getProtocolList()
			this.getNoticeList()
			this.getCommonProblemList()
		},
		methods: {
			verify(type){
				if(type ==1){
					if(this.qq == ''){
						this.message('请填写官方QQ','error')
						return
					}
					validate({
						type:1,
						content:this.qq
					}).then(res=>{
						if(res.result == 0){
							this.message('客服QQ为假','error')
						}else{
							this.message('客服QQ为真','success')
						}
						
					})
				}else if(type ==2){
					if(this.link.indexOf('cc007.com') != -1){
						this.message('官网链接正确','success')
					}else{
						this.message('错误的官网链接','error')
					}
				}else if(type ==3){
					if(this.weixin == ''){
						this.message('请填写官方微信','error')
						return
					}
					validate({
						type:2,
						content:this.weixin
					}).then(res=>{
						if(res.result == 0){
							this.message('官方微信为假','error')
						}else{
							this.message('官方微信为真','success')
						}
					})
				}
			},
			setInfoId(type,index,id){
				this.infoId = id
				if(type == 'protocolIndex'){
					this.protocolIndex = index
				}else if(type == 'commonProblemIndex'){
					this.commonProblemIndex = index 
				}else if(type == 'noticeIndex'){
					this.noticeIndex = index
				}
				this.$router.replace({
					path:'/serviceCenter/'+this.action+'/'+this.infoId,
				})
			},
			repaleTo(index,id){
				this.$router.replace({
					path:'/serviceCenter/'+index+'/'+this.infoId,
				})
				this.action = index
			},
			// 获取帮助中心数据
			getCommonProblemList(){
				commonProblemList({
					pageNo: '1',
					pageSize: '1000'
				}).then(res => {
					if (res.code == 200) {
						this.commonProblemList = res.result.records
						if (this.$route.params.id != 0) {
							res.result.records.forEach((data, index) => {
								if (data.id == this.$route.params.id) {
									this.commonProblemIndex = index
								}
							})
						}
					}
				})
			},
			// 获取公告列表
			getNoticeList() {
				noticeList({
					pageNo: '1',
					pageSize: '1000'
				}).then(res => {
					if (res.code == 200) {
						this.noticeList = res.result.records
						if (this.$route.params.id != 0) {
							res.result.records.forEach((data, index) => {
								if (data.id == this.$route.params.id) {
									this.noticeIndex = index
								}
							})
						}
					}
				})
			},
			getProtocolList() {
				protocolList(this.search).then(res => {
					if (res.code == 200) {
						this.protocolList = res.result.records
						if (this.$route.params.id != 0) {
							res.result.records.forEach((data, index) => {
								if (data.id == this.$route.params.id) {
									this.protocolIndex = index
								}
							})
						}
					}
				})
			},
			submit() {
				this.$refs.ruleForm.validate((valid) => {
					if (valid) {
						this.accountAppeal.pic = this.addImage.join(',')
						accountAppealAdd(this.accountAppeal).then(res => {
							if (res.code == 200) {
								this.message('提交成功', 'success')

							}
						})
					}
				})
			},
			// 上传失败事件
			handleError(e) {
				this.message('上传失败', 'error')
			},
			// 上传成功事件
			handlePictureCardPreview(e) {
				this.addImage.push(e.result)
			},
			handleChange(e, index) {
				this.helpIndex = e[e.length - 1]
			},
			handleChange1(e, index) {
				this.protocolIndex = e[e.length - 1]
			},
			
		}
	}
</script>

<style scoped lang="scss">
	.lableTop {
		position: relative;
		background-color: #F8F8F8;

		.lableImage {
			width: 100%;
			height: 150px;
			position: absolute;
		}
	}

	.lableBg {
		padding-top: 20px;
		height: 100px;
		display: flex;

		.lableTitle {
			font-weight: bold;
			font-size: 24px;
			color: #FFFFFF;
		}

		.lableDetaile {
			font-weight: 400;
			font-size: 16px;
			color: #FFFFFF;
			margin-top: 12px;
		}
	}

	.tabList {
		border-bottom: 1px solid #ECECEC;
		padding: 20px 40px;
	}

	.classify {
		color: #777A8A;
		background: #FFFFFF;
		width: 100%;
		// margin-top: -80px;
		border-radius: 20px 20px 20px 20px;
		height: 1228px;
cursor:pointer;
		.gameClass {
			font-weight: 400;
			font-size: 18px;
			color: #777A8A;
			margin-right: 60px;
		}

		.action {
			font-weight: 600;
			color: #0085FF;
			position: relative;
		}

		.action::after {
			position: absolute;
			content: '';
			height: 4px;
			width: 70px;
			background: #0085FF;
			bottom: -20px;
			left: 0;
		}
	}

	.list {
		display: flex;
		// margin-top: 60px;
		height: 746px;
	}

	.listItem {
		border: 1px solid #DEDEDE;
		height: 560px;
		width: 25%;

		.noHover {
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;
			height: 560px;

			
		}
		.noHover1 {
			display: none;
			flex-direction: column;
			align-items: center;
			justify-content: center;
			height: 560px;
		
			
		}
		.noHoverTitle {
			font-weight: 600;
			font-size: 18px;
			color: #1D223C;
			margin-top: 34px;
		}
		
		.noHoverTitle1 {
			font-weight: 600;
			font-size: 24px;
			color: #1D223C;
		}
		
		.noHoverInput {
			width: 300px;
			height: 66px;
			margin-top: 76px;
			margin-bottom: 16px;
		}
		
		.noHoverArea {
			width: 300px;
		
		}
		
		::v-deep .el-input__inner {
			height: 66px;
			font-size: 20px;
		}
		
		
		.noHoverDetaile {
			font-weight: 400;
			font-size: 14px;
			color: #A5A7B1;
			margin-top: 12px;
			text-align: center;
		}
		
		.noHoverDetaile1 {
			font-weight: 400;
			font-size: 14px;
			color: #1D223C;
			line-height: 21px;
			width: 300px;
			text-align: left;
		}
		
		.result {
			width: 460px;
			height: 66px;
			background: #8EC9FF;
			border-radius: 8px 8px 8px 8px;
			font-weight: 500;
			font-size: 24px;
			color: #FFFFFF;
			margin-top: 20px;
		}
	}
	.listItem:hover{
		width: 40%;
		.noHover{
			display: none;
		}
		.noHover1{
			display: flex;
		}
	}
	
	.listItem1 {
		border: 1px solid #DEDEDE;
		height: 560px;
		width: 25%;
	
		.noHover2 {
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;
			height: 560px;
	
			
		}
		.noHover3 {
			display: none;
			flex-direction: column;
			align-items: center;
			justify-content: center;
			height: 560px;
		
			
		}
		.noHoverTitle {
			font-weight: 600;
			font-size: 18px;
			color: #1D223C;
			margin-top: 34px;
		}
		
		.noHoverTitle1 {
			font-weight: 600;
			font-size: 24px;
			color: #1D223C;
		}
		
		.noHoverInput {
			width: 460px;
			height: 66px;
			margin-top: 76px;
			margin-bottom: 16px;
		}
		
		.noHoverArea {
			width: 300px;
		
		}
		
		::v-deep .el-input__inner {
			height: 66px;
			font-size: 20px;
		}
		
		
		.noHoverDetaile {
			font-weight: 400;
			font-size: 14px;
			color: #A5A7B1;
			margin-top: 12px;
			text-align: center;
		}
		
		.noHoverDetaile1 {
			font-weight: 400;
			font-size: 18px;
			color: #1D223C;
			line-height: 21px;
			width: 460px;
			text-align: left;
		}
		
		.result {
			width: 460px;
			height: 66px;
			background: #8EC9FF;
			border-radius: 8px 8px 8px 8px;
			font-weight: 500;
			font-size: 24px;
			color: #FFFFFF;
			margin-top: 20px;
		}
	}
	.listItem1:hover{
		width: 40%;
		.noHover2{
			display: none;
		}
		.noHover3{
			display: flex;
		}
	}
	
	
	.listItem2 {
		border: 1px solid #DEDEDE;
		height: 560px;
		width: 25%;
	
		.noHover4 {
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;
			height: 560px;
	
			
		}
		.noHover5 {
			display: none;
			flex-direction: column;
			align-items: center;
			justify-content: center;
			height: 560px;
		
			
		}
		.noHoverTitle {
			font-weight: 600;
			font-size: 18px;
			color: #1D223C;
			margin-top: 34px;
		}
		
		.noHoverTitle1 {
			font-weight: 600;
			font-size: 24px;
			color: #1D223C;
		}
		
		.noHoverInput {
			width: 460px;
			height: 66px;
			margin-top: 76px;
			margin-bottom: 16px;
		}
		
		.noHoverArea {
			width: 300px;
		
		}
		
		::v-deep .el-input__inner {
			height: 66px;
			font-size: 20px;
		}
		
		
		.noHoverDetaile {
			font-weight: 400;
			font-size: 14px;
			color: #A5A7B1;
			margin-top: 12px;
			text-align: center;
		}
		
		.noHoverDetaile1 {
			font-weight: 400;
			font-size: 18px;
			color: #1D223C;
			line-height: 21px;
			width: 460px;
			text-align: left;
		}
		
		.result {
			width: 460px;
			height: 66px;
			background: #8EC9FF;
			border-radius: 8px 8px 8px 8px;
			font-weight: 500;
			font-size: 24px;
			color: #FFFFFF;
			margin-top: 20px;
		}
	}
	.listItem2:hover{
		width: 40%;
		.noHover4{
			display: none;
		}
		.noHover5{
			display: flex;
		}
	}
	
	
	.listItem3 {
		border: 1px solid #DEDEDE;
		height: 560px;
		width: 25%;
	
		.noHover6 {
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;
			height: 560px;
	
			
		}
		.noHover7 {
			display: none;
			flex-direction: column;
			align-items: center;
			justify-content: center;
			height: 560px;
		
			
		}
		.noHoverTitle {
			font-weight: 600;
			font-size: 18px;
			color: #1D223C;
			margin-top: 34px;
		}
		
		.noHoverTitle1 {
			font-weight: 600;
			font-size: 24px;
			color: #1D223C;
		}
		
		.noHoverInput {
			width: 460px;
			height: 66px;
			margin-top: 76px;
			margin-bottom: 16px;
		}
		
		.noHoverArea {
			width: 300px;
		
		}
		
		::v-deep .el-input__inner {
			height: 66px;
			font-size: 20px;
		}
		
		
		.noHoverDetaile {
			font-weight: 400;
			font-size: 14px;
			color: #A5A7B1;
			margin-top: 12px;
			text-align: center;
		}
		
		.noHoverDetaile1 {
			font-weight: 400;
			font-size: 18px;
			color: #1D223C;
			line-height: 21px;
			width: 460px;
			text-align: left;
		}
		
		.result {
			width: 460px;
			height: 66px;
			background: #8EC9FF;
			border-radius: 8px 8px 8px 8px;
			font-weight: 500;
			font-size: 24px;
			color: #FFFFFF;
			margin-top: 20px;
		}
	}
	.listItem3:hover{
		width: 40%;
		.noHover6{
			display: none;
		}
		.noHover7{
			display: flex;
		}
	}
	
	
	.formItem {
		margin-top: 8px;
		color: #000;
		display: flex;

		.item1 {
			width: 250px;
			text-align: right;
		}

		.item2 {
			width: 400px;
			margin-left: 10px;
		}

		.item3 {
			font-weight: 400;
			font-size: 16px;
			color: #0085FF;
			margin-left: 20px;
		}

		.item4 {
			border: 1px solid #DEDEDE;
			border-radius: 4px 4px 4px 4px;
		}

		.item5 {
			border-top: 1px solid #DEDEDE;
			font-weight: 400;
			font-size: 16px;
			color: #A5A7B1;
			height: 56px;
			line-height: 56px;
			padding-left: 20px;
		}

	}
	
	

	.helpLeft {

		width: 280px;
		height: 1246px;
		background-color: #F8F8F8;
		overflow-y: auto;

		.leftTitle {
			padding-left: 42px;
			height: 94px;
			font-weight: 600;
			font-size: 16px;
			color: #1D223C;
			line-height: 94px;
			cursor:pointer;
		}

		.leftItem {
			padding-left: 12px;
			height: 68px;
			width: 180px;
			font-weight: 500;
			font-size: 14px;
			color: #777A8A;
			line-height: 68px;
			
		}

		.helpIndex {
			background: #FFFFFF;
			color: #1D223C;
			border-left: 4px solid #0085FF;
		}


	}
	.helpLeft::-webkit-scrollbar {
  width: 0;
}

	.helpRight {
		padding: 32px;
		width: 1250px;
		height: 1150px;
		overflow: auto;

		::v-deep .el-collapse {
			border: none;
		}

		::v-deep .el-collapse-item__header {
			height: 71px;
		}

		.detailes {
			padding-left: 20px;
			font-size: 18px;
		}

		.rightDetaile {
			font-weight: 400;
			font-size: 20px;
			color: #777A8A;
			line-height: 42px;
		}
	}

	// .helpRight {}

	.rightTitle {
		width: 1252px;
		height: 71px;
		font-weight: 600;
		font-size: 20px;
		color: #1D223C;
		line-height: 71px;
		padding-left: 20px;

	}

	.rightItemAction {
		overflow: hidden;
		border-radius: 12px 12px 12px 12px;
		border: 1px solid #0085FF;
	}

	.rightTitleAction {
		color: #0085FF;
	}

	// ::v-deep .el-collapse-item__header .is-active{
	//  color: #0085FF;
	// }
</style>