<template>
	<div>
		<div class="roleTitle">更改绑定手机
			<span class="balance">｜您验证的手机{{phoneEncryption(userInfo.account)}}，若已停用无法接受短信，<span style="color: #0085FF;">请点击这里</span></span>
		</div>
		<div class="stepList">
			<el-steps :active="step" align-center>
				<el-step title="校验身份">
					<div slot="icon">
						<div class="step" :class="{'stepAction': step>=1 }">1</div>
					</div>
				</el-step>
				<el-step title="绑定新手机">
					<div slot="icon">
						<div class="step" :class="{'stepAction': step>=2 }">2</div>
					</div>
				</el-step>
				<el-step title="设置完成">
					<div slot="icon">
						<div class="step" :class="{'stepAction': step>=3 }">
							✔
						</div>
					</div>
				</el-step>
			</el-steps>
		</div>

		<div class="submitForm" v-if="step == 1">
			<div class="fl formItem">
				<div class="submitLable">
					<span class="lableText">已绑定手机：</span>
				</div>
				<div class="submitValue">
					{{phoneEncryption(userInfo.account)}}
				</div>
			</div>
			<div class="fl formItem">
				<div class="submitLable">
					<span class="lableText">短信验证码：</span>
				</div>
				<div class="submitValue">
					<el-input v-prevent-space v-model="code" placeholder="请输入验证码"></el-input>
					<span @click="getCodes(userInfo.account,'7')" class="getCode">{{countDown || '获取验证码'}}</span>
				</div>
			</div>
			<div class="fl formItem">
				<div class="submitLable">
					&ensp;
				</div>
				<div class="submitValue">
					<el-button type="primary" @click="verifyPhone">下一步</el-button>
				</div>
			</div>
		</div>

		<div class="submitForm" v-if="step == 2">
			<div class="fl formItem">
				<div class="submitLable">
					<span class="lableText">绑定新手机：</span>
				</div>
				<div class="submitValue">
					<el-input v-prevent-space v-model="userUpPhoneInfo.phone" type="number" placeholder="请输入您的新手机"></el-input>
				</div>
			</div>
			<div class="fl formItem">
				<div class="submitLable">
					<span class="lableText">短信验证码：</span>
				</div>
				<div class="submitValue">
					<el-input v-prevent-space v-model="userUpPhoneInfo.code" type="number" placeholder="请输入验证码"></el-input>
					<span class="getCode" @click="getCodes1(userUpPhoneInfo.phone,'3')">{{countDown1 || '获取验证码'}}</span>
				</div>
			</div>
			<div class="fl formItem">
				<div class="submitLable">
					&ensp;
				</div>
				<div class="submitValue">
					<el-button type="primary" @click="setAccount">下一步</el-button>
				</div>
			</div>
		</div>



		<div class="status" v-if="step == 3 ">
			<img :src="require('@/static/image/mine/status1.png')" style="width: 172px;height: 164px;" />
			<span class="statusTitle">恭喜您！手机号换绑成功</span>
			<el-button type="primary" style="margin-top: 12px;font-size: 22px;" @click="step =1">返回个人信息</el-button>
		</div>


	</div>
</template>

<script>
	import {
		smsVerify,
		userUpPhone
		
	} from '@/api/index.js'
	export default {
		data() { 
			return {
				step: 1,
				userInfo:{},
				code:'',
				// 倒计时
				countDown: 0,
				countdownInterval: null,
				
				// 倒计时
				countDown1: 0,
				countdownInterval1: null,
				userUpPhoneInfo:{
					code:'',
					phone:''
				}
			}
		},
		created() {
			if (this.$store.state.userInfo != undefined) {
				this.userInfo = this.$store.state.userInfo
			}
		},
		methods:{
			//设置新手机号
			setAccount(){
				if(this.userUpPhoneInfo.phone == ''){
					this.message('请输入手机号','error')
					return
				}
				if(this.userUpPhoneInfo.code == ''){
					this.message('请输入验证码','error')
					return
				}
				userUpPhone(this.userUpPhoneInfo).then(res=>{
					if(res.code == 200){
						this.step = 3
					}
				})
			},
			// 验证手机号
			verifyPhone(){
				if(this.code == ''){
					this.message('请输入短信验证码','error')
					return
				}
				smsVerify({
					code:this.code,
					phoneNum:this.userInfo.account,
				}).then(res=>{
					if(res.code == 200){
						this.step = 2
					}
					
				})
			},
			
			getCodes(account, type){
				if (this.countDown > 0) {
					return
				}
				this.getCode(type, account).then(res => {
					this.startCountdown();
				})
			},
			startCountdown() {
				this.countDown = 60; // 初始倒计时时间（秒）  
				this.countdownInterval = setInterval(() => {
					this.countDown--;
					if (this.countDown <= 0) {
						clearInterval(this.countdownInterval);
						this.countDown = 0;
					}
				}, 1000);
			},
			getCodes1(account, type){
				if (this.countDown1 > 0) {
					return
				}
				this.getCode(type, account).then(res => {
					this.startCountdown1();
				})
			},
			startCountdown1() {
				this.countDown1 = 60; // 初始倒计时时间（秒）  
				this.countdownInterval1 = setInterval(() => {
					this.countDown1--;
					if (this.countDown1 <= 0) {
						clearInterval(this.countdownInterval1);
						this.countDown1 = 0;
					}
				}, 1000);
			},
			
		}
		
	}
</script>

<style scoped lang="scss">
	.roleTitle {
		font-weight: 600;
		font-size: 24px;
		color: #1D223C;
		width: 950px;
		border-bottom: 1px solid #ECECEC;
		height: 60px;
		line-height: 60px;
		padding-left: 30px;
	}

	.balance {
		font-weight: 400;
		font-size: 16px;
		color: #A5A7B1;
	}

	.stepList {
		width: 600px;
		margin: 30px auto;
	}

	.step {
		width: 27px;
		height: 27px;
		color: #fff;
		// background: #0085FF 0 0 no-repeat transfor;
		text-align: center;
		// transform: rotate(45deg);
		line-height: 27px;
		position: relative;
	}

	.step::before {
		content: "";
		position: absolute;
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
		z-index: -1;
		border-radius: 6px 6px 6px 6px;
		background: #DDDDDD;
		background-size: 100% 100%;
		transform: rotate(45deg);
		// transform: scaleY(-1);

	}

	.stepAction {
		width: 27px;
		height: 27px;
		color: #fff;
		// background: #0085FF 0 0 no-repeat transfor;
		text-align: center;
		// transform: rotate(45deg);
		line-height: 27px;
		position: relative;
	}

	.stepAction::before {
		content: "";
		position: absolute;
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
		z-index: -1;
		border-radius: 6px 6px 6px 6px;
		background: #0085FF;
		background-size: 100% 100%;
		transform: rotate(45deg);
		// transform: scaleY(-1);

	}

	.stepSuccess {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
	}

	::v-deep .el-step__title.is-process {
		color: #C0C4CC;
		font-weight: 500;

	}


	.submitForm {
		margin-top: 50px;
		display: flex;
		flex-direction: column;
		align-items: center;

		.formItem {
			margin-bottom: 30px;

			.submitLable {
				width: 100px;
				text-align: right;

				.lableText {
					font-weight: 400;
					font-size: 16px;
					color: #A5A7B1;
					line-height: 30px;
					position: relative;
				}

			}

			.submitValue {
				width: 336px;
				margin: 0 20px;
				position: relative;

				::v-deep .el-input__inner {
					width: 336px;
					height: 46px;
				}

				::v-deep .el-button--primary {
					width: 180px;
					height: 44px;
					// font-weight: 600;
					font-size: 16px;
				}
			}

			.lableNumber {
				margin: 0 20px;


				font-weight: bold;
				font-size: 24px;
				color: #FF5C00;


			}

			.submitRemark {
				width: 40%;
				color: #A5A7B1;
			}
		}
	}

	.getCode {
		position: absolute;
		right: 5px;
		line-height: 46px;
		font-size: 14px;
		color: #0085FF;
		cursor:pointer;
	}

	.getCode::after {
		content: '';
		position: absolute;
		width: 2px;
		height: 20px;
		left: -10px;
		top: 10px;
		background-color: #ECECEC;
	}
	
	
	.status {
		display: flex;
		flex-direction: column;
		align-items: center;
		// justify-content: center;
		margin-top: 100px;
	
		.statusTitle {
			font-weight: 600;
			font-size: 22px;
			color: #1D223C;
			margin-top: 12px;
		}
	
		.statusDetaile {
			font-weight: 400;
			font-size: 18px;
			color: #A5A7B1;
			margin-top: 12px;
		}
	}
</style>