import request from '@/api/request.js'

// 发送短信
export function smsSend(data) {
  return request({
    url: 'api/sms/send',
    method: 'POST',
    data
  })
}
export function updateSend(data) {
  return request({
    url: 'api/sms/updateSend',
    method: 'POST',
    data
  })
}