import Vue from 'vue'
import Vuex from 'vuex'
import {
	getToken,
	setToken,
	setCookie,
	getCookie,
	removeToken
} from '@/utils/auth.js'
import router from '@/router/index.js'

import {
		registered,
		loginByCode,
		login,
		forgetPassword
	} from '@/api/login.js'

// 应用vuex插件
Vue.use(Vuex)


// 创建并暴露store
export default new Vuex.Store({
	// 数据,相当于data
	state: {
		token: getToken(),
		userInfo: getCookie('saveUserInfo') == 'undefined' || getCookie('saveUserInfo') == undefined  ? {} : JSON.parse(getCookie('saveUserInfo')) ,
		IMlogin:false,
		
		
	},
	//准备getters——用于将state中的数据进行加工
	getters: {
		// state => state.token,
		// userInfo => state.userInfo,
	},
	//准备mutations——用于操作数据（state）
	mutations: {
		saveToken(state, value) {
			state.token = value
		},
		saveUserInfo(state, value) {
			state.userInfo = value
		},
		saveToken(state, value) {
			state.IMlogin = value
		},
	},
	//准备actions——用于响应组件中的动作
	actions: {
		login({commit}, userInfo) {
			return new Promise((resolve, reject) => {
				login(userInfo).then(response => {
					console.log(response)
					const {
						result,
						code
					} = response
					if(code == 200){
						console.log(result.userMessage)
						commit('saveToken', result.token)
						commit('saveUserInfo', result.userMessage)
						setToken(result.token)
						setCookie('saveUserInfo', JSON.stringify(result.userMessage))
						
						resolve()
					}
				}).catch(error => {
					reject(error)
				})
			})
		},
		loginOut({commit}){
			commit('saveToken', '')
			commit('saveUserInfo', 'undefined')
			removeToken()
			setCookie('saveUserInfo', 'undefined')
			// router.push('/login/login')
			
		},
		loginCode({commit}, userInfo) {
			return new Promise((resolve, reject) => {
				loginByCode(userInfo).then(response => {
					console.log(response)
					const {
						result,
						code
					} = response
					if(code == 200){
						commit('saveToken', result.token)
						commit('saveUserInfo', result.userMessage)
						setToken(result.token)
						setCookie('saveUserInfo', JSON.stringify(result.userMessage))
						resolve(result.userMessage)
					}
				}).catch(error => {
					reject(error)
				})
			})
		},
	},
	modules: {}
})