<template>
	<div>
		<div class="noDate" :style="{marginTop:top+'px'}">
			<img :src='image' style="width: 160px;height: 160px;" />
			<span class="noDateText">{{text}}</span>
		</div>
	</div>
</template>

<script>
	export default{
		props:{
			image:{
				type:'String',
				default:require('@/static/image/mine/noDate.png')
			},
			top:{
				type:Number,
				default:70
			},
			text:{
				type:'String', 
				default: '暂无相关数据哦～'
			},
		}
	}
</script>

<style scoped lang="scss">
	.noDate{
		display: flex;
		flex-direction: column;
		
		align-items: center;
	}
	.noDateText{
		font-weight: 400;
		font-size: 18px;
		color: #AEB1C1;
		margin-top: 12px;
	}
</style>