<template>
	<div class="card">
		<el-card shadow="hover" class="cartCard">
			<div class="cardItem" v-for="(item,index) in funcList" :key="index" @click="navTo(item)">
				<!-- :value="getUnRead('252264395636757')" -->
				<el-badge v-if="item.title == '咨询投诉'" class="item">
					<div class="fl" style="flex-direction: column;">
						<img :src="item.image" style="width:25px;height:25px;" />
						<span class="cardTitle">{{item.title}}</span>
					</div>
				</el-badge>
				<div v-else class="fl" style="flex-direction: column;">
					<img :src="item.image" style="width:25px;height:25px;" />
					<span class="cardTitle">{{item.title}}</span>
				</div>

			</div>
			<div @click="$router.push('/serviceCenter/2/0')" class="problem">常见<br />问题</div>

		</el-card>

		<el-dialog title="" :visible.sync="dragging" width="700px" :before-close="handleClose" v-dialogDrag>
			<div style="display: flex;">
				<div class="chat">
					<div class="chatTop fl" style="justify-content: space-between;">
						<!-- 	-->
						<div class="fl">
							<img :src="require('@/static/image/chat/logo.png')"
								style="width:40px;height:40px;border-radius: 10px;margin: 15px;" />
							<div class="chatName">CC咨询客服</div>
						</div>
						<div style="margin-right: 30px;">
							<img style="filter: brightness(0.1);width: 120px;"
								:src="require('@/static/image/index/logo.png')" />
						</div>

					</div>
					<!--@scroll="chatCenter" -->
					<div class="chatCenter" ref="chatCenter">
						<div>
							<div class="recordsLeft">
								<div class="leftInfo">
									<div>
										<span class="infoName">CC咨询客服</span>
									</div>
									<div class="infoCount" style="white-space: pre-wrap;">您好，很高兴为您服务，请问有什么可以帮助您的？
									</div>

								</div>
							</div>
							<div class="recordsLeft">
								<div class="leftInfo">
									<div>
										<span class="infoName">CC咨询客服</span>
									</div>
									<div class="infoCount" style="white-space: pre-wrap;">
										<div>常见问题</div>
										<div>注:如有其他问题请直接联系人工客服！</div>
										<div style="margin-top: 10px;color: #0085FF;cursor: pointer;">
											<div @click="toService(item)" v-for="(item,index) in commonProblemList">
												{{item.title}}</div>
										</div>

									</div>
								</div>
							</div>
						</div>
						<div v-for="(item,index) in messageList">
							<div class="recordsLeft" v-if="item.from != $store.state.userInfo.id">
								<div class="leftInfo">
									<div>
										<span class="infoName">咨询客服</span>
									</div>
									<div class="infoCount" style="white-space: pre-wrap;" v-html="renderTxt(item.msg)"
										v-if="item.type == 'txt'">
									</div>
									<div class="infoCount" v-if="item.type == 'img'">
										<el-image :preview-src-list="[item.url]" style="width: 200px; height: 200px"
											:src="item.url" fit="cover"></el-image>
									</div>
									<div class="infoCount" v-if="item.type == 'video'">
										<video width="300" controls :src="item.url"></video>
									</div>
								</div>
							</div>
							<div class="recordsRight" v-else>
								<div class="rightInfo">
									<div style="text-align: right;">
										<span class="infoTime">我</span>
										<span class="infoName">{{sjVal(item.time,2)}}</span>
									</div>
									<div class="infoCount" style="white-space: pre-wrap;" v-html="renderTxt(item.msg)"
										v-if="item.type == 'txt'">

									</div>
									<div class="infoCount" v-if="item.type == 'img'">
										<el-image :preview-src-list="[item.url]" style="width: 200px; height: 200px"
											:src="item.url" fit="cover"></el-image>
									</div>
									<div class="infoCount" v-if="item.type == 'video'">
										<video width="300" controls :src="item.url"></video>
									</div>
								</div>

							</div>
						</div>
					</div>

					<div class="chatBottom">
						<div class="bottomTop">
							<div class="bottomLeft fl" ref="dialogBox">
								<div v-if="emojiShow">
									<transition name="el-fade-in-linear">
										<div class="emoji">
											<img v-for="(v,i) in emojiList" :src="require(`@/static/faces/${v}`)"
												:key="i" @click="selectEmoji(i)" class="img-style" />
										</div>
									</transition>
								</div>

								<img @click="emojiShow = true" :src="require('@/static/image/chat/function1.png')"
									class="functionImage" />


								<img @click="selectImage" :src="require('@/static/image/chat/function3.png')"
									class="functionImage" />
								<img @click="selectVideo" :src="require('@/static/image/chat/function4.png')"
									class="functionImage" />

								<span @click="artificial" v-if="Object.keys(roomInfo).length == 0"
									style="color: #0085FF;margin-left: 20px;cursor: pointer;">
									转人工
								</span>
							</div>
						</div>

						<div class="chatArea">
							<el-input v-prevent-space ref="chatValue" v-model="chatValue" type="textarea"></el-input>
							<div style="display: flex;justify-content: flex-end;padding: 10px;"><el-button
									@click="sendMessage" type="primary">发送</el-button>
							</div>
						</div>
					</div>
				</div>
			</div>
		</el-dialog>

		<input type="file" id="image" @change="onFileChange" accept="image/*" style="display: none;" />
		<input type="file" id="video" @change="onVideoChange" accept="video/*" style="display: none;" />

	</div>
</template>

<script>
	import {
		consult,
		commonProblemList
	} from '@/api/index.js'
	import websdk from 'easemob-websdk'
	import {
		conn
	} from '@/assets/utils/WebIM/initWeb.js'
	// 引入表情资源
	import emoji from '@/config/emoji';
	import {
		EventBus
	} from '@/static/js/event-bus.js';
	export default {
		name: 'HelloWorld',
		data() {
			return {
				chatValue: '',
				messageList: [],
				roomInfo: {},
				keyCodeList: [],
				// 表情资源
				emojiList: emoji.obj,
				emojiShow: false,
				funcList: [{
						image: require('@/static/image/index/cart1.png'),
						title: '咨询投诉',
						router: '/chatRoom'
					},
					{
						image: require('@/static/image/index/cart6.png'),
						title: '挑选商品',
						router: '/game/select/0'
					},
					{
						image: require('@/static/image/index/cart2.png'),
						title: '上架商品',
						router: '/game/add/add'
					},
					{
						image: require('@/static/image/index/cart3.png'),
						title: '购物车',
						router: '/shoppingCart'
					},
					{
						image: require('@/static/image/index/cart4.png'),
						title: '订单聊天',
						router: '/chat/0'
					},
					{
						image: require('@/static/image/index/cart5.png'),
						title: '我的收藏',
						router: '/collect'
					}
				],

				dragging: false,
				startX: 0,
				startY: 0,
				left: 0,
				top: 0,
				commonProblemList: []
			}
		},
		mounted() {
			// 可以在这里设置初始位置（如果需要）  
			this.left = 50;
			this.top = 50;
			window.addEventListener('keydown', this.handleKeyDown);
			window.addEventListener('keyup', this.handleKeyUp);
			// 监听全局点击事件
			document.addEventListener('click', (e) => {
				if (this.$refs.dialogBox && !this.$refs.dialogBox.contains(e.target)) {
					this.emojiShow = false
				}
			});
			EventBus.$on('sericeCard', (msg) => {
				consult().then(res => {
					if (res.code == 200) {
						this.roomInfo = res.result
						this.messageList = []
						this.getHistoryMessages(res.result.chatroomId, 'groupChat')
						this.dragging = true
					}
				})
			});

		},

		destroyed() {
			window.removeEventListener('keydown', this.handleKeyDown);
			window.removeEventListener('keyup', this.handleKeyUp);
			// conn.close();
		},
		methods: {
			getUnRead(id) {
				if (localStorage.getItem(id) == null) {
					return ''
				} else {
					let oldDate = JSON.parse(localStorage.getItem(id))
					return oldDate.unRead
				}
			},
			toService(item) {
				window.open(this.$router.resolve({
					path: '/serviceCenter/2/' + item.id
				}).href, '_blank');

			},
			// 监听键盘事件
			handleKeyDown(e) {
				console.log(e)
				// 快捷键思路 列如alt+z 触发事件 按下是将alt的code码push到一个数组内 在次触发按下事件时假如监听的code时z就触发方法
				// 已按下shift建
				if (e?.keyCode == 16) {
					this.keyCodeList?.push(e?.keyCode)
				}
				// 是否已经按下了shift键
				const isShift = this.keyCodeList?.includes(16)
				// 触发工单 shift+回车
				const isWorkOrder = isShift && e?.keyCode == 13

				if (isWorkOrder) {
					console.log('按下shift+回车键')
					// this.chatValue += '\n'
					this.keyCodeList = []
					return
				}
				if (e?.keyCode == 13) {
					this.sendMessage()
				}
			},
			handleKeyUp() {
				this.keyCodeList = []
			},
			artificial() {
				consult().then(res => {
					if (res.code == 200) {
						this.roomInfo = res.result
						this.messageList = []
						this.getHistoryMessages(res.result.chatroomId, 'groupChat')
					}
				})
			},
			//登陆
			loginIM() {
				let that = this
				return new Promise((resolve, reject) => {
					conn.close();
					var options = {
						user: this.$store.state.userInfo.id,
						pwd: 123456,
						appKey: WebIM.config.appkey,
						success: function(res) {},
						error: function(err) {
							console.log('登录失败l ')
							// console.log(err)
						}
					};
					conn.open(options).then(() => {

						that.getMessage();
						resolve();
					}).catch((reason) => {

						// conn.close();
						// that.loginIM() 
						console.log("login fail", reason);
					});


				});

			},
			navTo(item) {
				if (item.router == '/chatRoom') {
					this.dragging = true
					commonProblemList({
						pageNo: '1',
						pageSize: '1000'
					}).then(res => {
						if (res.code == 200) {
							this.commonProblemList = res.result.records
						}
					})


					return
				} else if (item.router == '/game/select/0' || item.router == '/game/add/add') {
					window.open(this.$router.resolve({
						path: item.router,

					}).href, '_blank');
				} else {
					this.$router.push(item.router)
				}

			},
			// 获取会话列表
			getHistoryMessages(id, type) {
				let that = this

				// 判断是否登录
				if (conn.isOpened()) {

					conn.getHistoryMessages({
						targetId: id, // 单聊为对端用户 ID，群组聊天为群组 ID。
						chatType: type, // 会话类型：单聊、群组聊天和聊天室分别为 `singleChat`、`groupChat` 和 `chatRoom`。
						pageSize: 10, // 每次获取的消息数量，取值范围为 [1,50]，默认值为 `20`。
						searchDirection: 'up', // 消息搜索方向。`up` 表示按消息时间戳递减的方向获取，即先获取最新消息；`down` 表示按消息时间戳递增的方向获取，即先获取最老的消息。
					}).then(res => {
						this.messageList = res.messages.reverse()
						setTimeout(() => {
							this.handleScroll()
						}, 800)
					})



					conn.listen({
						onTextMessage: function(message) {
							console.log('收到文本消息')
							console.log(message)
							// 普通文本信息
							let value = {
								type: 'txt',
								id: message.id,
								msg: message.data,
								to: message.to,
								from: message.from,
								time: Number(message.time),
								chatType: message.type
							}
							if (value.msg.indexOf('结束服务') != -1) {
								that.roomInfo = {}
								that.messageList = []
								that.message('客服已关闭咨询，如有问题请继续点击转人工')

							}
							// 进行本地存储
							if (localStorage.getItem(message.from) == null) {
								console.log('进行本地存储')
								// 进行本地存储
								localStorage.setItem(message.from, JSON.stringify({
									msgList: [value],
									unRead: 1
								}));
							} else {
								let oldDate = JSON.parse(localStorage.getItem(message
									.from))
								oldDate.msgList = [...oldDate.msgList, ...[value]]
								oldDate.unRead = Number(oldDate.unRead) + 1
								localStorage.setItem(message.from, JSON.stringify(
									oldDate));
							}
							if (message.to == that.roomInfo.chatroomId) {
								that.messageList.push(value)
								setTimeout(() => {
									that.handleScroll()
								}, 200)
							}
						},
						onPictureMessage: function(message) {


							let value = {
								type: 'img',
								id: message.id,
								url: message.url,
								to: message.to,
								from: message.from,
								time: Number(message.time),
								chatType: message.type
							}
							// 进行本地存储
							if (localStorage.getItem(message.from) == null) {
								console.log('进行本地存储')
								// 进行本地存储
								localStorage.setItem(message.from, JSON.stringify({
									msgList: [value],
									unRead: 1
								}));
							} else {
								let oldDate = JSON.parse(localStorage.getItem(message
									.from))
								oldDate.msgList = [...oldDate.msgList, ...[value]]
								oldDate.unRead = Number(oldDate.unRead) + 1
								localStorage.setItem(message.from, JSON.stringify(
									oldDate));
							}
							if (message.to == that.roomInfo.chatroomId) {
								that.messageList.push(value)
								setTimeout(() => {
									that.handleScroll()
								}, 200)
							}
						},
						onVideoMessage: function(message) {
							let value = {
								type: 'video',
								id: message.id,
								url: message.url,
								to: message.to,
								from: message.from,
								time: Number(message.time),
								chatType: message.type
							}
							// 进行本地存储
							if (localStorage.getItem(message.from) == null) {
								console.log('进行本地存储')
								// 进行本地存储
								localStorage.setItem(message.from, JSON.stringify({
									msgList: [value],
									unRead: 1
								}));
							} else {
								let oldDate = JSON.parse(localStorage.getItem(message
									.from))
								oldDate.msgList = [...oldDate.msgList, ...[value]]
								oldDate.unRead = Number(oldDate.unRead) + 1
								localStorage.setItem(message.from, JSON.stringify(
									oldDate));
							}
							if (message.to == that.roomInfo.chatroomId) {
								that.messageList.push(value)
								setTimeout(() => {
									that.handleScroll()
								}, 200)
							}
						}
					});



				} else {
					var options = {
						user: this.$store.state.userInfo.id,
						pwd: 123456,
						appKey: WebIM.config.appkey,
						success: function(res) {
							conn.listen({
								onTextMessage: function(message) {
									console.log('收到文本消息')
									console.log(message)
									// 普通文本信息
									let value = {
										type: 'txt',
										id: message.id,
										msg: message.data,
										to: message.to,
										from: message.from,
										time: Number(message.time),
										chatType: message.type
									}
									// 进行本地存储
									if (localStorage.getItem(message.from) == null) {
										console.log('进行本地存储')
										// 进行本地存储
										localStorage.setItem(message.from, JSON.stringify({
											msgList: [value],
											unRead: 1
										}));
									} else {
										let oldDate = JSON.parse(localStorage.getItem(message
											.from))
										oldDate.msgList = [...oldDate.msgList, ...[value]]
										oldDate.unRead = Number(oldDate.unRead) + 1
										localStorage.setItem(message.from, JSON.stringify(
											oldDate));
									}
									if (message.to == that.roomInfo.chatroomId) {
										that.messageList.push(value)
										setTimeout(() => {
											that.handleScroll()
										}, 200)
									}
								},
								onPictureMessage: function(message) {


									let value = {
										type: 'img',
										id: message.id,
										url: message.url,
										to: message.to,
										from: message.from,
										time: Number(message.time),
										chatType: message.type
									}
									// 进行本地存储
									if (localStorage.getItem(message.from) == null) {
										console.log('进行本地存储')
										// 进行本地存储
										localStorage.setItem(message.from, JSON.stringify({
											msgList: [value],
											unRead: 1
										}));
									} else {
										let oldDate = JSON.parse(localStorage.getItem(message
											.from))
										oldDate.msgList = [...oldDate.msgList, ...[value]]
										oldDate.unRead = Number(oldDate.unRead) + 1
										localStorage.setItem(message.from, JSON.stringify(
											oldDate));
									}
									if (message.to == that.roomInfo.chatroomId) {
										that.messageList.push(value)
										setTimeout(() => {
											that.handleScroll()
										}, 200)
									}
								},
								onVideoMessage: function(message) {
									let value = {
										type: 'video',
										id: message.id,
										url: message.url,
										to: message.to,
										from: message.from,
										time: Number(message.time),
										chatType: message.type
									}
									// 进行本地存储
									if (localStorage.getItem(message.from) == null) {
										console.log('进行本地存储')
										// 进行本地存储
										localStorage.setItem(message.from, JSON.stringify({
											msgList: [value],
											unRead: 1
										}));
									} else {
										let oldDate = JSON.parse(localStorage.getItem(message
											.from))
										oldDate.msgList = [...oldDate.msgList, ...[value]]
										oldDate.unRead = Number(oldDate.unRead) + 1
										localStorage.setItem(message.from, JSON.stringify(
											oldDate));
									}
									if (message.to == that.roomInfo.chatroomId) {
										that.messageList.push(value)
										setTimeout(() => {
											that.handleScroll()
										}, 200)
									}
								}
							});
						},
						error: function(err) {
							console.log('登录失败l ')
							// console.log(err)
						},
					}
					conn.open(options);
				}



				// conn.getHistoryMessages({
				// 	targetId: id, // 单聊为对端用户 ID，群组聊天为群组 ID。
				// 	chatType: type, // 会话类型：单聊、群组聊天和聊天室分别为 `singleChat`、`groupChat` 和 `chatRoom`。
				// 	pageSize: 10, // 每次获取的消息数量，取值范围为 [1,50]，默认值为 `20`。
				// 	searchDirection: 'up', // 消息搜索方向。`up` 表示按消息时间戳递减的方向获取，即先获取最新消息；`down` 表示按消息时间戳递增的方向获取，即先获取最老的消息。
				// }).then(res => {
				// 	this.messageList = res.messages.reverse()
				// 	setTimeout(() => {
				// 		this.handleScroll()
				// 	}, 800)
				// })
			},
			sendMessage() {
				if (Object.keys(this.roomInfo).length == 0) {
					// this.message('如有问题请点击转人工','error')
					return
				}
				let that = this
				if (this.chatValue.split(/[\s\n]/).join('') == '') {
					// this.message('发送内容不能为空!', 'info');
					return;
				}
				//收信息用户id
				let contentMsg = that.chatValue;
				let id = conn.getUniqueId(); // 生成本地消息id
				let msg = new WebIM.message('txt', id); // 创建文本消息
				console.log(this.$store.state.userInfo)
				msg.set({
					msg: contentMsg, // 消息内容
					to: that.roomInfo.chatroomId, // 接收消息对象（用户id）
					chatType: 'groupChat', // 设置为单聊	
					ext: {
						type: 'groupChat',
						chatroomId: that.roomInfo.chatroomId,
						account: this.$store.state.userInfo.account
					},
					success: function(id, serverMsgId) {
						console.log('成功发送消息');
						that.sendMessageSuccessful(contentMsg, that.roomInfo.chatroomId, 'txt');
						that.chatValue = ''
					},
					error: function(e) {
						console.log("发送消息失败");
					}
				});
				if (conn.logOut) {
					this.loginIM().then(result => {
						setTimeout(res => {
							conn.send(msg.body);
							that.chatValue = '';
						}, 1000)
					})
				} else {
					conn.send(msg.body);
					that.chatValue = '';
				}

			},
			sendMessageSuccessful(data, toID, type) {

				let userMsg = {};
				userMsg.to = toID;
				userMsg.from = this.roomInfo.userId;
				userMsg.msg = data;
				if (!this.isEmpty(data.url)) {
					userMsg.url = data.url
				}


				userMsg.time = new Date().getTime();
				userMsg.msgType = type;
				userMsg.type = type;

				this.messageList.push(userMsg);
				// 进行本地存储
				localStorage.setItem(toID, JSON.stringify({
					msgList: this.messageList,
					unRead: ''
				}));

				setTimeout(() => {
					this.handleScroll()
				}, 200)
			},
			handleScroll() {
				this.$refs.chatCenter.scrollTop = this.$refs.chatCenter.scrollHeight - this.$refs.chatCenter
					.offsetHeight - 10
			},
			customEmoji(value) {
				return `<img src="/faces/${value}"  style="width:18px"/>`
			},
			renderTxt(txt = '') {
				let rnTxt = [];
				let match = null;
				const regex = /(\[.*?\])/g;
				let start = 0;
				let index = 0;
				while ((match = regex.exec(txt))) {
					index = match.index;
					if (index > start) {
						rnTxt.push(txt.substring(start, index));
					}
					if (match[1] in emoji.obj) {
						const v = emoji.obj[match[1]];
						rnTxt.push(this.customEmoji(v));
					} else {
						rnTxt.push(match[1]);
					}
					start = index + match[1].length;
				}
				rnTxt.push(txt.substring(start, txt.length));

				return rnTxt.toString().replace(/,/g, '');
			},
			// 选择表情
			selectEmoji(item) {
				if (this.chatValue == null) {
					this.chatValue = ''
				}
				this.chatValue += item
				this.$refs.chatValue.focus()
			},
			// 选择图片
			selectImage() {
				document.getElementById("image").click()
			},
			// 选择视频
			selectVideo() {
				document.getElementById("video").click()
			},
			//  上传图片
			onFileChange(e) {
				this.sendPrivateImg()
			},
			// 上传视频
			onVideoChange(e) {
				this.sendPrivateVideo()
			},
			sendPrivateVideo() {

				let that = this
				// 选择本地视频文件。
				let input = document.getElementById("video");
				let file = WebIM.utils.getFileUrl(input);
				let allowType = {
					mp4: true,
					wmv: true,
					avi: true,
					rmvb: true,
					mkv: true,
				};
				if (file.filetype.toLowerCase() in allowType) {

					let chatType = '';
					let targetId = '';

					// if (Object.keys(this.singleChatInfo).length != 0) {
					// 	chatType = 'singleChat'
					// 	if (this.singleChatInfo.userId == this.user) {
					// 		targetId = this.singleChatInfo.consultId
					// 	} else {
					// 		targetId = this.singleChatInfo.userId
					// 	}
					// } else {
					// 	if (this.roleIm == 'user') {
					// 		chatType = 'groupChat'
					// 		targetId = this.userInfo.chatroomId
					// 	} else {
					// 		chatType = 'singleChat'
					// 		targetId = this.serveInfo.id
					// 	}
					// }
					let option = {
						// 消息类型。
						type: "video",
						file: file,
						// 消息接收方：单聊为对方用户 ID，群聊和聊天室分别为群组 ID 和聊天室 ID。
						to: that.roomInfo.chatroomId,
						// 会话类型：单聊、群聊和聊天室分别为 `singleChat`、`groupChat` 和 `chatRoom`。
						chatType: 'groupChat',
						ext: {
							type: 'groupChat',
							chatroomId: that.roomInfo.chatroomId,
							account: this.$store.state.userInfo.account
						},
						onFileUploadError: function() {
							// 视频文件上传失败。
							console.log("onFileUploadError");
						},
						onFileUploadProgress: function(e) {
							// 视频文件上传进度。
							console.log(e);
						},
						onFileUploadComplete: function() {
							// 视频文件上传成功。
							console.log("onFileUploadComplete");
						},
						ext: {},
					};
					// 创建一条视频消息。
					let msg = WebIM.message.create(option);
					// 调用 `send` 方法发送该视频消息。
					if (conn.logOut) {
						this.loginIM().then(result => {
							setTimeout(res => {
								conn
									.send(msg)
									.then((res) => {
										// that.messageList.push(res.message);
										// setTimeout(() => {
										// 	that.handleScroll()
										// }, 200)
										console.log(res)
										that.sendMessageSuccessful(res.message, that.roomInfo
											.chatroomId, 'video');
									})
									.catch((e) => {
										// 视频文件发送失败。例如，本地用户被禁言或封禁。
										console.log("Fail");
									});
							}, 1000)
						})
					} else {
						conn
							.send(msg)
							.then((res) => {
								// that.messageList.push(res.message);
								// setTimeout(() => {
								// 	that.handleScroll()
								// }, 200)
								console.log(res)
								that.sendMessageSuccessful(res.message, that.roomInfo.chatroomId, 'video');
							})
							.catch((e) => {
								// 视频文件发送失败。例如，本地用户被禁言或封禁。
								console.log("Fail");
							});
					}

				}
			},


			sendPrivateImg() {

				let that = this;
				// 选择本地图片文件。
				let input = document.getElementById("image");
				console.log(input)
				let file = WebIM.utils.getFileUrl(input);
				let allowType = {
					jpg: true,
					gif: true,
					png: true,
					bmp: true,
				};
				if (file.filetype.toLowerCase() in allowType) {
					let chatType = '';
					let targetId = '';
					let option = {
						// 消息类型。
						type: "img",
						file: file,
						ext: {
							// 图片文件长度，单位为字节。
							file_length: file.data.size,
							account: this.$store.state.userInfo.account

						},
						// 消息接收方：单聊为对方用户 ID，群聊和聊天室分别为群组 ID 和聊天室 ID。
						to: that.roomInfo.chatroomId,
						// 会话类型：单聊、群聊和聊天室分别为 `singleChat`、`groupChat` 和 `chatRoom`。
						chatType: 'groupChat',
						// 图片文件上传失败。
						onFileUploadError: function() {
							console.log("onFileUploadError");
						},
						// 图片文件上传进度。
						onFileUploadProgress: function(e) {
							console.log(e);
						},
						// 图片文件上传成功。
						onFileUploadComplete: function() {
							console.log("onFileUploadComplete");
						},
						thumbnailHeight: 200,
						thumbnailWidth: 200,
					};
					// 创建一条图片消息。
					let msg = WebIM.message.create(option);
					// 调用 `send` 方法发送该图片消息。

					if (conn.logOut) {
						this.loginIM().then(result => {
							setTimeout(res => {
								conn
									.send(msg)
									.then((res) => {
										// that.messageList.push(res.message);
										// setTimeout(() => {
										// 	that.handleScroll()
										// }, 200)
										console.log(res.message)
										// 图片文件成功发送。
										that.sendMessageSuccessful(res.message, that.roomInfo
											.chatroomId, 'img');
									})
									.catch((e) => {
										// 图片文件发送失败。
										console.log("Fail");
									});
							}, 1000)
						})
					} else {
						conn
							.send(msg)
							.then((res) => {
								// that.messageList.push(res.message);
								// setTimeout(() => {
								// 	that.handleScroll()
								// }, 200)
								console.log(res.message)
								// 图片文件成功发送。
								that.sendMessageSuccessful(res.message, that.roomInfo.chatroomId, 'img');
							})
							.catch((e) => {
								// 图片文件发送失败。
								console.log("Fail");
							});
					}

				}
			},


		}
	}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
	::v-deep .el-card__body {
		padding: 0;
	}

	.cartCard {
		position: fixed;
		top: 100px;
		right: 30px;
		width: 80px;
		border-radius: 10px;
		z-index: 99999;

		/* height: 588px; */
		img {
			width: 36px;
			height: 36px;
		}

		.cardItem {
			display: flex;
			flex-direction: column;
			align-items: center;
			padding: 10px 0;
			cursor: pointer;
		}


		.cardTitle {
			font-weight: 400;
			font-size: 12px;
			color: #000000;
			margin-top: 8px;
		}

		.problem {
			font-weight: 400;
			font-size: 14px;
			color: #777A8A;
			letter-spacing: 1px;
			text-align: center;
			position: relative;
			padding-top: 20px;
			border-top: 1px solid #ccc;
			padding-bottom: 20px;
			cursor: pointer;
		}

		.problem:hover {
			background-color: rgba(0, 133, 255, .5);
			color: #fff;
		}

		.problem1 {
			font-weight: 400;
			font-size: 18px;
			color: #777A8A;
			letter-spacing: 1px;
			text-align: center;
			position: relative;
			padding-top: 20px;
			// border-top: 1px solid #ccc;
		}

		.cardItem:hover {
			background-color: rgba(0, 133, 255, .5);
			color: #fff;

			.cardTitle {
				color: #fff;
			}
		}

		/* .problem::after{
		position: absolute;
		content: '';
		top: 0;
		left: 23px;
		width: 50px;
		height: 1px;
		background-color: #ccc;
	} */

	}

	/* 宽度低于1920，给主体内容左右加留白 */
	@media screen and (max-width: 1200px) {
		.cartCard {
			display: none;
		}
	}

	.draggable-modal {
		position: fixed;
		/* 或者 absolute，取决于你的需求 */
		z-index: 99999999 !important;
		/* 其他样式 */
	}

	.notice {
		height: 72px;
		background: #0085FF;
	}

	.chatMain {
		width: 1666px;
		height: 952px;
		background: #F9F9F9;
		box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.04);
		border-radius: 20px 20px 20px 20px;
		overflow: hidden;
		display: flex;
		margin-top: 16px;
		margin-bottom: 16px;
	}

	.chatLeft {
		width: 112px;
		padding-bottom: 40px;
		background: #212328;
		color: #A5A7B1;
		overflow: auto;

		.leftItem {
			height: 112px;
			flex-direction: column;
			justify-content: center;
		}

		.leftItem1 {
			height: 90px;
			flex-direction: column;
			justify-content: center;
		}

		.leftText {
			margin-top: 8px;
		}
	}

	.chatLeft::-webkit-scrollbar {
		display: none;
	}

	.account {
		width: 372px;
		height: 100%;
		overflow: auto;
		display: flex;
		flex-direction: column;
		align-items: center;
		position: relative;
		background-color: #fff;
	}

	.search {
		width: 372px;
		z-index: 99;

		.searchInfo {
			margin-left: 12px;
		}
	}

	.account::-webkit-scrollbar {
		display: none;
	}

	.chatList {
		overflow: auto;
	}

	.chatList::-webkit-scrollbar {
		display: none;
	}

	.chatItem {
		margin-top: 16px;
		position: relative;
		border-radius: 12px;
	}

	.infoTitle {
		.infoAccount {
			font-size: 10px;
			background: #FF5C00;
			border-radius: 4px;
			color: #fff;
			padding: 1px 3px;
		}

		font-weight: 600;
		font-size: 14px;
		line-height: 30px;
		color: #000000;
		width: 250px;
	}

	.idItem {
		font-weight: 400;
		font-size: 14px;
		color: #A5A7B1;
		margin-bottom: 5px;
	}

	.chat {
		width: 700px;
		// border-radius: 20px;
		position: relative;

		.chatTop {
			display: flex;
			background-color: #0085FF;
			height: 60px;
			// margin-top: -20px;
			border-radius: 10px 10px 0 0;

			.chatTopItem {
				width: 157px;
				height: 54px;
				border-radius: 4px;
				border: 1px solid #DEDEDE;
				display: flex;
				align-items: center;
				padding-left: 16px;
				font-weight: 600;
				font-size: 16px;
				color: #0085FF;
			}

			.chatAction {
				background-color: #ECF9FF;
			}
		}
	}

	.chatCenter {
		height: 370px;
		// width: 624px;
		padding-right: 16px;
		overflow: auto;
	}

	.chatCenter::-webkit-scrollbar {
		display: none;
	}

	.recordsLeft {
		margin-top: 20px;
		margin-left: 16px;
		display: flex;

	}

	.recordsRight {
		margin-top: 20px;
		margin-left: 16px;
		display: flex;
		justify-content: flex-end;
	}

	.leftInfo {
		margin-left: 8px;

	}

	.rightInfo {

		display: grid;
		justify-items: end;
		/* 水平居中 */
		align-items: center;
		/* 垂直居中 */
		margin-right: 8px;

	}

	.infoName {
		font-weight: 400;
		font-size: 14px;
		color: #0085FF;
	}

	.infoTime {
		font-weight: 400;
		font-size: 16px;
		color: #A5A7B1;
		margin-left: 8px;
	}

	.infoCount {
		max-width: 296px;
		display: inline-block;
		font-size: 12px;
		background: #F1F1F1;
		border-radius: 8px;
		padding: 12px 16px;
		margin-top: 4px;

	}

	.chatBottom {
		border-top: 1px solid #ECECEC;
		height: 180px;
		// position: absolute;
		bottom: 0;
		z-index: 99;
		left: 0;
		width: 630px;
		padding: 18px;
	}

	.bottomTop {
		display: flex;
		align-items: center;
		justify-content: space-between;

		.bottomTips {
			font-weight: 400;
			font-size: 16px;
			color: #FF5C00;
			margin-right: 10px;
		}
	}

	.functionImage {
		width: 20px;
		height: 20px;
		margin-right: 15px;
	}

	::v-deep .el-textarea__inner {
		border: none;
		margin-top: 10px;
		// width: 580px;
		height: 100px;
		background-color: #F9F9F9;

	}

	.chatArea {
		font-size: 14px;
		color: #000;
		background-color: #F9F9F9;
	}


	.orderAll {
		background-color: #fff;
		width: 538px;
		overflow: auto;

		.orderTop {
			padding: 16px;

			.orderImage {
				width: 60px;
				height: 60px;
			}

			.orderTitle {
				font-weight: 600;
				font-size: 20px;
				color: #1D223C;
			}

			.orderDetaile {
				font-weight: 400;
				font-size: 16px;
				color: #A5A7B1;
			}

			.orderState {
				display: flex;
				flex-direction: column;
				margin-left: 12px;
			}
		}

		.stateList {
			display: flex;
			justify-content: right;
			margin-top: 8px;
			padding-bottom: 8px;
			border-bottom: 1px solid #ECECEC;
			width: 100%;

			span {
				margin-left: 8px;
			}

			.state1 {
				font-weight: 400;
				font-size: 14px;
				color: #FFFFFF;
				height: 22px;
				padding: 4px 12px;
				background: #19D972;
				border-radius: 6px 6px 6px 6px;
			}

			.state2 {
				font-weight: 400;
				font-size: 14px;
				color: #FFFFFF;
				height: 22px;
				padding: 4px 12px;
				background: #FF5C00;
				border-radius: 6px 6px 6px 6px;
			}

			.cancelState {
				font-weight: 400;
				font-size: 14px;
				height: 22px;
				color: #777A8A;
				padding: 3px 12px;
				border: 1px solid #DEDEDE;
				border-radius: 6px 6px 6px 6px;
			}
		}
	}

	.orderAll::-webkit-scrollbar {
		display: none;
	}

	.takeInfo {
		padding: 8px 12px;
		border-bottom: 1px solid #ECECEC;

		.takeTitle {
			font-weight: 600;
			font-size: 16px;
			color: #1D223C;
			line-height: 24px;
			margin-left: 18px;
			position: relative;
		}

		.takeTitle::before {
			position: absolute;
			content: '';
			top: 0;
			left: -15px;
			width: 3px;
			height: 24px;
			background: #FF5C00;

		}

		.takeTitle1 {
			font-weight: 600;
			font-size: 16px;
			color: #1D223C;
			line-height: 24px;
			margin-left: 18px;
			position: relative;
		}

		.takeTitle1::before {
			position: absolute;
			content: '';
			top: 0;
			left: -15px;
			width: 3px;
			height: 24px;
			background: #0085FF;

		}

		.infoItem {
			font-weight: 400;
			font-size: 16px;
			color: #A5A7B1;
			margin-top: 12px;
		}
	}

	.takeSubmit {

		height: 54px;
		background: #FAFAFA;
		border-radius: 6px 6px 6px 6px;
		padding-left: 12px;
		margin-top: 16px;

		.takeText {
			font-weight: 400;
			font-size: 16px;
			color: #A5A7B1;

			line-height: 40px;
		}

		.takeInput {
			width: 225px;
			height: 40px;
			border-radius: 4px 4px 4px 4px;
		}

		.takeBtn {
			width: 56px;
			height: 38px;
			padding: 0;
			margin-left: 8px;
		}
	}

	// ::v-deep .el-timeline-item__node:last-child{
	// 	border: 4px solid #FF5C00;
	// }
	::v-deep .el-timeline {
		margin-left: -40px;
	}

	::v-deep .el-timeline-item__tail {
		border-left: 2px dotted #E4E7ED;

	}

	.infoTitle1 {
		font-weight: 600;
		font-size: 14px;
		line-height: 30px;
		color: #0085FF;
		width: 250px;
	}

	.idItems {
		font-weight: 400;
		font-size: 14px;
		color: #A5A7B1;
		margin-top: 3px;
	}

	::v-deep .el-table th {
		background: #FAFAFA;
		color: #000;
	}

	.leftAction {
		background: linear-gradient(270deg, rgba(0, 133, 255, 0.3) 0%, rgba(0, 133, 255, 0) 100%);
		color: #0085FF;
	}

	.img-style {
		margin-left: 10px;
		margin-bottom: 10px;
	}

	.bottomLeft {
		position: relative;

		.emoji {
			position: absolute;
			bottom: 60px;
			left: -100px;
			width: 300px;
			background-color: #fff;
			padding: 15px;
			border-radius: 5px;
			border: 1px solid #E4E7ED;
			box-shadow: 0 2px 12px 0 rgba(0, 0, 0, .1);
		}

		// el-popover el-popper


	}

	.dialogCenter {
		border-top: 1px solid #FAFAFA;
		border-bottom: 1px solid #FAFAFA;
		flex-direction: column;
		padding: 10px 0;

		.deleteTitle {
			font-weight: 600;
			font-size: 20px;
			color: #1D223C;
			text-align: center;
			margin: 20px;
		}

		.deleteDetaile {
			font-weight: 400;
			font-size: 16px;
			color: #777A8A;
			text-align: center;
			line-height: 30px;
		}

	}

	.dialogLable {

		font-size: 16px;
		color: #000000;
		width: 100px;
		justify-content: space-between;
		margin-left: 40px;
		position: relative;

	}

	.must {
		position: relative;
	}

	.must::after {
		position: absolute;
		content: '*';
		color: red;
		top: 0;
		left: -20px;
	}

	.dialogValue {
		position: relative;

		::v-deep .el-select-dropdown {
			position: absolute !important;
			top: auto !important;
			left: auto !important;
		}
	}

	.formItem {
		margin-bottom: 20px;

		.submitLable {
			width: 100px;
			text-align: right;

			.lableText {
				font-weight: 400;
				font-size: 18px;
				color: #1D223C;
				line-height: 30px;
				position: relative;
			}


		}

		.submitValue {
			margin: 0 20px;
			position: relative;

			.getCode {
				position: absolute;
				color: #0085FF;
				right: 10px;
				top: 10px;
			}

			::v-deep .el-cascader {
				width: 400px;
			}

			::v-deep .el-input__inner {
				width: 300px;
			}

			::v-deep .el-popper {
				position: absolute !important;
				top: auto !important;
				left: auto !important;
			}

			::v-deep .el-select-dropdown {
				position: absolute !important;
				top: auto !important;
				left: auto !important;
			}
		}

		.submitRemark {
			// width: 40%;
			margin-left: 50px;
			color: red;
		}
	}

	.chatStatus {
		position: absolute;
		bottom: 5px;
		left: 0;
		width: 80px;
		text-align: center;
		font-size: 14px;
		color: #FFFFFF;
		opacity: 0.8;
		padding: 3px 0;
		letter-spacing: 2px;
		background-color: rgba(0, 0, 0, .8);
		border-bottom-left-radius: 12px;
		border-bottom-right-radius: 12px;
	}

	.chatStatus1 {
		position: absolute;
		top: 10px;
		right: -20px;
		width: 80px;
		text-align: center;
		font-size: 14px;
		color: #FFFFFF;
		background: #FF5C00;
		transform: rotate(45deg);
		// opacity: 0.8;
		letter-spacing: 2px;
		overflow: hidden;
	}

	.formTip {
		font-weight: 400;
		font-size: 16px;
		color: #A5A7B1;
		line-height: 21px;
		text-align: left;
		margin-left: 20px;
	}



	.uploadText {
		font-weight: 400;
		font-size: 18px;
		color: #0085FF;
		line-height: 21px;
		margin-top: 10px;
	}

	.uploadTip {
		font-weight: 400;
		font-size: 16px;
		color: #FF5C00;
		// line-height: 30px;

	}

	.reason {
		::v-deep .el-textarea__inner {
			background: none;
			width: 376px;
			border: 1px solid #C0C4CC;
			height: 100px;
		}
	}

	.search {


		.searchText {
			width: 100px;
			text-align: right;
			font-weight: 400;
			font-size: 18px;
			color: #1D223C;
		}
	}

	.roleTitles {

		width: 1186px;
		border-bottom: 1px solid #ECECEC;
		height: 67px;
		// line-height: 87px;
		padding-left: 12px;
		padding-right: 42px;
		margin-top: 30px;
		display: flex;
		align-items: center;

		.titleItem {
			font-weight: 400;
			font-size: 20px;
			position: relative;
			color: #1D223C;
			height: 30px;
			margin-right: 50px;
		}

		.titleItem::after {
			position: absolute;
			width: 1px;
			height: 20px;
			content: '';
			top: 2px;
			right: -25px;
			background-color: #DEDEDE;
		}

		.titleItem:last-child::after {
			width: 0px;
		}

		.action {
			color: #0085FF;
			font-weight: bold;
		}
	}

	.tableList {
		margin: 20px;
		width: 1400px;
		height: 500px;
		overflow-y: auto;
		scrollbar-width: none;
	}

	.popUp {
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		z-index: 999;
		background-color: rgba(0, 0, 0, .6);
		overflow: hidden;

		.popClose {
			position: absolute;
			top: 20px;
			right: 100px;
			width: 50px;
			height: 50px;
		}

		.popLeft {
			position: absolute;
			top: 450px;
			left: 30px;
			width: 50px;
			height: 50px;
		}

		.popRight {
			position: absolute;
			top: 450px;
			right: 30px;
			width: 50px;
			height: 50px;
		}
	}

	::v-deep .video-js.vjs-fluid {
		height: 100vh;

	}

	.chatActions {
		background-color: #e5f6ff;
	}

	.HandleDrag {
		// background-color: rgba(0, 0, 0, .6);
		// z-index: 9999;
		opacity: .4;
	}

	.payOvertime {
		color: #FF5C00;
		font-size: 20px;
		font-weight: bold;
		position: absolute;
		top: 10px;
		right: -330px;
		width: 200px;
		text-align: center;
	}

	::v-deep .el-dialog__body {
		padding: 0;
		border-radius: 20px;
	}

	::v-deep .el-dialog {
		border-radius: 10px;
	}

	::v-deep .el-dialog__header {
		padding: 0;
		border-radius: 10px 10px 0 0;


	}

	.chatName {
		font-size: 14px;
		color: #fff;
		font-weight: bold;
	}
</style>