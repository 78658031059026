import Vue from 'vue'
import {
	smsSend,
	updateSend
} from '@/api/public.js'
import CryptoJS from 'crypto-js'

Vue.prototype.message = function(message, type) {
	this.$message({
		message: message,
		type: type
	});
}

Vue.prototype.getCode = function(type, account) {
	return new Promise((resolve, reject) => {
		if (!/^1\d{10}$/.test(account)) {
			this.message('手机号码格式不正确', 'error')
			return
		}
		smsSend({
			type: type,
			phoneNum: account
		}).then(res => {
			console.log(res)
			if (res.code == 200) {
				this.message('发送成功', 'success')
				resolve()
			}
		})
	})

}


Vue.prototype.daysBetweenDates = function(date1, date2) {
	// 创建两个Date对象  
	const dateOne = new Date(date1);
	const dateTwo = new Date(date2);

	// 计算时间差（毫秒）  
	const differenceInMs = Math.abs(dateTwo - dateOne);

	// 将毫秒转换为天  
	const days = Math.ceil(differenceInMs / (1000 * 60 * 60 * 24)); // 1000毫秒/秒 * 60秒/分钟 * 60分钟/小时 * 24小时/天  

	return days;
}
// 手机号加密
Vue.prototype.phoneEncryption = function(num) {
	var phone = "" + num;
	return phone.substr(0, 3) + "****" + phone.substr(7);
}
// 姓名加密
Vue.prototype.nameEncryption = function(num) {
	var phone = "" + num;
	return phone.substr(0, 1) + "*" + phone.substr(2, phone.length);
}
// 身份证号加密
Vue.prototype.idCardEncryption = function(num) {
	var phone = "" + num;
	return phone.substr(0, 3) + "***********" + phone.substr(13, phone.length);
}

// 获得当前时间年月日时分秒
Vue.prototype.sjVal = function(data, val) {
	let str = "";
	let date = new Date(data);
	let year = date.getFullYear();
	let month = (date.getMonth() + 1) > 9 ? (date.getMonth() + 1) : ('0' + (date.getMonth() + 1));
	let day = date.getDate() > 9 ? date.getDate() : ('0' + date.getDate());
	let hours = date.getHours() > 9 ? date.getHours() : ('0' + date.getHours()); //获取当前小时数(0-23)
	let minute = date.getMinutes() > 9 ? date.getMinutes() : ('0' + date.getMinutes()); //获取当前分钟数(0-59)
	let seconds = date.getSeconds() > 9 ? date.getSeconds() : ('0' + date.getSeconds()); //获取当前秒数(0-59)
	if (val == 0) { //年月
		str = year + '-' + month;
	} else if (val == 1) { //年月日
		str = year + '-' + month + '-' + day;
	} else if (val == 2) { //时分
		str = hours + ':' + minute;
	} else if (val == 3) { //时分秒
		str = hours + ':' + minute + ':' + seconds;
	} else if (val == 4) { //全部
		str = year + '-' + month + '-' + day + ' ' + hours + ':' + minute + ':' + seconds;
	} else if (val == 5) { //月日 时分
		str = month + '-' + day + ' ' + hours + ':' + minute;
	}else if(val == 6){
		str =  month + '-' + day;
	}
	return str;
}

// 获得当前时间年月日时分秒
Vue.prototype.sjCurrent = function(val) {
	let str = "";
	let date = new Date();
	let year = date.getFullYear();
	let month = (date.getMonth() + 1) > 10 ? (date.getMonth() + 1) : ('0' + (date.getMonth() + 1));
	let day = date.getDate() > 9 ? date.getDate() : ('0' + date.getDate());
	let hours = date.getHours() > 9 ? date.getHours() : ('0' + date.getHours()); //获取当前小时数(0-23)
	let minute = date.getMinutes() > 9 ? date.getMinutes() : ('0' + date.getMinutes()); //获取当前分钟数(0-59)
	let seconds = date.getSeconds() > 9 ? date.getSeconds() : ('0' + date.getSeconds()); //获取当前秒数(0-59)
	if (val == 0) { //年月
		str = year + '-' + month;
	} else if (val == 1) { //年月日
		str = year + '-' + month + '-' + day;
	} else if (val == 2) { //时分
		str = hours + ':' + minute;
	} else if (val == 3) { //时分秒
		str = hours + ':' + minute + ':' + seconds;
	} else if (val == 4) { //全部
		str = year + '-' + month + '-' + day + ' ' + hours + ':' + minute + ':' + seconds;
	} else if (val == 5) { //月日 时分
		str = month + '-' + day + ' ' + hours + ':' + minute;
	} else if (val == 6) { //月日 时分
		str =  year + '-' + month + '-' + day + ' ' + hours + ':' + minute + ':' + seconds;;
	}
	return str;
}

// 判断不为空
Vue.prototype.isEmpty = function(obj, defaultValue) {
	if (obj === undefined || obj === null || obj === 'setEmptyJly' || obj === 'undefined' || obj === 'null' ||
		obj ==='' || obj === '&nbsp;' || obj.length === 0 || obj === false || Object.keys(obj).length == 0) {
		if (defaultValue !== undefined && defaultValue !== null) {
			return defaultValue
		} else {
			return true
		}
	}
	if (defaultValue !== undefined && defaultValue !== null) {
		return obj
	} else {
		return false
	}
}


function encrypt(word, keyStr) {
  keyStr = keyStr || '0123456789ABCDEF'
  const key = CryptoJS.enc.Utf8.parse(keyStr)
  const srcs = CryptoJS.enc.Utf8.parse(word)
  const encrypted = CryptoJS.AES.encrypt(srcs, key, {
    mode: CryptoJS.mode.ECB,
    padding: CryptoJS.pad.Pkcs7
  })
  return encrypted
}

Vue.prototype.decryptBase64 = function(word, keyStr) {
  keyStr = keyStr || '0123456789ABCDEF'
  var key = CryptoJS.enc.Utf8.parse(keyStr)
  var decrypt = CryptoJS.AES.decrypt(word, key, {
    mode: CryptoJS.mode.ECB,
    padding: CryptoJS.pad.Pkcs7
  })
  return CryptoJS.enc.Utf8.stringify(decrypt).toString()
}
Vue.prototype.encryptHex = function(word, keyStr) {
	
  const encrypted = encrypt(word, keyStr)
  return encrypted.toString().toUpperCase()
}

Vue.prototype.encryptBase64 = function(word, keyStr) {
  const encrypted = encrypt(word, keyStr)
  return CryptoJS.enc.Base64.stringify(encrypted.ciphertext)
}

Vue.prototype.decryptHex = function(hexWord, keyStr) {
  const wordArray = CryptoJS.enc.Hex.parse(hexWord)
  const base64Word = CryptoJS.enc.Base64.stringify(wordArray)
  return decryptBase64(base64Word, keyStr)
}


