<template>
	<div>
		<div v-if="type == ''">
			<div class="roleTitle">店铺管理</div>
			<div class="adminMain">
				<div class="shopInfo fl" v-for="(item,index) in tableList">
					<div style="margin-left: 32px;">
						<img :src="item.logo || require('@/static/image/index/hotGoodImage.png')"
							style="width: 80px;height: 80px;border-radius: 50%;" />
					</div>
					<div>
						<div class="infoRight">
							<span class="shopName">{{item.storeName}}，欢迎您！</span>
							<el-button class="infoButton" @click="$router.push('/mineIndex/19')" v-if="item.settledType == 1" type="primary">
								收货商家设置
							</el-button>
							<el-button class="infoButton" @click="$router.push('/mineIndex/20')" v-if="item.settledType == 2" type="primary">
								热卖商家设置
							</el-button>
						</div>
						<div class="infoRight" style="margin-top: 16px;">
							<div class="infoType">商家类型:
								<span style="color: #1D223C;">{{item.settledTypeName}}</span>
							</div>
							<div class="infoType fl">保证金余额：
								<span style="color: #FF5C00;">¥{{item.deposit}}</span>
								<div class="infoBtn1" @click=" payInfo = item ;type = 'retroactively'">补交</div>
								<div class="infoBtn2" @click="getTableList(item.settledType);type = 'detail'">明细</div>
							</div>
							
							<el-popconfirm @confirm="exit(item)" class="idItemHover"
							  title="确定要退出么"
							>
							  <!-- <span   >下架</span> -->
							  <el-button slot="reference" class="infoButton">退出商家</el-button>
							</el-popconfirm>
							
						</div>
					</div>
				</div>
				<el-empty v-if="tableList.length == 0" description="您还未入驻任何店铺"></el-empty>

				<div class="remind">
					<div class="remind1">温馨提示：</div>
					<div class="remind2">1、诚信经营，对违反协议的商家，将会撤销商家资格，严重者将扣除一定诚信保证金。</div>
					<div class="remind2">2、您所缴纳的诚信保证金将冻结在系统账户上， 当您退出商家服务后，该款项会解冻并转入到您的余额账户上。</div>
				</div>
			</div>

			
		</div>
		<div v-if="type == 'retroactively'">
			<div class="roleTitle fl" style="justify-content: space-between;">
				<span>我要补交</span>
				<div class="fl" @click="type = ''">
					<img :src="require('@/static/image/mine/back.png')" style="width: 24px;height: 24px;" />
					<span class="balance" style="margin: 0 60px 0 4px; ">返回上一级</span>
				</div>
			</div>
			<div class="dialogCenter ">
				<div class="fl formItem">
					<div class="submitLable">
						<span class="lableText ">保证金余额：</span>
					</div>
					<div class="submitValue fl">
						<span class="deposit">{{payInfo.deposit}}</span>
						<span class="rule">查看补交规则</span>
					</div>
				</div>
				<div class="fl formItem">
					<div class="submitLable">
						<span class="lableText ">补交金额：</span>
					</div>
					<div style="position: relative;">
						<el-input v-prevent-space v-model="payNumber" class="submitValue"  placeholder="请输入金额" type="number"></el-input>
						<!-- <el-select  :popper-append-to-body="false" class="submitValue"
							v-model="payNumber" placeholder="请选择" filterable allow-create default-first-option>
							<el-option  v-for="(item,index) in rechargeList"
								:key="item" :label="item.money" :value="index">
							</el-option>
						</el-select> -->
					</div>

				</div>

				<div class="fl formItem">
					<div class="submitLable">
						<span class="lableText">支付方式：</span>
					</div>
					<div style="position: relative;">
						<el-select :popper-append-to-body="false" class="submitValue" v-model="payValue"
							placeholder="请选择">
							<el-option v-for="item in payType" :key="item" :label="item.text" :value="item.value">
							</el-option>
						</el-select>
					</div>
				</div>
				<div class="fl formItem">
					<div class="submitLable">

					</div>
					<div style="position: relative;margin-left: 10px;">
						<el-button @click="submitPay" type="primary">确认充值</el-button>
					</div>
				</div>
			</div>

		</div>

		<div v-if="type == 'detail'">
			<div class="roleTitle fl" style="justify-content: space-between;">
				<span>明细</span>
				<div class="fl" @click="type = ''">
					<img :src="require('@/static/image/mine/back.png')" style="width: 24px;height: 24px;" />
					<span class="balance" style="margin: 0 60px 0 4px; ">返回上一级</span>
				</div>
			</div>

			<div class="tableList">
				<el-table ref="multipleTable" :data="tableDetaile" tooltip-effect="dark"
					style="width: 90%;margin-left: 5%;" @selection-change="handleSelectionChange">
					<el-table-column type="index" :index="indexMethod" width="80">
						<template slot="header">
							<div class="cardPrice">序号</div>
						</template>
					</el-table-column>
					<el-table-column>
						<template slot="header">
							<div class="cardPrice">流水号</div>
						</template>
						<template slot-scope="scope">
							<div>
								{{scope.row.id}}
							</div>
						</template>
					</el-table-column>
					<el-table-column prop="name" width="180" align="left">
						<template slot="header">
							<div class="cardPrice">金额</div>
						</template>
						<template slot-scope="scope">
							<div>{{scope.row.price}}</div>
						</template>
					</el-table-column>

					<el-table-column prop="address" width="180">
						<template slot="header">
							<div class="cardPrice">充值方式</div>
						</template>
						<template slot-scope="scope">
							<div>
								<span>{{scope.row.payType_dictText}}</span>
							</div>
						</template>
					</el-table-column>
					<el-table-column prop="address" width="180">
						<template slot="header">
							<div class="cardPrice">类型</div>
						</template>
						<template slot-scope="scope">
							<div>
								<span>{{scope.row.depositType_dictText}}</span>
							</div>
						</template>
					</el-table-column>

					<el-table-column prop="address" width="280">
						<template slot="header">
							<div class="cardPrice">时间</div>
						</template>
						<template slot-scope="scope">
							<div>{{scope.row.createTime}}</div>
						</template>
					</el-table-column>
				</el-table>

				<div class="fl" style="justify-content: space-between;font-size: 18px;margin-top: 20px;">
					<div>

					</div>
					<el-pagination @size-change="getTableList(search.depositType)"
						@current-change="getCurrentList(search.depositType)" :current-page="search.pageNo"
						:page-sizes="[10, 20, 30, 40]" :page-size="search.pageSize"
						layout="total, sizes, prev, pager, next, jumper" :total="totalNumber">
					</el-pagination>
				</div>


			</div>
		</div>


		<div class="popUp" v-if="realShow">
			<div style="width: 100%;justify-content: center;position: relative;" class="fl">
				<img style="width: 522px;height:166px;position: absolute;top: 100px;"
					:src="require('@/static/image/index/payImage1.png')" />
				<div class="popCount">
					<img class="close" @click="realShow = false" :src="require('@/static/image/index/close.png')" />
					<div class="payment-password-input" v-if="showPass">
						<input v-prevent-space ref="inputList" :ref="`input${index}`" v-for="(item, index) in passwordLength"
							:key="index"  type="text"  v-model="password[index]"
							@input="handleInput($event, index)" @keydown="onKeydownBackSpace($event,index)"  />
							<i class="el-icon-view" @click="showPass = !showPass" v-if="!showPass" style="font-size: 28px;margin-left: 10px;" ></i>
							<i class="el-icon-view" @click="showPass = !showPass" v-else style="font-size: 28px;margin-left: 10px;color: #0085FF;" ></i>
							
					</div>
					<div class="payment-password-input " v-else>
						<input v-prevent-space ref="inputList" :ref="`input${index}`" v-for="(item, index) in passwordLength"
							:key="index"  type="password" autoComplete="new-password"  v-model="password[index]"
							@input="handleInput($event, index)" @keydown="onKeydownBackSpace($event,index)"  />
								<i class="el-icon-view" @click="showPass = !showPass" v-if="!showPass" style="font-size: 28px;margin-left: 10px;" ></i>
								<i class="el-icon-view" @click="showPass = !showPass" v-else style="font-size: 28px;margin-left: 10px;color: #0085FF;" ></i>
					</div>
					
					<div class="payOrder">
						<span>实付金额： </span>
						<div>
							<span
								style="color: #FF5C00;font-weight: bold;font-size: 36px;">{{payNumber}}</span>
							<span style="color: #FF5C00;">元</span>
						</div>
					</div>
				</div>
			</div>
		</div>
		
		<div class="popUp" v-if="realShow1">
			<div style="width: 100%;justify-content: center;position: relative;" class="fl">
				<img style="width: 522px;height:166px;position: absolute;top: 100px;"
					:src="require('@/static/image/index/payImage1.png')" />
				<div class="popCount">
					<img class="close" @click="closeReal" :src="require('@/static/image/index/close.png')" />
					
					<img style="width: 240px;height: 240px;margin-top: 40px;"
						:src="payImage" alt="" />
					<div class="popText">使用 支付宝 扫码支付<br />支付即视为你同意 <span style="color: #0085FF;">相关协议</span> 条例</div>
					<div class="payOrder">
						<span>实付金额： </span>
						<div>
							<span
								style="color: #FF5C00;font-weight: bold;font-size: 36px;">{{payNumber}}</span>
							<span style="color: #FF5C00;">元</span>
						</div>
					</div>
				</div>
			</div>
		</div>

	</div>
</template>

<script>
	import {
		storeList,
		orderDepositPay,
		rechargeList,
		orderDepositList,
		quitById,
		orderDepositQueryById
	} from '@/api/index.js'
	export default {
		data() {
			return {
				showPass:false,
				tableList: [],
				type: '',
				realShow1:false,
				realShow: false,
				payValue: 5,
				payInfo: {},
				password: Array(6).fill(''), // 初始化一个长度为6的数组用于存储密码
				passwordLength: 6, // 支付密码的长度
				payNumber: '',
				payImage:'',
				payType: [{
						text: '支付宝',
						value: 5
					},
					// {
					// 	text: '微信',
					// 	value: 2
					// },
					// {
					// 	text: '银行卡',
					// 	value: 3
					// },
					{
						text: '余额',
						value: 4
					},
				],
				search: {
					pageNo: 1,
					pageSize: 10,
					depositType: ''
				},
				totalNumber: '',
				rechargeList: [],
				tableDetaile: [],
				timeInterval:''
			}
		},
		created() {
			this.getList()
		},
		beforeDestroy(){
			clearInterval(this.timeInterval)
			this.timeInterval = null
		},
		methods: {
			getTableList(type) {
				this.search.depositType = type
				orderDepositList(this.search).then(res => {
					if (res.code == 200) {
						this.tableDetaile = res.result.records
						this.totalNumber = res.result.total
					}
				})
			},
			// getRechargeList(type) {
			// 	rechargeList().then(res => {
			// 		if (res.code == 200) {
			// 			this.rechargeList = res.result
			// 		}
			// 	})
			// },
			// getMoney(e){
			// 	console.log(e)
			// },
			// 获取店铺列表
			getList() {
				storeList(this.search).then(res => {
					this.tableList = res.result
				})
			},
			handleInput(event, index) {
				if (event.inputType != 'deleteContentBackward') {
					// 当输入框的值改变时，只允许输入数字并限制输入长度
					if (event.target.value.length > 1) {
						this.password[index] = event.target.value.slice(0, 1);
					}

					this.$nextTick(() => {
						const nextInput = this.$refs[`input${index + 1}`][0];
						if (nextInput) {
							nextInput.focus();
						}
					});

					if (index === this.passwordLength - 1) {
						// 最后一个输入框后，可以触发支付或其他操作
						this.twoPay(4);
					}
				}

			},
			submitPay() {
				if (this.payNumber == '' || this.payNumber == 0) {
					this.message('请输入补交金额', 'error')
					return
				}
				if(this.payValue == 5){
					orderDepositPay({
						depositType: this.payInfo.settledType,
						money:this.payNumber,
						payType: this.payValue,
					}).then(res => {
						if (res.code == 200) {
							this.payImage = res.result.qrcode
							this.getPaySuccess(res.result.id)
							this.realShow1 = true
						}
					})
					
				}else{
					this.realShow = true
				}
			},
			closeReal(){
				this.realShow1 = false
				clearInterval(this.timeInterval)
				this.timeInterval = null
			},
			getPaySuccess(id) {
				this.timeInterval = setInterval(() => {
					orderDepositQueryById({
						id: id
					}).then(res => {
						if (res.result.status == 1) {
							clearInterval(this.timeInterval)
							this.timeInterval = null
							this.message('补交成功', 'success')
							this.realShow = false
							this.type = ''
							this.getList()
						}
					})
				}, 3000)
			},
			twoPay(payType) {
				orderDepositPay({
					depositType: this.payInfo.settledType,
					// id: this.rechargeList[this.payNumber].id,
					money:this.payNumber,
					payType: payType,
					password: this.password.join('')
				}).then(res => {
					if (res.code == 200) {
						this.message('补交成功', 'success')
						this.realShow = false
						this.type = ''
						this.getList()
					}
				})
			},
			// 退出商家
			exit(item){
				quitById({
					id:item.id
				}).then(res=>{
					if(res.code ==200){
						this.message('操作成功，请等待审核','success')
						this.getList()
					}
				})
			}

		}
	}
</script>

<style scoped lang="scss">
	.roleTitle {
		font-weight: 600;
		font-size: 24px;
		color: #1D223C;
		width: 950px;
		border-bottom: 1px solid #ECECEC;
		height: 60px;
		line-height: 60px;
		padding-left: 42px;
	}

	.adminMain {
		padding:  42px;
		overflow-y: auto;
		height: 1050px;
		scrollbar-width: none;
	}

	.shopInfo {
		width: 950px;
		height: 136px;
		background: #FFFFFF;
		border-radius: 8px;
		border: 1px solid #DEDEDE;
		margin-bottom: 30px;

		.infoRight {
			margin-left: 24px;
			width: 800px;
			display: flex;
			align-items: center;
			justify-content: space-between;
		}

		.infoType {
			font-weight: 400;
			font-size: 14px;
			color: #A5A7B1;
		}

		.shopName {
			font-weight: 600;
			font-size: 16px;
			color: #000000;
		}

		.infoButton {
			width: 132px;
			height: 40px;
			
			cursor:pointer;
		}

		.infoBtn1 {
			width: 54px;
			height: 30px;
			background: rgba(255, 92, 0, 0.06);
			border-radius: 4px 4px 4px 4px;
			border: 1px solid #FF5C00;
			font-weight: 400;
			font-size: 14px;
			color: #FF5C00;
			text-align: center;
			line-height: 30px;
			margin-left: 20px;
			cursor:pointer;
		}

		.infoBtn2 {
			width: 54px;
			height: 30px;
			border-radius: 4px 4px 4px 4px;
			border: 1px solid #DEDEDE;
			font-weight: 400;
			font-size: 14px;
			color: #777A8A;
			text-align: center;
			line-height: 30px;
			margin-left: 8px;
			cursor:pointer;
		}
	}

	.remind {
		margin-left: 42px;
		margin-top: 50px;

		.remind1 {
			font-weight: 600;
			font-size: 18px;
			color: #000000;
			margin-bottom: 10px;

		}

		.remind2 {
			font-weight: 400;
			font-size: 14px;
			color: #777A8A;
			margin-bottom: 5px;
		}
	}

	.balance {
		font-weight: 400;
		font-size: 16px;
		color: #A5A7B1;
	}

	.dialogCenter {
		margin-top: 30px;
	}

	.formItem {
		margin-bottom: 15px;


		::v-deep .el-select-dropdown {
			position: absolute !important;
			top: auto !important;
			left: auto !important;
		}

		::v-deep .el-cascader__dropdown {
			position: absolute !important;
			top: auto !important;
			left: auto !important;
		}

		::v-deep .el-popper {
			position: absolute !important;
			top: auto !important;
			left: auto !important;
		}

		.submitLable {
			width: 200px;
			text-align: right;

			.lableText {
				font-weight: 400;
				font-size: 16px;
				color: #1D223C;
				line-height: 30px;
				position: relative;
			}

		}

		.submitValue {
			width: 336px;
			margin: 0 10px;
			position: relative;
		}

		.lableNumber {
			margin: 0 20px;


			font-weight: bold;
			font-size: 24px;
			color: #FF5C00;


		}

		.submitRemark {
			// width: 40%;
			color: #A5A7B1;
		}

		.must {
			position: relative;
		}

		.must::after {
			position: absolute;
			content: '*';
			color: #FF5C00;
			top: -5px;
			left: -10px
		}

		.dealTime {
			display: flex;
			align-items: center;
			justify-content: space-between;
		}

		.deposit {

			font-weight: bold;
			font-size: 32px;
			color: #FF5C00;
			line-height: 28px;

		}

		.rule {
			font-weight: 400;
			font-size: 18px;
			color: #0085FF;
			line-height: 21px;
			text-align: left;
			margin-left: 20px;
		}
	}

	.popUp {
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		z-index: 999;
		background-color: rgba(0, 0, 0, .3);
		overflow: hidden;
	}

	.popCount {
		z-index: 9999;
		position: relative;
		margin-top: 220px;
		display: flex;
		flex-direction: column;
		width: 522px;
		border-radius: 24px;
		align-items: center;
		background-color: #fff;
		padding-bottom: 30px;

		.popText {
			width: 264px;
			font-weight: 400;
			font-size: 16px;
			color: #777A8A;
			text-align: center;
			margin-top: 20px;
		}

		.payOrder {
			width: 394px;
			height: 100px;
			background: #F6F8FA;
			border-radius: 12px 12px 12px 12px;
			margin-top: 40px;
			padding: 0 24px;
			color: #A5A7B1;
			display: flex;
			align-items: center;
			justify-content: space-between;
		}

	}

	.close {
		position: absolute;
		top: -100px;
		right: 10px;
		z-index: 999;
	}

	.realSubmit {
		width: 296px;
		height: 66px;
		background: linear-gradient(90deg, #03B5FF 0%, #0085FF 100%);
		border-radius: 8px 8px 8px 8px;
		font-weight: 500;
		font-size: 24px;
		color: #FFFFFF;
		margin-top: 50px;
	}

	.payment-password-input {
		margin-top: 30px;
	}

	.payment-password-input input {
		width: 40px;
		height: 40px;
		margin: 0;
		padding: 10px;
		border: 1px solid #ccc;
		text-align: center;
	}

	.payment-password-input input:focus {
		border-color: #007bff;
	}
</style>