<template>
	<div>
		<div v-if="type == 'recharge'">
			<div class="roleTitle fl" style="justify-content: space-between;">
				<span>我要充值</span>
				<div class="fl" @click="type = ''">
					<img :src="require('@/static/image/mine/back.png')" style="width: 24px;height: 24px;" />
					<span class="balance" style="margin: 0 60px 0 4px; ">返回上一级</span>
				</div>
			</div>
			<div style="margin-top: 68px;">

				<div class="fl formItem">
					<div class="submitLable">
						<span class="lableText">充值金额：</span>
					</div>
					<div class="submitValue">
						<el-input v-prevent-space v-model="payNumber" type="number" placeholder="请输入充值金额"></el-input>
					</div>
					、
				</div>
				<div class="fl formItem">
					<div class="submitLable">
						<span class="lableText">支付方式：</span>
					</div>
					<div style="position: relative;">
						<el-select :popper-append-to-body="false" class="submitValue" v-model="payValue"
							placeholder="请选择">
							<el-option v-for="item in payType" :key="item" :label="item.text" :value="item.value">
							</el-option>
						</el-select>
					</div>
				</div>

				<div class="fl formItem">
					<div class="submitLable">&emsp;</div>
					<div class="submitValue">
						<el-button type="primary" @click="configWithdraw">确认充值</el-button>
					</div>
				</div>
			</div>

		</div>

		<div style="overflow-y: auto;height: 1100px; scrollbar-width: none; " v-else>
			<div class="mine">
				<div class="mineTop fl" style="margin-bottom: 40px;">
					<div class="mineInfo ">
						<img class="infoImage" :src=" users.portrait ||require('@/static/image/index/profile.png')" />
						<span class="infoName el1">{{users.name}}</span>
						<span class="infoCode">邀请码：{{users.userId}} <img v-preventReClick v-clipboard:copy="users.userId"
												v-clipboard:success="onCopy":src="require('@/static/image/index/copy.png')"
								style="width: 16px;height: 16px;margin-left: 4px;" /></span>
						<div class="auths">
							<div class="infoAuth fl" v-if="users.idCardAudit == 1">
								<img :src="require('@/static/image/index/auth2.png')"
									style="width: 16px;height: 16px;margin-right: 3px;" />
								<span>已实名认证</span>
							</div>
							<div class="infoAuth fl">
								<img :src="require('@/static/image/index/auth2.png')"
									style="width: 16px;height: 16px;margin-right: 3px;" />
								<span>已手机认证</span>
							</div>
						</div>
					</div>
					<div class="wallet">
						<div class="walletTop fl">
							<span class="walletText">我的钱包</span>
							<div>
								<el-button class="withdraw" @click="$emit('withdraw',16)" type="primary"
									plain>余额提现</el-button>
							</div>

						</div>
						<div class="balanceList fl">
							<div class="balanceItem">
								<span class="balanceTitle">账户余额(元)</span>
								<span class="balanceNumber">{{users.earningsBalance}}</span>
								<div>
								<!-- 	<span class="balanceDetail" @click="type = 'recharge'">充值</span>
									<span class="balanceDetail" style="margin: 0 10px;">|</span> -->
									<span class="balanceDetail" @click="$emit('withdraw',16)">提现</span>
								</div>
							</div>
							<div class="balanceItem" @click="jump('1')">
								<span class="balanceTitle">保证金余额(元)</span>
								<span class="balanceNumber">{{users.deposit}}</span>
								<span class="balanceDetail" >查看明细</span>
							</div>
							<div class="balanceItem" @click="jump('2')">
								<span class="balanceTitle">赔付金余额(元)</span>
								<span class="balanceNumber">{{users.paidMoney}}</span>
								<span class="balanceDetail" >查看明细</span>
							</div>
						</div>
					</div>
				</div>


				<div class="mineOrder">
					<div class="orderTop fl">
						<div class="fl">
							<span class="orderTitle">我是买家</span>
							<div class="fl" style="margin-left: 24px;">
								<span class="credit">买家信用</span>
								<img v-for="item in 4" :src="require('@/static/image/index/masonry.png')"
									style="width: 16px;height: 16px;" />
							</div>
						</div>
						<div class="fl" style="padding-right: 24px;">
							<div class="fl" style="margin-left: 24px;">
								<span class="credit">成交笔数:</span>
								<span class="credit" style="color: #1D223C;">{{users.dealNum}}</span>
							</div>
							<div class="fl" style="margin-left: 24px;">
								<span class="credit">成交率:</span>
								<span class="credit" style="color: #1D223C;">{{users.dealRate}}%</span>
							</div>
						</div>
					</div>
					<div class="orderList">
						<div class="orderItem">
							<img :src="require('@/static/image/mine/mine1.png')" style="width:50px;height: 50px;" />
							<div class="orderText">
								<div>
									<span
										style="color: #FF5C00;font-size: 24px;font-weight: bold;">{{users.notPayNum}}</span>
									<span>单</span>
								</div>
								<div>待付款</div>
							</div>
						</div>
						<div class="orderItem">
							<img :src="require('@/static/image/mine/mine1.png')" style="width: 50px;height: 50px;" />
							<div class="orderText">
								<div>
									<span
										style="color: #1D223C;font-size: 20px;font-weight: bold;">{{users.payNum}}</span>
									<span>单</span>
								</div>
								<div>待发货</div>
							</div>
						</div>
						<div class="orderItem">
							<img :src="require('@/static/image/mine/mine1.png')" style="width: 50px;height: 50px;" />
							<div class="orderText">
								<div>
									<span
										style="color: #1D223C;font-size: 20px;font-weight: bold;">{{users.notReceiveNum}}</span>
									<span>单</span>
								</div>
								<div>待收货</div>
							</div>
						</div>
						<div class="orderItem">
							<img :src="require('@/static/image/mine/mine1.png')" style="width: 50px;height: 50px;" />
							<div class="orderText">
								<div>
									<span
										style="color: #1D223C;font-size: 20px;font-weight: bold;">{{users.allNum}}</span>
									<span>单</span>
								</div>
								<div>全部订单</div>
							</div>
						</div>
					</div>


				</div>


				<div class="mineOrder">
					<div class="orderTop fl">
						<div class="fl">
							<span class="orderTitle">我是卖家</span>
							<div class="fl" style="margin-left: 24px;">
								<span class="credit">卖家信用：</span>
								<img v-for="item in 4" :src="require('@/static/image/index/masonry.png')"
									style="width: 16px;height: 16px;" />
							</div>
						</div>
						<div class="fl" style="padding-right: 24px;">
							<div class="fl" style="margin-left: 24px;">
								<span class="credit">成交笔数:</span>
								<span class="credit" style="color: #1D223C;">{{users.dealNumSale}}</span>
							</div>
							<div class="fl" style="margin-left: 24px;">
								<span class="credit">成交率:</span>
								<span class="credit" style="color: #1D223C;">{{users.dealRateSale}}%</span>
							</div>
						</div>
					</div>
					<div class="orderList">
						<div class="orderItem">
							<img :src="require('@/static/image/mine/mine5.png')" style="width: 50px;height: 50px;" />
							<div class="orderText">
								<div>
									<span
										style="color: #0085FF;font-size: 20px;font-weight: bold;">{{users.payNumSale}}</span>
									<span>单</span>
								</div>
								<div>待发货</div>
							</div>
						</div>
						<div class="orderItem">
							<img :src="require('@/static/image/mine/mine6.png')" style="width: 50px;height: 50px;" />
							<div class="orderText">
								<div>
									<span
										style="color: #1D223C;font-size: 20px;font-weight: bold;">{{users.notReceiveNumSale}}</span>
									<span>单</span>
								</div>
								<div>已发货</div>
							</div>
						</div>
						<div class="orderItem">
							<img :src="require('@/static/image/mine/mine7.png')" style="width: 50px;height: 50px;" />
							<div class="orderText">
								<div>
									<span
										style="color: #1D223C;font-size: 20px;font-weight: bold;">{{users.reductionNumSale}}</span>
									<span>单</span>
								</div>
								<div>求降价</div>
							</div>
						</div>
						<div class="orderItem">
							<img :src="require('@/static/image/mine/mine8.png')" style="width: 50px;height: 50px;" />
							<div class="orderText">
								<div>
									<span
										style="color: #1D223C;font-size: 20px;font-weight: bold;">{{users.allNumSale}}</span>
									<span>单</span>
								</div>
								<div>全部订单</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			
			<div class="infoList">
				<div class="infoItem fl">
					<div class="fl">
						<div style="flex-direction: column;display: flex;width: 500px;">
							<span class="title">个人信息</span>
							<span class="detaile">去完善个人信息</span>
						</div>
						<!-- <div class="itemType">
							
							<i  style="color: #19D972;margin-right: 8px;" class="el-icon-success"></i>
							<span >已完善</span>
						</div> -->
					</div>
					<div class="fl">
						<el-button  @click="menuSelect(27)">查看个人信息</el-button>
					</div>
				</div>
				<div class="infoItem fl">
					<div class="fl">
						<div style="flex-direction: column;display: flex;width: 500px;">
							<span class="title">实名认证</span>
							<span class="detaile">通过实名认证后，提升账户安全级别，同时获得更多的会员权利</span>
						</div>
						<div class="itemType">
			
							<i v-if="isReal" style="color: #19D972;margin-right: 8px;" class="el-icon-success"></i>
							<i v-else style="color: #FF800E;margin-right: 8px;" class="el-icon-warning"></i>
							<span v-if="isReal">已设置</span>
							<span v-else>未设置</span>
						</div>
					</div>
					<div class="fl">
						<div class="itemType" style="margin-right: 50px;" v-if="isReal">
							<span>{{nameEncryption(realInfo.realName)}}</span>
							<span>{{idCardEncryption(realInfo.idCard)}}</span>
						</div>
						<el-button @click="menuSelect(28)">查看</el-button>
					</div>
				</div>
				<div class="infoItem fl">
					<div class="fl">
						<div style="flex-direction: column;display: flex;width: 500px;">
							<span class="title">登录密码</span>
							<span class="detaile">互联网账户存在被盗风险，建议您定期更换密码以保护账户安全</span>
						</div>
						<div class="itemType">
							<!-- <i style="color: #FF800E;margin-right: 8px;" class="el-icon-warning"></i> -->
							<i style="color: #19D972;margin-right: 8px;" class="el-icon-success"></i>
							<span>已设置</span>
						</div>
			
					</div>
					<div>
						<el-button @click="menuSelect(29)">修改</el-button>
					</div>
				</div>
				<div class="infoItem fl">
					<div class="fl">
						<div style="flex-direction: column;display: flex;width: 500px;">
							<span class="title">支付密码</span>
							<span class="detaile">保障账户资金安全，建议您定期更换新的支付密码，提高安全险</span>
						</div>
						<div class="itemType">
			
							<i v-if="payPassword != ''" style="color: #19D972;margin-right: 8px;"
								class="el-icon-success"></i>
							<i v-else style="color: #FF800E;margin-right: 8px;" class="el-icon-warning"></i>
							<span v-if="payPassword != ''">已设置</span>
							<span v-else>未设置</span>
						</div>
					</div>
					<div>
						<el-button @click="menuSelect(30)">修改</el-button>
					</div>
				</div>
			
				<div class="infoItem fl">
					<div class="fl">
						<div style="flex-direction: column;display: flex;width: 500px;">
							<span class="title">手机绑定</span>
							<span class="detaile">可以选择手机验证码作为身份验证方式，接受交易通知，提高账户安全险</span>
						</div>
						<div class="itemType">
							<!-- <i style="color: #FF800E;margin-right: 8px;" class="el-icon-warning"></i> -->
							<i style="color: #19D972;margin-right: 8px;" class="el-icon-success"></i>
							<span>已绑定</span>
						</div>
					</div>
					<div class="fl">
						<div class="itemType" style="margin-right: 50px;" v-if="isReal">
							<span>{{phoneEncryption(bindPhone)}}</span>
			
						</div>
						<el-button @click="menuSelect(31)">修改</el-button>
					</div>
				</div>
				<div class="infoItem fl">
					<div class="fl">
						<div style="flex-direction: column;display: flex;width: 500px;">
							<span class="title">QQ/微信绑定</span>
							<span class="detaile">绑定QQ/微信用于找回密码验证身份，接受订阅消息等服务</span>
						</div>
						<div class="itemType">
							<i v-if="openId != ''" style="color: #19D972;margin-right: 8px;" class="el-icon-success"></i>
							<i v-else style="color: #FF800E;margin-right: 8px;" class="el-icon-warning"></i>
							<span v-if="openId != ''">已绑定</span>
							<span v-else>未绑定</span>
						</div>
					</div>
					<div>
						<el-button @click="menuSelect(32)">修改</el-button>
					</div>
				</div>
			</div>
		</div>


		<div class="popUp" v-if="realShow">
			<div style="width: 100%;justify-content: center;position: relative;" class="fl">
				<img style="width: 522px;height:166px;position: absolute;top: 100px;"
					:src="require('@/static/image/index/payImage.png')" />
				<div class="popCount">
					<img class="close" @click="closePay" :src="require('@/static/image/index/close.png')" />
					<!-- <div class="payment-password-input">
						<input ref="inputList" :ref="`input${index}`" v-for="(item, index) in passwordLength"
							:key="index" type="password" maxlength="1" v-model="password[index]"
							@input="handleInput($event, index)" @keydown="onKeydownBackSpace($event,index)" />
					</div> -->
					<img style="width: 240px;height: 240px;margin-top: 40px;" :src="payImage" alt="" />
					<div class="popText">使用 支付宝 扫码支付<br />支付即视为你同意 <span style="color: #0085FF;">相关协议</span> 条例</div>
					<div class="payOrder">
						<span>实付金额： </span>
						<div>
							<span style="color: #FF5C00;font-weight: bold;font-size: 36px;">{{payNumber}}</span>
							<span style="color: #FF5C00;">元</span>
						</div>
					</div>
				</div>
			</div>
		</div>

		
	</div>

</template>

<script>
	import {
		userCenter,
		orderDepositPay,
		orderDepositQueryById,
		realInfo,
		fush
	} from '@/api/index.js'
	export default {
		data() {
			return {
				realShow: false,
				users: {},
				type: '',
				timeInterval: null,
				payNumber: '',
				payValue: 5,
				payImage: '',
				payType: [{
						text: '支付宝',
						value: 5
					},
					// {
					// 	text: '微信',
					// 	value: 2
					// },
					// {
					// 	text: '银行卡',
					// 	value: 3
					// },
					// {
					// 	text: '余额',
					// 	value: 4
					// },
				],
				isReal: false,
				realInfo: {},
				payPassword: '',
				openId: '',
				bindPhone: ''
			}
		},
		created() {
			userCenter().then(res => {
				if (res.code == 200) {
					this.users = res.result
				}
			})

			// 判断是否实名
			realInfo().then(res => {
				if (res.result == null || res.result.idCardAudit == 0) {
					this.isReal = false
				} else {
					this.realInfo = res.result
					this.isReal = true
				}
			})
			fush().then(res => {
				if (res.result.payPassword != undefined && res.result.payPassword != null && res.result
					.payPassword != '') {
					this.payPassword = res.result.payPassword
				}
				if (res.result.openId != undefined && res.result.openId != null && res.result.openId != '') {
					this.openId = res.result.openId
				}
				this.bindPhone = res.result.phone
			})
		},
		beforeDestroy() {
			clearInterval(this.timeInterval)
			this.timeInterval = null
		},
		methods: {
			onCopy() {
				this.message('复制成功', 'success')
			},
			jump(type){
				localStorage.setItem('money', type);
				this.$router.push('/mineIndex/15')
			},
			menuSelect(e) {
				this.$emit('withdraw', e)
			},
			configWithdraw() {
				if (this.payNumber == '' || this.payNumber == 0) {
					this.message('请输入充值金额', 'error')
					return
				}
				orderDepositPay({
					depositType: 9,
					money: this.payNumber,
					payType: this.payValue
				}).then(res => {
					if (res.code == 200) {
						this.payImage = res.result.qrcode
						this.getPaySuccess(res.result.id)
						this.realShow = true
					}
				})

			},
			getPaySuccess(id) {
				this.timeInterval = setInterval(() => {
					orderDepositQueryById({
						id: id
					}).then(res => {
						if (res.result.status == 1) {
							clearInterval(this.timeInterval)
							this.timeInterval = null
							this.realShow = false
							this.type = ''
							this.getInfo()
						}
					})
				}, 3000)
			},
			closePay() {
				clearInterval(this.timeInterval)
				this.timeInterval = null
				this.realShow = false
			},
			getInfo() {
				userCenter().then(res => {
					if (res.code == 200) {
						this.users = res.result
					}
				})
			},
			baozheng(){
				localStorage.setItem('money', type);
			},
			peifu(){
				
			}


		}
	}
</script>

<style scoped lang="scss">
	.mine {
		padding: 42px;
	}

	.mineInfo {
		background-image: url("../../../static/image/mine/mineBg.png");
		width: 240px;
		height: 212px;
		background-size: 100% 100%;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;

		.infoImage {
			width: 60px;
			height: 60px;
			border-radius: 50%;
			border: 6px solid #86D2FF;
		}

		.infoName {
			width: 80%;
			font-weight: 600;
			font-size: 16px;
			color: #FFFFFF;
			margin-top: 10px;
			text-align: center;
		}

		.infoCode {
			font-weight: 400;
			font-size: 12px;
			color: #FFFFFF;
			display: flex;
			align-items: center;
			margin-top: 4px;
		}

		.auths {
			display: flex;
			margin-top: 14px;
		}

		.infoAuth {
			width: 96px;
			height: 30px;
			border-radius: 4px 4px 4px 4px;
			border: 1px solid #FFFFFF;

			justify-content: center;

			font-weight: 400;
			font-size: 12px;
			color: #FFFFFF;
			margin-right: 5px;

		}
	}

	.wallet {
		width: 700px;
		height: 188px;
		background: linear-gradient(180deg, #FFFFFF 0%, #F9F9F9 100%);
		box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.1);
		border-radius: 0px 20px 20px 0px;

		.walletTop {
			justify-content: space-between;
			height: 55px;
			padding: 0 28px;
			border-bottom: 1px solid #ECECEC;

			.withdraw {
				width: 90px;
				height: 39px;

				font-weight: 600;
				font-size: 12px;


			}

			.walletText {
				font-weight: 600;
				font-size: 16px;
				color: #1D223C;
			}
		}
	}

	.balanceList {
		.balanceItem {
			display: flex;
			flex-direction: column;
			justify-content: center;
			height: 123px;
			width: 304px;
			padding-left: 28px;

			.balanceTitle {
				font-weight: 400;
				font-size: 16px;
				color: #A5A7B1;
			}

			.balanceNumber {
				font-weight: bold;
				font-size: 30px;
				color: #1D223C;
			}

			.balanceNumber:hover {
				color: #FF5C00;
			}

			.balanceDetail {
				font-weight: 400;
				font-size: 14px;
				color: #A5A7B1;
				cursor: pointer;
			}

			.balanceDetail:hover {
				color: #0085FF;
			}
		}
	}

	.mineOrder {
		border: 1px solid #DEDEDE;
		width: 940px;
		border-radius: 8px;
		margin-bottom: 20px;
	}

	.orderTop {
		width: 940px;
		height: 64px;
		background: #FCFCFC;
		border-radius: 8px 8px 0px 0px;
		border-bottom: 1px solid #DEDEDE;
		justify-content: space-between;

		.orderTitle {
			font-weight: 600;
			font-size: 16px;
			color: #000000;
			padding-left: 24px;
			border-left: 5px solid #FF9B20;
		}

		.credit {
			font-weight: 400;
			font-size: 14px;
			color: #A5A7B1;
			margin-right: 5px;
		}
	}

	.orderList {
		display: flex;

		.orderItem {
			width: 208px;
			height: 140px;
			display: flex;
			align-items: center;
			padding-left: 24px;

			.orderText {
				margin-left: 16px;
				font-weight: 400;
				font-size: 14px;
				color: #A5A7B1;

			}
		}
	}


	.roleTitle {
		font-weight: 600;
		font-size: 20px;
		color: #1D223C;
		width: 1286px;
		border-bottom: 1px solid #ECECEC;
		height: 87px;
		line-height: 87px;
		padding-left: 42px;
	}

	.balance {
		font-weight: 400;
		font-size: 16px;
		color: #A5A7B1;
	}

	.noAccount {
		width: 1232px;
		// height: 255px;
		padding: 42px 0;
		background: #F8F8F8;
		border-radius: 12px 12px 12px 12px;
		justify-content: space-between;
		margin-left: 42px;
		margin-top: 68px;

		.accountLeft {
			display: flex;
			flex-direction: column;
			margin-left: 40px;
		}

		.account1 {
			font-weight: 600;
			font-size: 26px;
			color: #1D223C;
		}

		.account2 {
			font-weight: 400;
			font-size: 20px;
			color: #A5A7B1;
			margin-top: 16px;

		}



	}

	.toAdd {
		width: 208px;
		height: 52px;
		border-radius: 8px 8px 8px 8px;
		border: 2px solid #0085FF;
		font-weight: 600;
		font-size: 18px;
		color: #0085FF;
		display: flex;
		align-items: center;
		justify-content: center;
		margin-top: 36px;

	}

	.toAdd1 {
		width: 210px;
		height: 54px;
		border-radius: 8px 8px 8px 8px;
		background-color: #0085FF;
		font-weight: 600;
		font-size: 18px;
		color: #fff;
		display: flex;
		align-items: center;
		justify-content: center;
		margin-top: 36px;
		margin-left: 42px;
		margin-right: 12px;
	}

	.remind {
		margin-left: 42px;
		margin-top: 50px;

		.remind1 {
			font-weight: 600;
			font-size: 22px;
			color: #000000;
			margin-bottom: 32px;

		}

		.remind2 {
			font-weight: 400;
			font-size: 18px;
			color: #777A8A;
			margin-bottom: 28px;
		}
	}

	.formItem {
		margin-bottom: 30px;

		.submitLable {
			width: 200px;
			text-align: right;

			.lableText {
				font-weight: 400;
				font-size: 16px;
				color: #A5A7B1;
				line-height: 30px;
				position: relative;
			}

		}

		.submitValue {
			width: 336px;
			margin: 0 20px;
			position: relative;
		}

		.lableNumber {
			margin: 0 20px;


			font-weight: bold;
			font-size: 24px;
			color: #FF5C00;


		}

		.submitRemark {
			width: 40%;
			color: #A5A7B1;
		}
	}

	.submitForm {
		margin-top: 50px;
	}

	.lablePhone {
		font-weight: 400;
		font-size: 18px;
		color: #1D223C;
	}

	.getCode {
		position: absolute;
		right: 5px;
		line-height: 40px;
		font-size: 14px;
		color: #0085FF;
	}

	.getCode::after {
		content: '';
		position: absolute;
		width: 2px;
		height: 20px;
		left: -10px;
		top: 10px;
		background-color: #ECECEC;
	}

	.typeList {
		display: flex;
		justify-content: space-between;
	}

	.withType {
		width: 160px;
		height: 46px;
		background: #FFFFFF;
		border-radius: 4px 4px 4px 4px;
		border: 1px solid #DEDEDE;
		font-weight: 400;
		font-size: 18px;
		color: #1D223C;
		line-height: 46px;
		text-align: center;
	}

	.action {
		background: rgba(0, 133, 255, 0.1);
		border: 1px solid #0085FF;
		color: #0085FF;
		position: relative;
	}

	.actionImage {
		position: absolute;
		bottom: 0;
		right: 0;
	}

	.delete {
		width: 88px;
		height: 37px;
		border-radius: 6px 6px 6px 6px;
		border: 1px solid #FF3C3C;
		display: flex;
		align-items: center;
		justify-content: center;

		font-weight: 400;
		font-size: 18px;
		margin-left: 12px;
		color: #FF3C3C;

	}

	.dialogCenter {
		border-top: 1px solid #FAFAFA;
		border-bottom: 1px solid #FAFAFA;
		flex-direction: column;
		padding: 30px 0;

		.deleteTitle {
			font-weight: 600;
			font-size: 20px;
			color: #1D223C;
			text-align: center;
			margin: 20px;
		}

		.deleteDetaile {
			font-weight: 400;
			font-size: 16px;
			color: #777A8A;
			text-align: center;
			line-height: 30px;
		}
	}

	.payment-password-input {
		margin-top: 30px;
	}

	.payment-password-input input {
		width: 40px;
		height: 40px;
		margin: 0;
		padding: 10px;
		border: 1px solid #ccc;
		text-align: center;
	}

	.payment-password-input input:focus {
		border-color: #007bff;
	}

	.payOrder {
		width: 394px;
		height: 100px;
		background: #F6F8FA;
		border-radius: 12px 12px 12px 12px;
		margin-top: 40px;
		padding: 0 24px;
		color: #A5A7B1;
		display: flex;
		align-items: center;
		justify-content: space-between;
	}

	.close {
		position: absolute;
		top: -100px;
		right: 10px;
		z-index: 999;
	}


	.popUp {
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		z-index: 999;
		background-color: rgba(0, 0, 0, .3);
		overflow: hidden;
	}

	.popCount {
		z-index: 9999;
		position: relative;
		margin-top: 220px;
		display: flex;
		flex-direction: column;
		width: 522px;
		border-radius: 24px;
		align-items: center;
		background-color: #fff;
		padding-bottom: 30px;

		.popText {
			width: 264px;
			font-weight: 400;
			font-size: 16px;
			color: #777A8A;
			text-align: center;
			margin-top: 20px;
		}

		.payOrder {
			width: 394px;
			height: 100px;
			background: #F6F8FA;
			border-radius: 12px 12px 12px 12px;
			margin-top: 40px;
			padding: 0 24px;
			color: #A5A7B1;
			display: flex;
			align-items: center;
			justify-content: space-between;
		}

	}

	.close {
		position: absolute;
		top: -100px;
		right: 10px;
		z-index: 999;
	}

	.realSubmit {
		width: 296px;
		height: 66px;
		background: linear-gradient(90deg, #03B5FF 0%, #0085FF 100%);
		border-radius: 8px 8px 8px 8px;
		font-weight: 500;
		font-size: 24px;
		color: #FFFFFF;
		margin-top: 50px;
	}

	.payment-password-input {
		margin-top: 30px;
	}

	.payment-password-input input {
		width: 40px;
		height: 40px;
		margin: 0;
		padding: 10px;
		border: 1px solid #ccc;
		text-align: center;
	}

	.payment-password-input input:focus {
		border-color: #007bff;
	}

	.infoList {
		border: 1px solid #EDEDED;
		margin: -30px 40px 0;

		.infoItem {
			width: calc(100% - 30px);
			justify-content: space-between;
			padding: 15px;
			border-bottom: 1px solid #EDEDED;

			.title {
				font-size: 16px;
				color: #000;
				margin-bottom: 15px;
				line-height: 16px;
			}

			.detaile {
				color: #888888;
				font-size: 14px;
			}
		}

		.itemType {
			color: #666666;

			font-size: 14px;

		}
	}
</style>