<template>
	<div class="wrapper" style="background-color: #F8F8F8;">
		<head-bar></head-bar>
		<div class="lableTop" style="padding-top: 30px;">
			<el-row>
				
				<el-col :span="24">
					<div class="main">
						<div class="title">
							<div class="titleH1">
								<img :src="require('@/static/image/index/left.png')" />
								<span style="margin: 0 20px;">营业资质</span>
								<img :src="require('@/static/image/index/right.png')" />
							</div>
							<div class="titleH2">BUSINESS LICENSE</div>
						</div>
						<div style="display:flex;justify-content: center;">
							<el-image style="width:600px;" :preview-src-list="['http://www.cc007.com/media/image1.jpg']"
								src="http://www.cc007.com/media/image1.jpg"></el-image>
						</div>
					</div>
					<div class="main">
						<div class="title">
							<div class="titleH1">
								<img :src="require('@/static/image/index/left.png')" />
								<span style="margin: 0 20px;">关于我们</span>
								<img :src="require('@/static/image/index/right.png')" />
							</div>
							<div class="titleH2">ABOUT US</div>
						</div>
						<div class="titleH3">
							关于cc游戏服务网（cc007.com）
							郑州市启生网络技术有限公司旗下网络游戏服务网（www.cc007.com）成立于2023年，是一家专业为用户提供虚拟数字产品交易服务的电子商务平台，是目前国内最受欢迎、最具影响力的数字产品交易网站之一。公司团队从2016年起就致力于游戏道具等周边交易业务，经过多年的发展，现已成为一家集技术研发、游戏增值服务、电子商务交易服务于一体的跨区域化互联网企业。
							服务宗旨
							服务，是cc007的核心价值。cc007坚持
							“员工满意度100%，客户满意度200%”的信念和追求，在“用户至上”思想的指导下，cc007凭借先进的技术和严谨的管理，以快捷的交易速度、便利的交易流程、人性化的交易服务和不断优化的交易安全机制，提供基于数字产品的各种交易，其中包括：帐号交易、网游装备、网游代练、手游交易等业务，为用户提供7×24小时不间断服务。
						</div>

						<div class="aboutList">
							<div class="aboutItem">
								<div class="fl">
									<span class="aboutNum">15+</span>
									<span class="aboutType">游戏类型</span>
								</div>
								<div class="aboutDetaile">快捷的交易速度、便利的交易流程</div>
							</div>
							<div class="aboutItem">
								<div class="fl">
									<span class="aboutNum">7x24</span>
									<span class="aboutType">不间断服务</span>
								</div>
								<div class="aboutDetaile">为用户提供7×24小时不间断服务</div>
							</div>
							<div class="aboutItem">
								<div class="fl">
									<span class="aboutNum">200%</span>
									<span class="aboutType">满意度</span>
								</div>
								<div class="aboutDetaile">客户满意度200%，员工满意度100%</div>
							</div>
						</div>
					</div>


					<!-- <div class="main">
						<div class="title">
							<div class="titleH1">
								<img :src="require('@/static/image/index/left.png')" />
								<span style="margin: 0 20px;">投诉建议</span>
								<img :src="require('@/static/image/index/right.png')" />
							</div>
							<div class="titleH2">SUGGESTIONS</div>
						</div>

						<div class="aboutForm">
							<div class="fl" style="justify-content: space-between;">
								<el-input v-prevent-space style="width: 420px;" v-model="input" placeholder="您的称呼"></el-input>
								<el-input v-prevent-space style="width: 420px;" v-model="input" placeholder="您的联系方式"></el-input>
							</div>
							<el-input v-prevent-space style="width: 872px;margin-top: 24px;" type="textarea" :rows="10" v-model="input"
								placeholder="您的称呼"></el-input>

						</div>
					</div> -->

					<div class="main">
						<div class="title">
							<div class="titleH1">
								<img :src="require('@/static/image/index/left.png')" />
								<span style="margin: 0 20px;">免责声明</span>
								<img :src="require('@/static/image/index/right.png')" />
							</div>
							<div class="titleH2">DISCLAIMER</div>
						</div>

						<div class="titleH3" style="padding-bottom: 60px;">
							用户在接受本平台服务前，请务必仔细阅读，且特别注意加粗字体部分，并同意本声明。</br>
							一、用户直接或通过各类方式间接使用本平台服务和数据的行为，都将被视为已无条件接受本声明所涉全部内容；若用户对本声明的任何条款有异议，请停止使用本平台所提供的全部服务。</br>
							二、用户以各种方式使用本平台服务和数据的过程中，不得以任何方式直接或间接从事违反中国法律法规及社会公德的行为，且应当恪守下述承诺：
							</br> <p class="th1">1.在本平台的所有行为应符合中国法律法规及社会公德；</p><p class="th1">2.不得干扰、损害和侵犯本平台的各种合法权益；</p> <p class="th1">3.遵守本平台以及与之相关的网络服务协议、指导原则、管理细则等。</p><p class="th1">本平台有权对违反上述承诺的行为予以处理，并保留移交司法机关的权利。 </p>
							三、本平台仅为游戏娱乐、交易平台，不参与用户之间的游戏娱乐活动，不提供任何形式的保证。
							<p class="th1">1.本网站在国家相关法律法规规定的范围内，只按现有状况提供虚拟物品在线交易第三方
							网络平台服务，本网站及其所有者非交易一方，也非交易任何一方之代理人或代表；同时，本网站及其所有者也未授权任何人代表或代理本网站及其所有者从事任何网络交易行为或做出任何承诺、保证或其他类似行为。</p>
							<p class="th1">2.
							鉴于互联网及网络交易的特殊性，本网站无法鉴别和判断相关交易各主体之民事权利和行为能力、资质、信用等状况，也无法鉴别和判断虚拟交易或正在交易或已交易之虚拟物品来源、权属、真伪、性能、规格、质量、数量等权利属性、自然属性及其他各种状况。因此，交易各方在交易前应加以仔细辨明，并慎重考虑和评估交易可能产生的各项风险，如出现任何情况与本网站及其所有者无关，本网站及其所有者不承担任何责任及后果。</p>
							<p class="th1">3. 本网站不希望出现任何因虚拟物品交易而在用户之间及用户与游戏开发运营商之间产生纠纷，但并不保证
							不发生该类纠纷。对于因前述各类情形而产生的任何纠纷，将由交易各方依据中华人民共和国现行的有关法律通过适当的方式直接加以解决，本网站及其所有者不参与其中；对于因此类交易而产生的各类纠纷之任何责任和后果，由交易各方承担，本网站及其所有者不承担任何责任及后果。</p>
							<p class="th1">4.
							本网站禁止出现任何人利用本网站或因使用本网站而侵犯他人合法权益的行为，但并不保证不会发生此类行为或类似行为。本网站将依据中华人民共和国现行的有关法律采取必要的措施防止发生前述各类行为或降低发生这类行为的可能性或者降低由此造成的损失及其后果。对于因前述各类情形而产生的任何纠纷，将由权利受到侵害之人和侵权方依据中华人民共和国现行的有关法律通过适当的方式直接解决，本网站及其所有者不参与其中；对于因</br>
							此类行为产生的各类纠纷之任何责任和后果，由相关责任方承担，本网站及其所有者不承担任何责任及后果。 </p>
							<p class="th1">5.
							任何非本网站责任而产生的任何其他纠纷，概由纠纷各方依据中国相关法律以适当的方式直接解决，本网站不参与其中；对于因该类行为产生的各类纠纷之任何责任和后果，由相关各方承担，本网站及其所有者不承担任何责任及后果。</br>
							四、因网络状况、通讯线路、第三方网站或管理部门的要求等因素而导致您不能正常使用，本平台不承担任何法律责任。</p>
							五、用户不得利用本平台从事洗钱、赌博等各种违法犯罪活动。一经发现，本平台有权予以处理（包括但不限于注销账号），并保留移交司法机关的权利。</br>
							六、个人或单位如认为本平台存在侵犯自身合法权益的内容，请及时联系，以便本平台迅速做出处理。 七、对免责声明的解释、修改及更新权均属于本平台所有。</br>
						</div>
					</div>
					
				</el-col>
				
			</el-row>
		</div>


		<!-- <div style="background-color: #fff;">
			<el-row>
				<el-col :span="2">
					<div>&emsp;</div>
				</el-col>
				<el-col :span="20">
					<div class="title">
						<div class="titleH1">
							<img :src="require('@/static/image/index/left.png')" />
							<span style="margin: 0 20px;">发展历程</span>
							<img :src="require('@/static/image/index/right.png')" />
						</div>
						<div class="titleH2">DEVELOPMENT PATH</div>
					</div>
					<div class="steps fl" style="height: 400px;justify-content: space-between;">
						<img :src="require('@/static/image/mine/left.png')" style="width: 44px;height: 44px;" />
						<div style="width: 80%;margin-top: 140px;">
							<el-steps :active="0" style="position: relative;">
								<el-step title="步骤 1" v-for="(item,index) in stepList">
									<div slot="icon">
										<img v-if="stepIndex == index"
											:src="require('@/static/image/mine/stepAction.png')"
											style="width: 32px;height: 32px;" />
										<img v-else :src="require('@/static/image/mine/step.png')"
											style="width: 32px;height: 32px;" />
									</div>
									<div slot="title">
										<div v-if="index % 2 == 1" class="stepTitle">{{item.title}}</div>
										<div class="stepTitle1" v-else>{{item.title}}</div>
									</div>
									<div slot="description">
										<span class="stepDesc">{{item.description}}</span>
									</div>
								</el-step>

							</el-steps>
						</div>

						<img :src="require('@/static/image/mine/right.png')" style="width: 44px;height: 44px;" />
					</div>
				</el-col>
				<el-col :span="2">
					<div>&emsp;</div>
				</el-col>
			</el-row>

		</div> -->
		<footer-bars></footer-bars>
	</div>
</template>

<script>
	import * as echarts from 'echarts';
	import "../../node_modules/echarts/map/js/china.js"; // 引入中国地图数据
	import headBar from '@/components/HeadBar.vue';
	import footerBars from '@/components/footerBars.vue';
	export default {

		data() {
			// 这里存放数据
			return {
				stepIndex: 0,
				stepList: [{
						title: '2002年',
						description: '2020年技术总部于上海市宝山区复旦软件园成立'
					},
					{
						title: '2006年',
						description: '2020年技术总部于上海市宝山区复旦软件园成立'
					},
					{
						title: '2012年',
						description: '2020年技术总部于上海市宝山区复旦软件园成立'
					},
					{
						title: '2021年',
						description: '2020年技术总部于上海市宝山区复旦软件园成立'
					},
					{
						title: '2022年',
						description: '2020年技术总部于上海市宝山区复旦软件园成立'
					},
					{
						title: '2023年',
						description: '2020年技术总部于上海市宝山区复旦软件园成立'
					},
					{
						title: '2024年',
						description: '2020年技术总部于上海市宝山区复旦软件园成立'
					}
				],
				funList: [{
						image: require('@/static/image/mine/about1.png'),
						text: '关于我们'
					},
					{
						image: require('@/static/image/mine/about2.png'),
						text: '联系我们'
					},
					{
						image: require('@/static/image/mine/about3.png'),
						text: '投诉建议'
					},
					{
						image: require('@/static/image/mine/about4.png'),
						text: '营业执照'
					},
					{
						image: require('@/static/image/mine/about5.png'),
						text: '免责声明'
					}
				]
			};
		},

		mounted() {
			this.init();
		},
		components: {
			headBar,
			footerBars
		},
		// 方法集合
		methods: {
			init() {
				let mapData = [{
					name: '郑州市',
					value: [112.42, 34.16]
				}, {
					name: '上海总部',
					value: [120.52, 30.40]
				}]
				let myChart = echarts.init(this.$refs.echarts);
				let option = {
					tooltip: {
						// 鼠标移到图里面的浮动提示框
						formatter(params) {
							let htmlStr = `
	                          <div style='font-size:18px;'> ${params.name}</div>
	                        `;
							return htmlStr;
						}
					},
					// geo配置详解： https://echarts.baidu.com/option.html#geo
					geo: {
						map: 'china',
						show: true,
						roam: true,
						top: 0,
						label: {
							emphasis: {
								show: false
							}
						},
						// 地图的背景色
						itemStyle: {
							normal: {
								areaColor: '#fff',
								borderColor: '#606266',
								shadowColor: 'rgba(255,255,255,0)',
								shadowBlur: 20,

							}
						}
					},
					series: [
						// 气泡
						{
							type: 'effectScatter',
							coordinateSystem: 'geo',
							//要有对应的经纬度才显示，先经度再维度
							data: mapData.map(function(item) {
								return {
									name: item.name,
									value: item.value,
									// 自定义标记样式
									symbol: 'image://https://houtai27.hnprkj.com/static/api/images/location.png',
									symbolSize: 20,
								};
							}),
							showEffectOn: 'render',
							rippleEffect: {
								scale: 1, // 波纹的最大缩放比例
								brushType: 'stroke'
							},
							hoverAnimation: true,
							label: {
								normal: {
									show: true,
									formatter: '{b}',
									position: 'bottom',
									fontWeight: 500,
									fontSize: 14
								}
							},
							itemStyle: {
								normal: {
									color: '#0085FF',
									// shadowBlur: 10,
									// shadowColor: '#333'
								}
							},
							emphasis: {
								itemStyle: {
									color: '#f4e925' // 高亮颜色
								}
							},
							// zlevel: 1
						}
					]
				};
				myChart.setOption(option);
				window.addEventListener('resize', function() {
					myChart.resize();
				});
			}
		}
	};
</script>

<style scoped lang="scss">
	.main {
		width: 1200px;
		min-height: 140px;
		background: #FFFFFF;
		border-radius: 20px 20px 20px 20px;
		margin:0 auto;
		margin-bottom: 60px;
		

		.functionItem {
			display: flex;
			flex-direction: column;
			margin-top: 20px;

			.itemText {
				font-weight: 500;
				font-size: 18px;
				margin-top: 12px;
			}
		}
	}

	.title {
		text-align: center;

		margin-bottom: 46px;
		padding-top: 60px;

		.titleH1 {
			display: flex;
			align-items: center;
			justify-content: center;
			font-weight: bold;
			font-size: 24px;
			color: #1D223C;
			line-height: 42px;

		}

		.titleH2 {
			font-weight: 400;
			font-size: 14px;
			color: #777A8A;
			line-height: 21px;
			margin-top: 8px;
		}

	}

	.titleH3 {
		margin: 0 80px;
		font-weight: 400;
		font-size: 14px;
		color: #777A8A;
		line-height: 30px;

	}

	.aboutList {
		display: flex;
		justify-content: space-around;

		.aboutItem {
			margin: 36px 0 60px;

			.aboutNum {

				font-weight: bold;
				font-size: 30px;
				color: #0085FF;
				line-height: 59px;

			}

			.aboutType {
				font-weight: 600;
				font-size: 14px;
				color: #1D223C;
				margin-left: 14px;
			}

			.aboutDetaile {
				font-weight: 400;
				font-size: 14px;
				color: #6B6F8C;
				margin-top: 12px;
			}
		}
	}

	.aboutForm {
		margin: 0 auto;
		width: 872px;
		padding-bottom: 40px;

		::v-deep .el-input__inner {
			height: 65px;
		}
	}

	.steps {
		.stepTitle {
			margin-top: -150px;

			font-weight: bold;
			font-size: 24px;
			color: #30304D;
			padding-bottom: 30px;

		}

		.stepTitle1 {
			font-weight: bold;
			font-size: 22px;
			color: #30304D;
			padding-bottom: 30px;
		}

		.stepDesc {
			font-size: 14px;
			color: #6B6F8C;
			line-height: 21px;


		}

		::v-deep .el-step__main {
			margin-left: 50px;

		}

	}
	.th1{
		text-indent: 2em;
	}
</style>