<template>
	<div>
		<div class="roleTitle">
			<el-badge value="" class="titleItem">
				<div @click="flagClick(0)" :class="{'action':search.flag  === 0}">已上架</div>
			</el-badge>
			<el-badge value="" class="titleItem">
				<div @click="flagClick(1)" :class="{'action':search.flag  === 1}">已下架</div>
			</el-badge>
		</div>
		<div class="fl search">
			<div class="fl">
				<div class="searchText">游戏区服：</div>
				<div style="position: relative;">
					<el-cascader clearable :append-to-body="false" v-model="districtId" style="width: 206px;"
						:options="gameList" :props="districtProps"></el-cascader>
				</div>
			</div>
			<div class="fl">
				<div class="searchText">商品类型：</div>
				<div style="position: relative;">
					<el-cascader clearable :append-to-body="false" v-model="commodityTypeId" style="width: 206px;"
						:options="gameList1" :props="districtProps1"></el-cascader>
				</div>
			</div>
			<div class="fl">
				<div class="searchText">交易类型：</div>
				<el-select clearable :popper-append-to-body="false" style="width: 200px;" v-model="search.deliveryType"
					placeholder="请选择">
					<el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
					</el-option>
				</el-select>
			</div>
		</div>

		<div class="fl search">
			<div class="fl">
				<div class="searchText">关键字：</div>
				<el-input v-prevent-space v-model="search.title" style="width: 206px;" placeholder="请输入关键字"></el-input>
				<!-- <el-select :popper-append-to-body="false" style="width: 286px;" v-model="value" placeholder="请选择">
					<el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
					</el-option>
				</el-select> -->
			</div>
			<div class="fl">
				<div class="searchText">创建时间：</div>
				<div style="position: relative;">
					<el-date-picker popper-class="popperClass" format="yyyy-MM-dd" style="width: 188px;" value-format="yyyy-MM-dd HH:mm:ss"
						:append-to-body="false" v-model="search.createTimeBegin" type="date" placeholder="选择日期">
					</el-date-picker>
				</div>
				<span style="margin: 0 10px; color: #000;">-</span>
				<div style="position: relative;">
					<el-date-picker popper-class="popperClass" format="yyyy-MM-dd" style="width: 188px;" value-format="yyyy-MM-dd HH:mm:ss"
						:append-to-body="false" v-model="search.createTimeEnd" type="date" placeholder="选择日期">
					</el-date-picker>
				</div>
			</div>
			<div style="margin-left: 11px;">
				<el-button type="primary" @click="getList" icon="el-icon-search">搜索</el-button>
			</div>
		</div>
		<div class="operate fl">
			<el-checkbox :indeterminate="isIndeterminate" v-model="checkAll"
				@change="handleCheckAllChange">全选</el-checkbox>
			<div style="margin-left: 24px;">
				<el-button @click="batchPut" size="mini">批量上架</el-button>
				<el-button @click="batchSold" size="mini">批量下架</el-button>
				<el-button @click="batchDelete" size="mini">批量删除</el-button>
			</div>

		</div>
		<div class="fl" style="justify-content: space-between;font-size: 18px;margin-top: 20px;">
			<div>
			</div>
			<el-pagination @size-change="sizeChange" @current-change="currentChange" :current-page="search.pageNo"
				:page-sizes="[20,30,50]" :page-size="search.pageSize" layout="total, sizes, prev, pager, next, jumper"
				:total="totalNumber">
			</el-pagination>
		</div>
		<el-checkbox-group @change="handleCheckedCitiesChange" v-model="ids">
			<div style="scrollbar-width: none; ">
				<div class="mineOrder" v-for="(item,index) in tableList">
					<div class="orderTop fl">
						<div class="fl" style="padding-right: 24px;">

							<div class="fl" style="margin-left: 24px;">
								<el-checkbox style="margin-right: 8px;" :label="item.id">&ensp;</el-checkbox>
								<span class="credit">订单编号：</span>
								<span class="credit" style="color: #1D223C;">{{item.id}}</span>
							</div>
							<div class="fl" style="margin-left: 24px;">
								<span class="credit">上架时间：</span>
								<span class="credit" style="color: #1D223C;">{{item.createTime}}</span>
							</div>
						</div>
						<div>
							<div class="fl">
								<span class="credit">售价：</span>
								<span class="credit"
									style="color: #0085FF;font-size: 24px;font-weight: bold;">¥{{item.price}}</span>
							</div>
						</div>
					</div>

					<div class="fl orderBottom">
						<div style="display: flex;margin-left: 24px;">
							<img :src="item.icon" style="width: 80px;height: 80px;border-radius: 12px;" />
							<div style="margin-left: 16px;">
								<div class="infoTitle "  style="display: flex;">
									<span v-if="item.gameCommodityCategory == 5 || item.gameCommodityCategory == 6"
										class="infoAccount">账户</span>
									<span v-else-if="item.deliveryType == 2" class="infoImage">寄售</span>
									<span v-else class="infoImage">担保</span>
									
									<el-input style="width: 150px;" v-if="tableList[index].isUpdateTitle == true" v-prevent-space @blur="setTitle(item,index)" v-model="item.title" 
										placeholder="请输入商品标题"></el-input>
										<span v-else class="el1" style="width: 150px;" @click="$router.push('/gameDetaile/'+item.id)">{{item.title}}</span>
								</div>
								<div class="idItem">
									<span>游戏区服：</span>
									<span style="color: #1D223C;">{{item.districtName}}</span>
								</div>
								<div class="idItem">
									<span>商品类型：</span>
									<span style="color: #1D223C;">{{item.commodityTypeName}}</span>
								</div>
							</div>
						</div>
						<div class="idItem infoItem" v-if="item.status == 0 ">未支付</div>
						<div class="idItem infoItem" v-if="item.status == 1 ">已支付</div>
						<div class="idItem infoItem" v-if="daysBetweenDates(item.createTime,item.expirationDate) > 0">
							<span style="color: #FF5C00;">{{daysBetweenDates(item.createTime,item.expirationDate)}}
							</span>天
						</div>
						<div class="idItem infoItem" v-else>过期</div>

						<div class="price fl" v-if="tableList[index].isUpdate == true">
							<el-input v-prevent-space @blur="setPrice(item,index)" v-model="item.price" type="number"
								placeholder="请输入价格"></el-input>
						</div>
						<div class="price fl" v-else>¥{{item.price}}</div>
						<div class="state" v-if="item.status == 0 ">
							<span class="btn2" @click="toPay(item)">去支付</span>

						</div>
						<div class="state" v-if="item.isOffShelf == 0 && item.status == 1 ">
							<span class="btn2" @click="update(index)">快捷改价</span>
							<!-- <span class="btn2">重新编辑</span> -->
							<div class="idItem" style="width: 128px;text-align: center;margin: 0;">
								<span @click="updateTitle(index)">编辑标题</span>
								<!-- <el-popconfirm @confirm="updateShop(item)" class="idItemHover" title="确定编辑么标题">
									<span slot="reference">编辑标题</span>
								</el-popconfirm> -->
								<span style="margin: 0 5px;">|</span>
								<el-popconfirm @confirm="shopOut(item)" class="idItemHover" title="确定下架么">
									<span slot="reference">下架</span>
								</el-popconfirm>
								<span style="margin: 0 5px;">|</span>
								<el-popconfirm @confirm="shopDelete(item)" class="idItemHover" title="确定删除此订单么？">
									<span slot="reference">删除</span>
								</el-popconfirm>

							</div>
						</div>
						<div class="state" v-if="item.isOffShelf == 1 && item.status == 1">
							<!-- <span class="btn2" @click="update(index)">快捷改价</span> -->
							<!-- <span class="btn2">重新编辑</span> -->
							<div class="idItem" style="width: 128px;text-align: center;margin: 0;">

								<el-popconfirm @confirm="shopOut(item)" class="idItemHover" title="确定重新上架么？">
									<span slot="reference">重新上架</span>

								</el-popconfirm>
								<span style="margin: 0 5px;">|</span>
								<el-popconfirm @confirm="shopDelete(item)" class="idItemHover" title="确定删除此订单么？">
									<span slot="reference">删除</span>
								</el-popconfirm>


							</div>
						</div>
					</div>
				</div>
			</div>
		</el-checkbox-group>
	<!-- <el-dialog title="修改信息" :visible.sync="dialogVisible1" width="60%" :before-close="handleClose">
		<div class="dialogCenter ">
			<div class="formItem fl" v-if="items.gameCommodityCategory == 8">
				<div class="item1"><span style="color:#FF3C3C;">*</span>代练内容：</div>
				<div class="item2">
					<el-input v-prevent-space v-model="items.title" placeholder="请输入代练内容"></el-input>
				</div>
			</div>
			<div class="formItem fl" v-if="items.gameCommodityCategory == 2">
				<div class="item1"><span style="color:#FF3C3C;">*</span>代练套餐：</div>
				<div class="item2">
					<el-input v-prevent-space v-model="items.title" placeholder="请输入代练套餐"></el-input>
				</div>
			</div>
		</div>
		<span slot="footer" class="dialog-footer">
			<el-button @click="dialogVisible1 = false">取 消</el-button>
			<el-button style="background-color: #0085FF;color: #fff;" @click="txtblur(setItems)">确 定</el-button>
		</span>
	</el-dialog> -->
		

	</div>
</template>

<script>
	import {
		getOrderList,
		getOrderNum,
		gameList,
		districtList,
		gameCommodityTypes,
		getSaleOrderList,
		gameCommodityMyList,
		gameCommodityEdit,
		gameCommodityDelete,
		offShelf,
		offShelfBatch,
		deleteBatch
	} from '@/api/index.js'
	import noDate from '@/components/noData.vue'
	export default {
		components: {
			noDate
		},
		data() {
			return {
				dialogVisible1:false,
				ids: [],
				checkAll: false,
				cities: [],
				isIndeterminate: false,
				options: [{
					value: '1',
					label: '担保'
				}, {
					value: '2',
					label: '寄售'
				}],
				districtId: [],
				commodityTypeId: [],
				search: {
					pageNo: 1,
					pageSize: 20,
					orderType: '1',
					isTrain: 1,
					flag: '',
					title: '',
					type: 1,
					createTimeBegin: '',
					createTimeEnd: '',
					// // 交易类型
					deliveryType: null,
					// // 区服id
					districtId: null,
					// // 商品分类id
					commodityTypeId: null,
					isHot: 0
				},
				totalNumber: 0,
				items:{},
				tableList: [],
				statusNum: {},
				gameList: [],
				gameList1: [],
				districtProps1: {
					value: 'id',
					label: 'name',
					// leaf:'hasChild',
					lazy: true,
					lazyLoad(node, resolve) {
						console.log(node)
						gameCommodityTypes({
							id: node.value.toString()
						}).then(res => {
							const {
								level
							} = node;
							let newList = []
							res.result.forEach(data => {

								data.leaf = level

								newList.push(data)
							})
							resolve(newList)
						})
					},
				},
				districtProps: {
					value: 'id',
					label: 'name',
					// leaf:'hasChild',
					lazy: true,
					lazyLoad(node, resolve) {
						console.log(node)
						districtList({
							id: node.value.toString()
						}).then(res => {
							const {
								level
							} = node;
							let newList = []
							res.result.forEach(data => {
								if (data.hasChild == 0) {
									data.leaf = level
								}
								newList.push(data)
							})
							resolve(newList)
						})
					},
				}
			}
		},
		created() {
			this.getList()
			this.getGameList()
		},
		methods: {
			updateShop(item){
				this.items= item
				this.dialogVisible1 = true
			},
			getGameList() {
				// 获取游戏列表
				gameList({
					pageNo: 1,
					pageSize: 1000
				}).then(res => {
					if (res.code == 200) {
						this.gameList = res.result.records
						this.gameList1 = res.result.records

					}
				})
			},
			getList() {
				if (this.districtId.length != 0) {
					this.search.districtId = this.districtId[this.districtId.length - 1]
				} else {
					this.search.districtId = null
				}
				if (this.commodityTypeId.length != 0) {
					this.search.commodityTypeId = this.commodityTypeId[this.commodityTypeId.length - 1]
				} else {
					this.search.commodityTypeId = null
				}
				gameCommodityMyList(this.search).then(res => {
					if (res.code == 200) {
						this.cities = JSON.parse(JSON.stringify(res.result.records))
						this.tableList = res.result.records
						this.totalNumber = res.result.total
					}

				})
			},
			handleCheckAllChange(val) {
				if (val) {
					this.cities.forEach(res => {
						this.ids.push(res.id)
					})
				} else {
					this.ids = []
				}
				this.checkAll = checkedCount === this.cities.length;
			},
			handleCheckedCitiesChange(value) {
				this.ids = value
				let checkedCount = value.length;
				this.checkAll = checkedCount === this.cities.length;
				this.isIndeterminate = checkedCount > 0 && checkedCount < this.cities.length;
			},
			// 状态搜索
			flagClick(type) {
				this.search.flag = type
				this.getList()
			},
			// 删除商品
			shopDelete(item) {
				gameCommodityDelete({
					id: item.id
				}).then(res => {
					if (res.code == 200) {
						this.message('删除成功', 'success')
						this.getList()
					}
				})
			},
			shopOut(item) {
				offShelf({
					id: item.id,
					isOffShelf: item.isOffShelf == 1 ? 0 : 1
				}).then(res => {
					if (res.code == 200) {
						this.message(res.message, 'success')
						this.getList()
					}
				})
			},
			sizeChange(e) {
				this.search.pageSize = e
				this.search.pageNo = 1
				this.getList()
			},
			currentChange(e) {
				this.search.pageNo = e
				this.getList()
			},
			// 去支付
			toPay(item) {
				let info = {
					title: item.title,
					districtName: item.districtName,
					price: item.price,
					id: item.id,
					type: 'earnestMoney',
					depositType: 6
				}
				// localStorage.setItem('orderPay', JSON.stringify(info));
				this.$router.push({
					path: '/orderPay',
					query: {
						info: JSON.stringify(info)
					}
				})
			},
			setTitle(item,index){
				this.$alert('确定要修改此商品吗？', '商品编辑', {
					confirmButtonText: '确定',
					callback: action => {
						if(action == 'confirm'){
							gameCommodityEdit(item).then(res => {
								if (res.code == 200) {
									this.message('修改成功', 'success')
									this.$set(this.tableList[index], 'isUpdateTitle', false);
								}
							})
						}else{
							console.log(this.cities)
							this.tableList[index].title = this.cities[index].title
							this.$set(this.tableList[index], 'isUpdateTitle', false);
							
						}
						
					}
				});
			},
			//修改价格
			setPrice(item, index) {
				
				this.$alert('确定要修改此商品吗？', '商品编辑', {
					confirmButtonText: '确定',
					callback: action => {
						if(action == 'confirm'){
							gameCommodityEdit(item).then(res => {
								if (res.code == 200) {
									this.message('价格修改成功', 'success')
									this.$set(this.tableList[index], 'isUpdate', false);
								}
							})
						}else{
							console.log(this.cities)
							this.tableList[index].price = this.cities[index].price
							this.$set(this.tableList[index], 'isUpdate', false);
							
						}
						
					}
				});
				
			},
			update(index) {
				this.$set(this.tableList[index], 'isUpdate', true);
			},
			updateTitle(index) {
				this.$set(this.tableList[index], 'isUpdateTitle', true);
			},
			// 批量上架
			batchPut() {
				offShelfBatch({
					ids: this.ids.join(','),
					isOffShelf: 0
				}).then(res => {
					if (res.code == 200) {
						this.ids = []
						let checkedCount = this.ids.length;
						this.checkAll = checkedCount === this.cities.length;
						this.isIndeterminate = checkedCount > 0 && checkedCount < this.cities.length;
						this.message(res.message, 'success')
						this.getList()
					}
				})
			},
			// 批量下架
			batchSold() {
				offShelfBatch({
					ids: this.ids.join(','),
					isOffShelf: 1
				}).then(res => {
					if (res.code == 200) {
						this.ids = []
						let checkedCount = this.ids.length;
						this.checkAll = checkedCount === this.cities.length;
						this.isIndeterminate = checkedCount > 0 && checkedCount < this.cities.length;
						this.message(res.message, 'success')
						this.getList()
					}
				})
			},
			// 批量删除
			batchDelete() {
				deleteBatch({
					ids: this.ids.join(',')
				}).then(res => {
					if (res.code == 200) {
						this.message(res.message, 'success')
						this.getList()
					}
				})
			}

		}
	}
</script>

<style scoped lang="scss">
	::v-deep .el-pagination span:not([class*=suffix]) {
		font-size: 16px;
	}

	::v-deep .el-pager li {
		font-size: 16px;
	}

	.roleTitle {

		width: 856px;
		border-bottom: 1px solid #ECECEC;
		height: 57px;
		// line-height: 87px;
		padding-left: 42px;
		padding-right: 42px;
		display: flex;
		align-items: center;
		
		.titleItem {
			font-weight: 400;
			font-size: 16px;
			position: relative;
			color: #1D223C;
			height: 30px;
			margin-right: 50px;
			cursor:pointer;
		}

		.titleItem::after {
			position: absolute;
			width: 1px;
			height: 20px;
			content: '';
			top: 2px;
			right: -25px;
			background-color: #DEDEDE;
		}

		.titleItem:last-child::after {
			width: 0px;
		}
	}

	.search {
		padding: 24px 0 0 0;

		.searchText {
			width: 100px;
			text-align: right;
			font-weight: 400;
			font-size: 14px;
			color: #1D223C;
		}

		::v-deep .popperClass {
			position: absolute !important;
			top: auto !important;
			left: auto !important;
		}

		::v-deep .el-select-dropdown {
			position: absolute !important;
			top: auto !important;
			left: auto !important;
		}

		::v-deep .el-cascader__dropdown {
			position: absolute !important;
			top: auto !important;
			left: auto !important;
		}
	}

	.cardPrice {
		font-weight: bold;
		font-size: 18px;
		color: #1D223C;
	}

	.action {
		color: #0085FF;
	}

	.mineOrder {
		border: 1px solid #DEDEDE;
		width: 930px;
		border-radius: 8px;
		margin-top: 24px;
		margin-left: 30px;
	}

	.orderTop {
		// width: 1232px;
		padding-right: 24px;
		height: 64px;
		background: #FCFCFC;
		border-radius: 8px 8px 0px 0px;
		border-bottom: 1px solid #DEDEDE;
		justify-content: space-between;

		.credit {
		font-weight: 400;
		font-size: 14px;
		color: #A5A7B1;
		margin-right: 5px;
		}
	}

	.infoTitle {
		.infoAccount {
			font-size: 12px;
			background: #FF5C00;
			border-radius: 4px;
			color: #fff;
			padding: 4px 6px;
		}

		font-weight: 600;
		font-size: 14px;
		line-height: 30px;
		color: #000000;
		width: 280px;
		cursor: pointer;
	}

	.idItem {
		font-weight: 400;
		font-size: 12px;
		color: #A5A7B1;
		margin-top: 8px;
	}

	.orderBottom {
		height: 108px;
	}

	.infoItem {
		margin: 0;
		width: 130px;
		text-align: center;
	}

	.price {
		font-weight: bold;
		font-size: 20px;
		color: #1D223C;
		width: 130px;
		text-align: center;
	}

	.state {
		width: 128px;
		display: flex;
		flex-direction: column;
		justify-content: center;

		.btn1 {
			width: 128px;
			height: 35px;
			font-weight: 500;
			font-size: 14px;
			color: #0085FF;
			text-align: center;
			line-height: 35px;
			margin-bottom: 8px;
			cursor: pointer;
		}

		.btn2 {
			width: 128px;
			height: 35px;
			background: rgba(0, 133, 255, 0.06);
			border-radius: 6px 6px 6px 6px;
			font-weight: 500;
			font-size: 14px;
			color: #0085FF;
			border: 1px solid #0085FF;
			text-align: center;
			line-height: 35px;
			margin-bottom: 8px;
			cursor: pointer;
		}
	}

	.idItemHover:hover {
		color: #0085FF;
	}

	.operate {
		margin-left: 30px;
		margin-top: 20px;

	}

	.infoImage {
		font-size: 12px;
		background: #0085FF;
		border-radius: 4px;
		color: #fff;
		padding: 4px 6px;
		margin-left: 5px;
		
	}
	.formItem {
		margin-top: 20px;
		color: #000;
		display: flex;

		.item1 {
			width: 200px;
			text-align: right;
		}

		.item2 {
			min-width: 300px;
			margin-left: 10px;
			position: relative;

			::v-deep .el-select-dropdown {
				position: absolute !important;

				top: 35px !important;
				left: 0px !important;
			}


		}

		.item3 {
			font-weight: 400;
			font-size: 16px;
			color: #0085FF;
			margin-left: 20px;
		}

		.item4 {
			border: 1px solid #DEDEDE;
			border-radius: 4px 4px 4px 4px;
		}

		.item5 {
			border-top: 1px solid #DEDEDE;
			font-weight: 400;
			font-size: 16px;
			color: #A5A7B1;
			height: 56px;
			line-height: 56px;
			padding-left: 20px;
		}

	}
</style>