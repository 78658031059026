<template>
	<div class="game">
		<layout-top></layout-top>
		<div class="stepList">
			<el-steps :active="step" align-center>
				<el-step title="选择游戏分类">
					<div slot="icon">
						<div class="step" :class="{'stepAction': step>=1 }">1</div>
					</div>
				</el-step>
				<el-step title="填写分类信息">
					<div slot="icon">
						<div class="step" :class="{'stepAction': step>=2 }">2</div>
					</div>
				</el-step>
				<el-step title="填写商品信息">
					<div slot="icon">
						<div class="step" :class="{'stepAction': step>=3 }">3</div>
					</div>
				</el-step>
				<el-step title="完成商品发布">
					<div slot="icon">
						<div class="step" :class="{'stepAction': step>=4 }">
							✔
						</div>
					</div>
				</el-step>
			</el-steps>
		</div>

		<div style="padding-bottom: 50px;">
			<el-row class="content">
				
				<el-col :span="24">
					<div class="classify">
						<div>
							<span class="gameClass" :class="{'action':search.terminalType == index}"
								v-for="(item,index) in lableList" :key="index" @click="swiperTab(index)">{{item}}</span>
						</div>
						<div class="selectForm fl">
							<div class="selectItem">
								<div class="top1" v-if="gameIndex !== ''">
									<i class="el-icon-circle-check" style="color: #19D972;"></i>
									<span style="color: #19D972;">{{gameList[gameIndex].name}}</span>
								</div>
								<div class="top1" v-else>
									<span>选择游戏</span>
								</div>

								<el-input v-prevent-space v-model="search.name" @input="inputSearch" class="top2" placeholder="请输入进行搜索"
									prefix-icon="el-icon-search">
								</el-input>

								<div class="top3">
									<span v-for="(item,index) in gameList" @click="selectGame(index)" class="top3Item"
										:class="{'top3Action':index == gameIndex}">{{item.name}}</span>
								</div>
							</div>
							<div class="selectItem">
								<div class="top1" v-if="gameTypeIndex !== ''">
									<i class="el-icon-circle-check" style="color: #19D972;"></i>
									<span style="color: #19D972;">{{typeList[gameTypeIndex].name}}</span>
								</div>
								<div class="top1" v-else>
									<span>选择商品类型</span>
								</div>
								<el-input v-prevent-space class="top2" v-model="typeName" @input="typeInput" placeholder="请输入进行搜索"
									prefix-icon="el-icon-search">
								</el-input>

								<div class="top3">
									<span v-for="(item,index) in typeList" @click="selectType(index)" class="top3Item"
										:class="{'top3Action':index == gameTypeIndex}">{{item.name}}</span>
								</div>
							</div>


							<div class="selectItem" v-if="typeChildList.length != 0">
								<div class="top1" v-if="typeChildIndex !== ''">
									<i class="el-icon-circle-check" style="color: #19D972;"></i>
									<span style="color: #19D972;">{{typeChildList[typeChildIndex]}}</span>
								</div>
								<div class="top1" v-else>
									<span>选择商品子型</span>
								</div>
								<el-input v-prevent-space class="top2" v-model="typeChildName" @input="typeChildInput"
									placeholder="请输入进行搜索" prefix-icon="el-icon-search">
								</el-input>

								<div class="top3">
									<span v-for="(item,index) in typeChildList" @click="selectChildType(index)"
										class="top3Item"
										:class="{'top3Action':index === typeChildIndex}">{{item}}</span>
								</div>
							</div>

							<div class="selectItem" v-if="areaList.length != 0">
								<div class="top1" v-if="areaIndex !== ''">
									<i class="el-icon-circle-check" style="color: #19D972;"></i>
									<span style="color: #19D972;">{{areaList[areaIndex].name}}</span>
								</div>
								<div class="top1" v-else>
									<span v-if="gameList[gameIndex].terminalType  == 2">选择系统</span>
									<span v-if="gameList[gameIndex].terminalType  == 1">选择大区</span>
								</div>
								<el-input v-prevent-space class="top2" @input="getDistrictList" v-model="areaName" placeholder="请输入进行搜索"
									prefix-icon="el-icon-search">
								</el-input>

								<div class="top3">
									<span v-for="(item,index) in areaList" class="top3Item" @click="nextFloor(index,1)"
										:class="{'top3Action':index === areaIndex}">{{item.name}}</span>
								</div>
							</div>

							<div class="selectItem" v-if="regionList.length != 0">
								<div class="top1" v-if="regionIndex !== ''">
									<i class="el-icon-circle-check" style="color: #19D972;"></i>
									<span style="color: #19D972;">{{regionList[regionIndex].name}}</span>
								</div>
								<div class="top1" v-else>
									<span v-if="gameList[gameIndex].terminalType  == 2">选择大区</span>
									<span v-if="gameList[gameIndex].terminalType  == 1">选择服务器</span>
								</div>
								<el-input v-prevent-space v-prevent-space @input="inputRegion" v-model="regionName" class="top2" placeholder="请输入进行搜索"
									prefix-icon="el-icon-search">
								</el-input>

								<div class="top3">
									<span v-for="(item,index) in regionList" class="top3Item"
										@click="nextFloor(index,2)"
										:class="{'top3Action':index === regionIndex}">{{item.name}}</span>
								</div>
							</div>

							<div class="selectItem" v-if="serverList.length != 0">
								<div class="top1" v-if="serverIndex !== ''">
									<i class="el-icon-circle-check" style="color: #19D972;"></i>
									<span style="color: #19D972;">{{serverList[serverIndex].name}}</span>
								</div>
								<div class="top1" v-else>
									<span>选择服务器</span>
								</div>
								<el-input v-prevent-space @input="serverInput" v-model="serverName" class="top2" placeholder="请输入进行搜索"
									prefix-icon="el-icon-search">
								</el-input>
							
								<div class="top3">
									<span v-for="(item,index) in serverList" class="top3Item"
										@click="nextFloor(index,3)"
										:class="{'top3Action':index === serverIndex}">{{item.name}}</span>
								</div>
							</div>
							
							<div class="selectItem" v-if="server1List.length != 0">
								<div class="top1" v-if="server1Index !== ''">
									<i class="el-icon-circle-check" style="color: #19D972;"></i>
									<span style="color: #19D972;">{{server1List[server1Index].name}}</span>
								</div>
								<div class="top1" v-else>
									<span>选择服务器</span>
								</div>
								<!-- <el-input v-prevent-space @input="server1Input" v-model="server1Name" class="top2" placeholder="请输入进行搜索"
									prefix-icon="el-icon-search">
								</el-input> -->

								<div class="top3">
									<span v-for="(item,index) in server1List" class="top3Item"
										@click="nextFloor(index,4)"
										:class="{'top3Action':index === server1Index}">{{item.name}}</span>
								</div>
							</div>


						</div>
						<div class="submit">
							<el-button @click="toPublic2()" style="font-size: 14px;"
								type="primary">下一步,填写商品信息</el-button>
						</div>
					</div>
				</el-col>
				
			</el-row>
		</div>
		<shopping-card></shopping-card>
		<footer-bars></footer-bars>
	</div>
</template>

<script>
	import layoutTop from '@/components/layoutTop.vue';
	import shoppingCard from '@/components/shoppingCart.vue';
	import footerBars from '@/components/footerBars.vue';
	import {
		gameList,
		districtList,
		gameCommodityTypes
	} from '@/api/index.js'
	export default {
		components: {
			layoutTop,
			shoppingCard,
			footerBars

		},

		data() {
			return {
				step: 2,
				action: 0,
				lableList: ['全部游戏', '网络游戏', '手机游戏'],
				search: {
					initial: '', //首字母
					name: '',
					pageNo: 1,
					pageSize: 1000,
					terminalType: ''
				},
				gameList: [],
				gameIndex: 0,
				gameTypeIndex: 0,
				gameId: '',

				// 分类
				typeList: [],
				typeName: '',
				// 分类子集
				typeChildList: [],
				typeChildName: '',
				typeChildIndex: '',


				// 区服列表
				areaIndex: '',
				areaList: [],
				areaName: '',
				// 大区列表
				regionList: [],
				regionIndex: '',
				regionName: '',
				// 服务器列表
				serverList: [],
				serverName: '',
				serverIndex: '',
				
				// 第四层
				server1List: [],
				server1Name: '',
				server1Index: ''
			}
		},
		created() {
			// localStorage.setItem('publish1', JSON.stringify(info));
			let info =  JSON.parse(this.$route.query.info)
			if(info == null){
				this.$router.push('/')
			}
			this.gameId = info.id
			this.getGameList()
			if (info.gameTypeIndex != undefined && info.gameTypeIndex !== '') {
				// this.gameTypeIndex = JSON.parse(this.$route.query.info).gameTypeIndex
				this.selectType( info.gameTypeIndex)
			}
		
			




		},
		methods: {
			toPublic2(){
				let info = {
					// 子分类
					childCommodityType: '',
					// 游戏商品分类id	
					commodityTypeId: this.typeList[this.gameTypeIndex].id,
					// 游戏商品分类名称	
					commodityTypeName: this.typeList[this.gameTypeIndex].name,
					// 区服id
					districtId: '',
					// 区服名称
					districtName: '',
					// 游戏商品大类
					gameName: this.gameList[this.gameIndex].name,
					gameCommodityCategory: this.typeList[this.gameTypeIndex].gameCommodityCategory,
					// 游戏ID
					gameId: this.gameList[this.gameIndex].id,
					// 保证金金额
					deposit: this.typeList[this.gameTypeIndex].deposit,
					// 发布游戏币单位
					commodityUnit: this.typeList[this.gameTypeIndex].commodityUnit,
					// 游戏图标
					icon: this.gameList[this.gameIndex].icon,
					addType:JSON.parse(this.$route.query.info).addType
				}
				if (this.typeChildList.length != 0) {
					info.childCommodityType = this.typeChildList[this.typeChildIndex]
				}
				if(this.regionIndex === ''){
					info.districtName = this.areaList[this.areaIndex].name
					info.districtId = this.areaList[this.areaIndex].id 
					if(this.areaList[this.areaIndex].hasChild == 1){
						this.message('请选择区服','error')
						return
					}
					
				}else if(this.serverIndex === ''){
					info.districtName = this.areaList[this.areaIndex].name+ '/' + this.regionList[this.regionIndex].name
					info.districtId = this.areaList[this.areaIndex].id + '/' + this.regionList[this.regionIndex].id
					if(this.regionList[this.regionIndex].hasChild == 1){
						this.message('请选择区服','error')
						return
					}
				}else if(this.server1Index === ''){
					info.districtName = this.areaList[this.areaIndex].name + '/' + this.regionList[this.regionIndex].name +
						'/' + this.serverList[this.serverIndex].name
					info.districtId = this.areaList[this.areaIndex].id + '/' + this.regionList[this.regionIndex].id + '/' +
						this.serverList[this.serverIndex].id
						if(this.serverList[this.serverIndex].hasChild == 1){
							this.message('请选择区服','error')
							return
						}
				}
				
				info.tradeRemark = this.typeList[this.gameTypeIndex].publishRemark
				info.isDelivery = this.typeList[this.gameTypeIndex].isDelivery
				info.minInsure = this.typeList[this.gameTypeIndex].minInsure
				
				
				// localStorage.setItem('publish2', JSON.stringify(info));
				window.open(this.$router.resolve({
					path: '/publish2',
					query: {
						info: JSON.stringify(info)
					}
				}).href, '_blank');
				
				// 跳转参数
				// if (this.gameList[this.gameIndex].terminalType == 1 && type == 2) {
				// 	// info.districtName = this.areaList[this.areaIndex].name + '/' + this.regionList[this.regionIndex].name
				// 	// info.districtId = this.areaList[this.areaIndex].id + '/' + this.regionList[this.regionIndex].id
				// 	info.tradeRemark = this.typeList[this.gameTypeIndex].publishRemark
				// 	info.isDelivery = this.typeList[this.gameTypeIndex].isDelivery
				// 	info.minInsure = this.typeList[this.gameTypeIndex].minInsure
				// 	this.$router.push({
				// 		path: '/publish2',
				// 		query: {
				// 			info: JSON.stringify(info)
				// 		}
				// 	})
				// }
			},
			// 获取区服下一层
			nextFloor(index, type) {
				this.areaName = ''
				this.regionName = ''
				this.regionName = ''
				
				if (type == 1) {
					this.areaIndex = index
					districtList({
						id: this.areaList[index].id,
						name: this.areaName
					}).then(res => {
						if (res.code == 200 && res.result.length != 0) {
							this.regionList = res.result
							
							if (JSON.parse(this.$route.query.info).sonGameAreaSelect != undefined && JSON.parse(this
									.$route.query.info).sonGameAreaSelect !== '') {
								res.result.forEach((item, index) => {
									if (item.id == JSON.parse(this.$route.query.info).sonGameAreaSelect) {
										this.nextFloor(index,2)
									}
								})
							}
							if(this.areaList[0].hasChild == 0){
								this.toPublic2()
							}
						}else{
							this.regionList = []
							if(res.result.length == 0){
								this.toPublic2()
							}
						}
						
					})
					
				} else if (type == 2) {
					this.regionIndex = index
					districtList({
						id: this.regionList[index].id,
						name: this.regionName
					}).then(res => {
						if (res.code == 200 && res.result.length != 0) {
							this.serverList = res.result
							if(this.regionList[0].hasChild == 0){
								this.toPublic2()
							}
						}
						if(res.result.length == 0){
							this.toPublic2()
						}
						
					})
					
				} else if(type == 3) {
					this.serverIndex = index
					districtList({
						id: this.serverList[index].id,
						name: this.regionName
					}).then(res => {
						if (res.code == 200 && res.result.length != 0) {
							this.server1List = res.result
							if(this.serverList[0].hasChild == 0){
								this.toPublic2()
							}
						}
						if(res.result.length == 0){
							this.toPublic2()
						}
						
					})
					
				}else{
					this.server1Index =index
					this.toPublic2()
				}
				
				

			},
			// 选择子集
			selectChildType(index) {
				this.typeChildIndex = index
				this.getDistrictList()
			},
			serverInput(e) {
				this.serverName = e
				districtList({
					id: this.regionList[this.regionIndex].id,
					name: this.serverName
				}).then(res => {
					if (res.code == 200 && res.result.length != 0) {
						this.serverList = res.result
					}
				})
			},
			typeInput(e) {
				
				this.typeName = e
				this.getGameCommodityTypes()
			},
			// 搜索游戏列表
			inputSearch(e) {
				this.search.name = e
				this.getGameList()

			},
			inputRegion(e) {
				this.regionName = e
				console.log(e)
				districtList({
					id: this.areaList[this.areaIndex].id,
					name: this.regionName
				}).then(res => {

					if (res.code == 200 && res.result.length != 0) {
						this.regionList = res.result
					}
				})

			},
			//选择分类 
			selectType(index) {
				this.gameTypeIndex = index

				// 区服列表
				this.areaIndex = ''
				this.areaList = []
				this.areaName = ''
				// 大区列表
				this.regionList = []
				this.regionIndex = ''
				this.regionName = ''
				// 服务器列表
				this.serverList = []
				this.serverName = ''
				this.serverIndex = ''
				
				
				if (this.typeList[this.gameTypeIndex].childrenName != null && this.typeList[this.gameTypeIndex]
					.childrenName != '') {
					this.typeChildList = this.typeList[this.gameTypeIndex].childrenName.split(' ')
					
				} else {
					this.typeChildList = []
					this.getDistrictList()
				}
				
				
			},
			// 选择游戏
			selectGame(index) {
				this.gameIndex = index
				this.gameTypeIndex = 0
				this.areaIndex = ''
				this.gameId = this.gameList[index].id
				this.areaIndex = ''
				// 区服列表
				this.areaList = []
				// 大区列表
				this.regionList = []
				this.regionIndex = ''
				// 服务器列表
				this.serverList = []
				this.serverIndex = ''

				// this.getDistrictList()
				this.getGameCommodityTypes()
			},

			// 游戏列表
			getGameList(type) {
				gameList(this.search).then(res => {
					if (res.code == 200) {
						this.gameList = res.result.records
						if (type == 'select') {
							this.gameIndex = 0
							this.gameId = this.gameList[this.gameIndex].id
						} else {
							res.result.records.forEach((data, index) => {
								if (data.id == this.gameId) {
									this.gameIndex = index
								}
							})
						}

						// this.getDistrictList()
						this.getGameCommodityTypes()
					}
				})
			},
			getGameCommodityTypes() {
				gameCommodityTypes({
					id: this.gameId,
					name: this.typeName
				}).then(res => {

					if (JSON.parse(this.$route.query.info).addType == 'addHot') {
						this.typeList = res.result.filter(item => item.isHot == 1)
					} else {
						this.typeList = res.result
					}

					if (this.typeList[this.gameTypeIndex].childrenName != null && this.typeList[this.gameTypeIndex]
						.childrenName != '') {
						this.typeChildList = this.typeList[this.gameTypeIndex].childrenName.split(' ')
						if (JSON.parse(this.$route.query.info).selectChildTypeIndex != undefined && JSON.parse(this.$route.query.info)
							.selectChildTypeIndex !== '') {
							this.selectChildType(JSON.parse(this.$route.query.info).selectChildTypeIndex)
						}
					} else {
						this.typeChildList = []
						this.getDistrictList()
					}
				})
			},

			// 游戏区服列表
			getDistrictList() {
				districtList({
					id: this.gameId,
					name: this.areaName
				}).then(res => {
					if (res.code == 200) {
						this.areaList = res.result

						if (JSON.parse(this.$route.query.info).gameAreaSelect != undefined && JSON.parse(this
								.$route.query.info).gameAreaSelect !== '') {
							res.result.forEach((item, index) => {
								if (item.id == JSON.parse(this.$route.query.info).gameAreaSelect) {
									this.nextFloor(index,1)
								}
							})
						}



					}
				})
			},
			swiperTab(index) {
				if (index == 0) {
					this.search.terminalType = ''
				} else {
					this.search.terminalType = index
				}

				this.gameTypeIndex = 0
				this.areaIndex = ''
				// this.gameId = this.gameList[this.gameTypeIndex].id
				this.areaIndex = ''
				// 区服列表
				this.areaList = []
				// 大区列表
				this.regionList = []
				this.regionIndex = ''
				// 服务器列表
				this.serverList = []
				this.serverIndex = ''

				this.getGameList('select')
			},
		}


	}
</script>
<style lang="scss" scoped>
	.stepList {
		width: 600px;
		margin: 15px auto;
	}

	.step {
		width: 27px;
		height: 27px;
		color: #fff;
		// background: #0085FF 0 0 no-repeat transfor;
		text-align: center;
		// transform: rotate(45deg);
		line-height: 27px;
		position: relative;
	}

	.step::before {
		content: "";
		position: absolute;
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
		z-index: -1;
		border-radius: 6px 6px 6px 6px;
		background: #DDDDDD;
		background-size: 100% 100%;
		transform: rotate(45deg);
		// transform: scaleY(-1);

	}

	.stepAction {
		width: 27px;
		height: 27px;
		color: #fff;
		// background: #0085FF 0 0 no-repeat transfor;
		text-align: center;
		// transform: rotate(45deg);
		line-height: 27px;
		position: relative;
	}

	.stepAction::before {
		content: "";
		position: absolute;
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
		z-index: -1;
		border-radius: 6px 6px 6px 6px;
		background: #0085FF;
		background-size: 100% 100%;
		transform: rotate(45deg);
		// transform: scaleY(-1);

	}

	.stepSuccess {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
	}

	::v-deep .el-step__title.is-process {
		color: #C0C4CC;
		font-weight: 500;

	}

	.game {
		background-color: #F8F8F8;
	}

	.lableTop {
		position: relative;

		.lableImage {
			width: 100%;
			height: 300px;
			position: absolute;
		}
	}

	.lableBg {
		padding-top: 40px;
		height: 220px;

		.lableTitle {
			font-weight: bold;
			font-size: 32px;
			color: #FFFFFF;
		}

		.lableDetaile {
			font-weight: 400;
			font-size: 20px;
			color: #FFFFFF;
			margin-top: 12px;
		}
	}

	.classify {
		color: #777A8A;
		background: #FFFFFF;
		width: 100%;
		// margin-top: -80px;
		border-radius: 20px 20px 20px 20px;
		padding: 30px;

		.gameClass {
			font-weight: 400;
			font-size: 18px;
			color: #777A8A;
			margin-right: 40px;
		}

		.action {
			font-weight: 600;
			color: #0085FF;
			position: relative;
		}

		.action::after {
			position: absolute;
			content: '';
			height: 4px;
			width: 72px;
			background: #0085FF;
			bottom: -10px;
			left: 0;
		}
	}

	.searchKey {
		// width: 100%;
		height: 60px;
		background: #F6F6F6;
		justify-content: space-between;
		padding: 0 16px;
		margin-top: 30px;

		.searchItem {
			font-weight: 600;
			font-size: 20px;
			color: #1D223C;
		}

		.actions {
			font-weight: 600;
			color: #0085FF;

		}
	}


	.selectForm {

		height: 648px;
		margin-top: 30px;
		background: #F7F7F7;
		border-radius: 12px 12px 12px 12px;

		.selectItem {
			width: 180px;

			display: flex;
			flex-direction: column;
			// justify-content: center;
			padding: 15px;

			.top1 {
				font-weight: 400;
				font-size: 18px;
				color: #FF5C00;
				padding-top: 5px;
				// height: 48px;
				width: 180px;
				text-align: center;
			}

			.top2 {
				width: 180px;
				margin-top: 22px;
			}

			.top3 {
				width: 180px;
				height: 480px;
				background: #FFFFFF;
				border-radius: 6px 6px 6px 6px;
				border: 1px solid #ECECEC;
				display: flex;
				flex-direction: column;
				margin-top: 12px;
				overflow-y: auto;
			}

			.top3Item {
				height: 48px;
				color: #000;
				line-height: 48px;
				padding-left: 20px;
				font-size: 14px;
			}

			.top3Item:hover {
				background: rgba(0, 133, 255, 0.06);
				color: #0085FF;
			}

			.top3Action {
				background: #0085FF;
				color: #FFFFFF;
			}
		}
	}

	.submit {
		display: flex;
		justify-content: center;
		align-items: center;
		background: none;
		margin-top: 30px;

	}
</style>