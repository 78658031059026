<template>
	<div style="background-color: #F8F8F8;min-height: 100vh;">
		<layout-top></layout-top>
		<div class="lableTop">
			<img class="lableImage" :src="require('@/static/image/index/classBg.png')" />
			<el-row class="content">
				
				<el-col :span="24">
					<div class="lableBg ">
						<div class="gameClass">
							<div class="lableTitle">我的购物车</div>
							<div class="lableDetaile">请根据同一区服、同一商品类型、同一交易方式批量结算</div>
						</div>
					</div>
				</el-col>
				
			</el-row>
		</div>

		<div>
			<el-row class="content">
				
				<el-col :span="24">
					<div class="screen">
						<div class="cardTop fl">
							<div class="th1">
								<!-- <el-checkbox v-model="checked"><span style="font-size: 16px;">全选</span></el-checkbox> -->
								<span class="cardTitle">商品信息</span>
							</div>
							<!-- <div class="th2">
								<span class="cardTitle">单价</span>
							</div> -->
							<div class="th3">
								<span class="cardTitle">购买数量</span>
							</div>
							<div class="th4">
								<span class="cardTitle">库存</span>
							</div>
							<div class="th5">
								<span class="cardTitle">金额</span>
							</div>
							<div class="th6">
								<span class="cardTitle">操作</span>
							</div>
						</div>
						<div style="margin: 20px;" v-for="(item,index) in  tableData">
							<el-table ref="multipleTable" :data="item.shopCartList" tooltip-effect="dark"
								style="width: 100%" @selection-change="$event=>handleSelectionChange($event,index)">
								<el-table-column type="selection" width="35">
								</el-table-column>
								<el-table-column width="430">
									<template slot="header">
										<div class="cardPrice">{{item.districtName}}</div>
									</template>
									<template slot-scope="scope">
										<div @click="$router.push('/gameDetaile/'+scope.row.gameCommodityId)">
											<div style="display: flex;">
												<img :src="scope.row.icon"
													style="width: 80px;height: 80px;border-radius: 12px;" />
												<div style="margin-left: 16px;">
													<div class="infoTitle el1">
														<span
															v-if="scope.row.gameCommodityCategory == 5 || scope.row.gameCommodityCategory == 6"
															class="infoAccount">账户</span>

														<span v-else-if="scope.row.deliveryType == 2" style="background-color: rgb(183, 66, 255);"
															class="infoImage">寄售</span>
														<span v-else class="infoImage">担保</span>
														<span>{{scope.row.title}}</span>
													</div>
													<div class="idItem">
														<span>游戏区服：</span>
														<span style="color: #1D223C;">{{scope.row.districtName}}</span>
													</div>
													<div class="idItem">
														<span>商品类型：</span>
														<span
															style="color: #1D223C;">{{scope.row.commodityTypeName}}</span>
													</div>
												</div>
											</div>
										</div>
									</template>
								</el-table-column>
								<!-- <el-table-column prop="name" width="100">
									<template slot-scope="scope">
										<div class="cardPrice">{{scope.row.price}}</div>
									</template>
								</el-table-column> -->
								<el-table-column prop="address" width="220">
									<template slot-scope="scope">
										<el-input-number v-model="scope.row.buyNum"
											@change="$event=>handleChange($event,scope.row,index)" :min="1"
											:max="scope.row.num" label="描述文字"></el-input-number>
									</template>
								</el-table-column>
								<el-table-column prop="address" width="90" align="center">
									<template slot-scope="scope">
										<div class="cardPrice">{{scope.row.num}}</div>
									</template>
								</el-table-column>
								<el-table-column prop="address" width="240" align="center">
									<template #header>
										<div class="cardPrice">已选
											<span v-if="item.selectList != undefined">{{item.selectList.length}}</span>
											<span v-else>0</span>
											件 <span style="color: #FF5C00;">¥{{totalPrice(item.selectList)}}</span>
										</div>

									</template>
									<template slot-scope="scope">
										<div class="cardPrice" style="color: #FF5C00;">
											¥{{scope.row.buyNum * scope.row.price}}</div>
									</template>
								</el-table-column>
								<el-table-column prop="address" width="140" align="right">
									<template #header>
										<div>
											<el-button @click="toOrderConfig(item.selectList)"
												type="primary">去结算</el-button>
										</div>
									</template>
									<template slot-scope="scope">

										<div class="cardDelete" @click="deleteGood(scope.row)">删除</div>
									</template>
								</el-table-column>
							</el-table>
						</div>
						<el-empty v-if="tableData.length ==0" description="暂无数据"></el-empty>

					</div>
				</el-col>
				<el-col :span="2">
					<div>&emsp;</div>
				</el-col>
			</el-row>
		</div>

		<div class="cardBottom">
			<el-row class="content">
				
				<el-col :span="24">
					<div class="bottom fl">
						<el-checkbox @change="handleCheckAllChange" v-model="checked"><span
								style="font-size: 16px;">全选</span></el-checkbox>
						<el-button class="delete" @click="deleteShops">删除已选商品</el-button>
						<span class="bottomText" @click="deleteExpireTime">清空失效商品</span>
						<span class="bottomText" @click="dialogVisible = true">清空购物车</span>
					</div>
				</el-col>
			
			</el-row>
		</div>
		<el-dialog title="温馨提示" :visible.sync="dialogVisible" width="30%">
			<div class="dialogCenter fl">
				<img :src="require('@/static/image/index/Vector.png')" style="width: 76px;height: 76px;" />
				<span class="deleteTitle">清空购物车</span>
				<span class="deleteDetaile">您确定要清空购物车吗？<br />取消后数据将无法恢复哦～</span>
			</div>
			<span slot="footer" class="dialog-footer">
				<el-button @click="dialogVisible = false">取 消</el-button>
				<el-button style="background-color: #FF5C00;color: #fff;" @click="clearDate()">确 定</el-button>
			</span>
		</el-dialog>
		<shopping-card></shopping-card>
		<!-- <footer-bars></footer-bars> -->
	</div>
</template>

<script>
	import {
		shopList,
		shopCartAdd,
		shopCartDeleteAll,
		deleteList,
		deleteExpireTime
	} from '@/api/index.js'
	import layoutTop from '@/components/layoutTop.vue';
	import shoppingCard from '@/components/shoppingCart.vue';
	import footerBars from '@/components/footerBars.vue';
	export default {
		components: {
			layoutTop,
			shoppingCard,
			footerBars
		},
		data() {
			return {
				num: 1,
				dialogVisible: false,
				tableData: [],
				checked: false
			}
		},
		created() {
			this.getList()
		},
		methods: {
			getList() {
				shopList().then(res => {
					this.tableData = res.result
				})
			},
			//全选 
			handleCheckAllChange() {
				let that = this
				console.log(this.checked)
				this.tableData.forEach((res, index) => {
					this.$refs.multipleTable[index].clearSelection()
					if (this.checked) {
						this.$refs.multipleTable[index].toggleAllSelection(res.shopCartList)
					}

				})
			},
			handleSelectionChange(e, index) {
				this.$set(this.tableData[index], 'selectList', e)
				let newtable = this.tableData
				console.log(newtable)
				this.checked = newtable.every((e) => {
					return JSON.stringify(e.selectList) == JSON.stringify(e.shopCartList)
				})

			},
			handleChange(e, item, index) {
				shopCartAdd({
					num: e,
					price: item.price,
					id: item.gameCommodityId
				}).then(res => {

				})
				if (this.tableData[index].selectList != undefined) {
					this.tableData[index].selectList.forEach((data, indexs) => {
						if (item.gameCommodityId == data.gameCommodityId) {
							data.buyNum = e
							// this.tableData[index].selectList[indexs] = data
							this.$set(this.tableData[index].selectList, indexs, data)
						}
					})
				}
				console.log(this.tableData)
				// this.$forceUpdate()

			},
			// 购物车删除
			deleteGood(item) {
				shopCartAdd({
					num: 0,
					price: item.price,
					id: item.gameCommodityId
				}).then(res => {
					if (res.code == 200) {
						this.getList()
					}
				})
			},
			totalPrice(value) {
				console.log(value)
				if (value == undefined) {
					return 0
				}
				let total = 0
				value.forEach(res => {
					total = total + res.buyNum * res.price
				})
				return total.toFixed(2)

			},
			// 去支付
			toOrderConfig(selectList) {
				console.log(selectList)
				if (selectList == undefined || selectList.lenght == 0) {
					this.message('请选择结算商品', 'error')
					return
				}
				let info = {
					type: 'toPay',
					shopCartIdList: []
				}
				selectList.forEach(item => {
					info.shopCartIdList.push(item.id)
				})
				// localStorage.setItem('confirmOrder', JSON.stringify(info));
				this.$router.push({
					path: '/confirmOrder',
					query: {
						info: JSON.stringify(info)
					}
				})
			},
			clearDate() {
				shopCartDeleteAll().then(res => {
					if (res.code == 200) {
						this.getList()
						this.dialogVisible = false
					}
				})
			},
			// 批量删除
			deleteShops() {
				let ids = []
				this.tableData.forEach((item, index) => {
					if (!this.isEmpty(item.selectList)) {
						item.selectList.forEach((items, index) => {
							ids.push(items.id)
						})
					}
				})
				deleteList({
					idList: ids
				}).then(res => {
					if (res.code == 200) {
						this.message('操作成功', 'success')
						this.getList()
					}
				})
			},
			// 清空失效商品
			deleteExpireTime() {
				deleteExpireTime().then(res => {
					if (res.code == 200) {
						this.message('操作成功', 'success')
						this.getList()
					}
				})
			}
		}
	}
</script>

<style scoped lang="scss">
	.lableTop {
		position: relative;

		.lableImage {
			width: 100%;
			height: 150px;
			position: absolute;
		}
	}

	.lableBg {
		margin-top: 30px;
		margin-bottom: 20px;
		// height: 150px;
		display: flex;
		cursor: pointer;
	
		.lableTitle {
			font-weight: bold;
			font-size: 24px;
			color: #FFFFFF;
		}
	
		.lableDetaile {
			font-weight: 400;
			font-size: 14px;
			color: #FFFFFF;
			margin-top: 12px;
		}
	}

	.screen {
		color: #777A8A;
		background: #FFFFFF;
		// margin-top: -80px;
		border-radius: 20px 20px 20px 20px;
		margin-bottom: 106px;
		padding-bottom: 20px;
		padding-top: 20px;
		// height: 700px;
		// padding: 0 0 24px;
	}



	.infoTitle {
		.infoAccount {
			font-size: 12px;
			background: #FF5C00;
			border-radius: 4px;
			color: #fff;
			padding: 4px 6px;
		}

		font-weight: 600;
		font-size: 14px;
		line-height: 30px;
		color: #000000;
		width: 450px;
	}

	.idItem {
		font-weight: 400;
		font-size: 14px;
		color: #A5A7B1;
		margin-top: 4px;
	}

	.cardPrice {
		font-weight: bold;
		font-size: 14px;
		color: #1D223C;

	}

	.cardDelete {
		font-weight: 400;
		font-size: 16px;
		color: #A5A7B1;
	}

	.toPay {
		width: 124px;
		height: 46px;
		background: #DDDDDD;
		border-radius: 6px;
		text-align: center;
		line-height: 46px;

		font-weight: 600;
		font-size: 18px;
		color: #FFFFFF;

	}

	::v-deep .el-table {
		border: 1px solid #FAFAFA;
		border-radius: 6px;
	}

	::v-deep .el-table th {
		background: #FAFAFA;
	}

	.cardTop {
		margin: 0 20px 20px;
		height: 64px;
		background: #FAFAFA;
		border-radius: 6px 6px 6px 6px;

		.cardTitle {
			font-weight: 600;
			font-size: 16px;
			color: #000000;
			margin-left: 32px;
		}

		.th1 {
			margin-left: 20px;
			width: 420px;
		}

		.th2 {
			width: 100px;
			text-align: center;
		}

		.th3 {
			width: 220px;
			text-align: center;
		}

		.th4 {
			width: 90px;
			text-align: center;
		}
		

		.th5 {
			width: 240px;
			text-align: center;
		}

		.th6 {
			width: 140px;
			text-align: right;
		}
	}

	.cardBottom {
		height: 80px;
		background: #FFFFFF;
		position: fixed;
		bottom: 0;
		left: 0;
		width: 100%;
		z-index: 99;

		.bottom {
			height: 40px;
			padding: 20px;

			.delete {
				margin-left: 20px;
				font-size: 16px;
			}

			.bottomText {
				font-weight: 400;
				font-size: 16px;
				color: #777A8A;
				margin-left: 20px;
			}

			.bottomText:hover {
				color: #0094FF;
			}
		}
	}

	.dialogCenter {
		border-top: 1px solid #FAFAFA;
		border-bottom: 1px solid #FAFAFA;
		flex-direction: column;
		padding: 10px 0;

		.deleteTitle {
			font-weight: 600;
			font-size: 20px;
			color: #1D223C;
			text-align: center;
			margin: 20px;
		}

		.deleteDetaile {
			font-weight: 400;
			font-size: 16px;
			color: #777A8A;
			text-align: center;
			line-height: 30px;
		}
	}

	.infoImage {
		font-size: 12px;
		background: #0085FF;
		border-radius: 4px;
		color: #fff;
		padding: 4px 6px;
		margin-left: 5px;
	}
</style>