<template>
	<div style="background-color:#F8F8F8 ;">
		<layout-top></layout-top>
		<div>
			<div>
				<el-row class="content">
					<el-col :span="2">
						<div>&emsp;</div>
					</el-col>
					<el-col :span="20">
						<div class="title">
							<div class="titleH1">
								<img :src="require('@/static/image/index/left.png')" />
								<span style="margin: 0 20px;">推荐商家</span>
								<img :src="require('@/static/image/index/right.png')" />
							</div>
							<div class="titleH2">海量新游戏 折扣天天有</div>
						</div>

						<div class="shopList">
							<div class="shopItem" v-for="(item,index) in recommendedList">
								<img :src="item.logo || require('@/static/image/index/hotShop1.png')"
									style="width: 150px;height: 150px;border-radius: 12px 12px 0 0;border-radius: 50%;margin: 20px 0;" />
								<div class="shopBottom">
									<div class="shopName el1">{{item.storeName}}</div>
									<div class="shopTag">
										<span class="tagItem">认证商家</span>
									</div>
								</div>

								<div class="shopBottomHover">
									<div class="hoverTag" @click="dialogInfo(item)">
										<div v-if="type == 'recycle'" class="tag1">出号给Ta</div>
										<div v-else class="tag1">找Ta代练</div>
									</div>
									<div class="hoverText">
										向商家询价请点击出号给TA
									</div>
								</div>
							</div>

						</div>

					</el-col>
					<el-col :span="2">
						<div>&emsp;</div>
					</el-col>
				</el-row>
			</div>
			<div class="content">
				<img v-if="type == 'recycle'"  style="margin:30px 0"  :src="require('@/static/image/index/resource.png')" />
				<img v-if="type == 'leveling'"  style="margin:30px 0"  :src="require('@/static/image/index/resource1.png')" />
			</div>
			
			<div>
				<el-row class="content">
					<el-col :span="2">
						<div>&emsp;</div>
					</el-col>
					<el-col :span="20">
						<div class="title">
							<div class="titleH1">
								<img :src="require('@/static/image/index/left.png')" />
								<span style="margin: 0 20px;">更多商家</span>
								<img :src="require('@/static/image/index/right.png')" />
							</div>
							<div class="titleH2">海量新游戏 折扣天天有</div>
						</div>

						<div class="shopList">
							<div class="shopItem" v-for="(item,index) in noRecommendedList">
								<img :src="item.logo || require('@/static/image/index/hotShop1.png')"
									style="width: 150px;height: 150px;border-radius: 12px 12px 0 0;border-radius: 50%;margin: 20px 0;" />
								<div class="shopBottom">
									<div class="shopName el1">{{item.storeName}}</div>
									<div class="shopTag">
										<span class="tagItem">认证商家</span>
									</div>
								</div>

								<div class="shopBottomHover">
									<div class="hoverTag" @click="dialogInfo(item)">
										<div v-if="type == 'recycle'" class="tag1">出号给Ta</div>
										<div v-else class="tag1">找Ta代练</div>
									</div>
									<div class="hoverText">
										向商家询价请点击出号给TA
									</div>
								</div>
							</div>
						</div>
					</el-col>
					<el-col :span="2">
						<div>&emsp;</div>
					</el-col>
				</el-row>
			</div>
		</div>
		<el-dialog
		  title="联系方式"
		  :visible.sync="dialogVisible"
		  width="30%"
		  :before-close="handleClose">
		  <div class="dialog">
			 <span>联系电话:{{accountInfo.phone}}</span>
			 <span style="margin-top: 20px;">联系QQ:{{accountInfo.qq}}</span> 
		  </div>
		 
		  <span slot="footer" class="dialog-footer">
		    <el-button @click="dialogVisible = false">取 消</el-button>
		    <el-button type="primary" @click="dialogVisible = false">确 定</el-button>
		  </span>
		</el-dialog>
		<shopping-card></shopping-card>
	</div>
</template>

<script>
	import layoutTop from '@/components/layoutTop.vue';
	import shoppingCard from '@/components/shoppingCart.vue';
	import {
		storeAccountList,
		sessionRecordAdd
	} from '@/api/index.js'
	export default {
		components: {
			layoutTop,
			shoppingCard
		},
		data() {
			return {
				dialogVisible:false,
				noRecommendedList: [],
				recommendedList: [],
				accountInfo:{},
				search: {
					pageNo: 1,
					pageSize: 100,
					settledType: 4
				},
				type:'recycle'
			}

		},
		created() {
			this.type = this.$route.params.type
			if(this.$route.params.type == 'recycle'){
				this.search.settledType = 4
				this.getList()
				this.getNoRecommend()
			}else{
				this.search.settledType = 5
				this.getList()
				this.getNoRecommend()
			}
			
		},
		methods: {
			dialogInfo(info){
				console.log(info.userId)
				console.log(this.$store.state.userInfo.id)
				if(info.userId == this.$store.state.userInfo.id){
					this.message('不能给自己聊天','error')
					return
				}
				let infos={
					consultId: info.userId,
					userId:this.$store.state.userInfo.id
				}
				if(this.$route.params.type == 'recycle'){
					infos.type = 2
				}else{
					infos.type = 3
				}
				localStorage.setItem('recordType',infos.type)
				sessionRecordAdd(infos).then(res=>{
					if(res.code == 200){
						this.$router.push('/chat/'+info.userId)
					}
				})
			},
			getList() {
				storeAccountList(Object.assign(this.search, {
					isRecommend: 1
				})).then(res => {
					if (res.code == 200) {
						this.recommendedList = res.result.records
					}
				})
			},
			getNoRecommend() {
				storeAccountList(Object.assign(this.search, {
					isRecommend: 0
				})).then(res => {
					if (res.code == 200) {
						this.noRecommendedList = res.result.records
					}
				})
			}
		}
	}
</script>

<style lang="scss" scoped>
	.title {
		text-align: center;
		margin-top: 46px;
		margin-bottom: 46px;

		.titleH1 {
			display: flex;
			align-items: center;
			justify-content: center;
			font-weight: bold;
			font-size: 32px;
			color: #1D223C;
			line-height: 42px;

		}

		.titleH2 {

			font-weight: 400;
			font-size: 16px;
			color: #777A8A;
			line-height: 21px;
			margin-top: 8px;

		}
	}

	.shopName {
		font-weight: 600;
		font-size: 22px;
		color: #1D223C;
		line-height: 28px;
		width: 288px;
		padding: 10px;
		text-align: center;
	}

	.shopList {
		display: flex;
	}

	.shopItem {
		width: 306px;
		border-radius: 12px 12px 12px 12px;
		background-color: #fff;
		margin-bottom: 16px;
		padding-bottom: 20px;
		margin-right: 16px;
		display: flex;
		flex-direction: column;
		// justify-content: center;
		align-items: center;
		
	}

	.shopItem:hover {
		.shopBottom {
			display: none;
		}

		.shopBottomHover {
			display: block;
		}
	}

	.shopBottomHover {
		display: none;
	}

	.shopTag {
		display: flex;
		justify-content: space-around;

		.tagItem {
			font-weight: 400;
			font-size: 18px;
			color: #FF5C00;
			line-height: 21px;

			// width: 96px;
			padding: 0 10px;
			height: 33px;
			background: rgba(255, 92, 0, 0.1);
			border-radius: 4px 4px 4px 4px;
			border: 1px solid #FF5C00;
			text-align: center;
			line-height: 33px;
			margin-right: 10px;
		}
	}

	.hoverTag {
		display: flex;
		justify-content: center;
		padding: 10px 40px;

		.tag1 {
			width: 104px;
			height: 41px;
			background: #0085FF;
			border-radius: 4px 4px 4px 4px;
			font-weight: 400;
			font-size: 18px;
			color: #FFFFFF;
			line-height: 41px;
			text-align: center;
		}

		.tag2 {
			width: 104px;
			height: 41px;
			background: #FF5C00;
			border-radius: 4px 4px 4px 4px;
			font-weight: 400;
			font-size: 18px;
			color: #FFFFFF;
			line-height: 41px;
			text-align: center;
		}
	}

	.hoverText {
		font-weight: 400;
		font-size: 16px;
		color: #A5A7B1;
		line-height: 21px;
		text-align: center;

	}
	.dialog{
		display: flex;
		flex-direction: column;
		font-size: 24px;
		font-weight: bold;
		letter-spacing: 2px;
	}
</style>