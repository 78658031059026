<template>
	<div>
		<div class="roleTitle">申述结果查询</div>
		<div class="fl search">
			<div class="fl">
				<span class="searchText">关键字：</span>
				<el-input v-prevent-space style="width: 286px;" v-model="search.message"
					placeholder="请输入消息关键字"></el-input>
			</div>
			<!-- <div class="fl" style="margin-left: 50px;">
				<span class="searchText">商品类型：</span>
				<el-select style="width: 328px;" v-model="value" placeholder="请选择">
					<el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
					</el-option>
				</el-select>
			</div> -->
			<div style="margin-left: 56px;">
				<el-button icon="el-icon-refresh" @click="search.message = ''">重置</el-button>
				<el-button type="primary" icon="el-icon-search" @click="getList">搜索</el-button>
			</div>
		</div>

		<div class="roleTitles">
			<el-badge value="" class="titleItem">
				<div @click="searchs('')" :class="{'action':search.isRead === ''}">全部记录</div>
			</el-badge>
			<el-badge value="" class="titleItem">
				<div @click="searchs(0)" :class="{'action':search.isRead === 0}">待处理</div>
			</el-badge>
			<el-badge value="" class="titleItem">
				<div @click="searchs(1)" :class="{'action':search.isRead === 1}">已处理</div>
			</el-badge>
		</div>

		<div class="tableList">
			<el-table ref="multipleTable" :data="tableList" tooltip-effect="dark" style="width: 950px"
				@selection-change="handleSelectionChange">
				<el-table-column type="index" :index="indexMethod" width="80">
					<template slot="header">
						<div class="cardPrice">序号</div>
					</template>
				</el-table-column>
				<el-table-column width="150">
					<template slot="header">
						<div class="cardPrice">用户名</div>
					</template>
					<template slot-scope="scope">
						<div>
							{{scope.row.account}}
						</div>
					</template>
				</el-table-column>
				<el-table-column prop="name" width="150" align="left">
					<template slot="header">
						<div class="cardPrice">解封原因</div>
					</template>
					<template slot-scope="scope">
						<div>{{scope.row.unsealReason}}</div>
					</template>
				</el-table-column>

				<el-table-column prop="address" width="130">
					<template slot="header">
						<div class="cardPrice">图片</div>
					</template>
					<template slot-scope="scope">
						<div>
							<el-image v-if="scope.row.pic != ''" style="width: 50px; height: 50px"
								:src="scope.row.pic.split(',')[0]" :preview-src-list="scope.row.pic.split(',')"
								fit="cover"></el-image>
						</div>
					</template>
				</el-table-column>
				<el-table-column prop="address" width="100">
					<template slot="header">
						<div class="cardPrice">状态</div>
					</template>
					<template slot-scope="scope">
						<div class="fl" v-if="scope.row.status == -1">
							<div class="stateColor2">&emsp;</div> {{scope.row.status_dictText}}
						</div>
						<div class="fl" v-if="scope.row.status == 0">
							<div class="stateColor2">&emsp;</div> {{scope.row.status_dictText}}
						</div>
						<div class="fl" v-if="scope.row.status == 1">
							<div class="stateColor1">&emsp;</div> {{scope.row.status_dictText}}
						</div>
					</template>
				</el-table-column>
				<el-table-column prop="address" width="180">
					<template slot="header">
						<div class="cardPrice">时间</div>
					</template>
					<template slot-scope="scope">
						<div>{{scope.row.createTime}}</div>
					</template>
				</el-table-column>
				<el-table-column prop="address" width="130" align="right">
					<template slot="header">
						<div class="cardPrice">说明</div>
					</template>
					<template slot-scope="scope">
						<div style="color: #0085FF;cursor:pointer;" @click="showResults(scope.row)">结果</div>
					</template>
				</el-table-column>
			</el-table>

		</div>

		<el-dialog title="温馨提示" :visible.sync="dialogVisible" width="30%" :before-close="handleClose">
			<div class="dialogCenter fl">
				<img :src="require('@/static/image/index/Vector.png')" style="width: 76px;height: 76px;" />
				<span class="deleteTitle">驳回申述</span>
				<span class="deleteDetaile">您的账户解封申述被驳回<br />驳回信息:{{resulesMessage}}</span>

			</div>
			<span slot="footer" class="dialog-footer">
				<el-button @click="dialogVisible = false">取 消</el-button>
				<el-button style="background-color: #FF5C00;color: #fff;" @click="dialogVisible = false">确 定</el-button>
			</span>
		</el-dialog>

		<el-dialog title="温馨提示" :visible.sync="dialogVisible1" width="30%" :before-close="handleClose">
			<div class="dialogCenter fl">
				<img :src="require('@/static/image/mine/status4.png')" style="width: 76px;height: 76px;" />
				<span class="deleteTitle">申述成功</span>
				<span class="deleteDetaile">您的账户解封已申述成功</span>

			</div>
			<span slot="footer" class="dialog-footer">
				<el-button @click="dialogVisible1 = false">取 消</el-button>
				<el-button style="background-color: #0085FF;color: #fff;" @click="dialogVisible1 = false">确
					定</el-button>
			</span>
		</el-dialog>
	</div>

</template>

<script>
	import {
		accountAppealQueryById,
		accountAppealList
	} from '@/api/index.js'
	export default {
		data() {
			return {
				dialogVisible: false,
				dialogVisible1: false,
				actionIndex: 0,
				tableList: [],
				search: {
					isRead: '',
					message: '',
					pageNo: 1,
					pageSize: 5
				},
				resulesMessage: ''
			}
		},
		created() {
			this.getList()

		},
		methods: {
			// 查看处理结果
			showResults(item) {
				this.resulesMessage = item.reason
				if (item.status == 1) {
					this.dialogVisible1 = true
				} else if (item.status == -1) {
					this.dialogVisible = true
				} else {
					this.message('暂未处理', 'error')
				}
			},
			searchs(value) {
				this.search.isRead = value
				this.getList()
			},
			getList() {
				accountAppealList(this.search).then(res => {
					if (res.code == 200) {
						this.tableList = res.result.records
					}

				})
			}

		}
	}
</script>

<style scoped lang="scss">
	.roleTitle {
		font-weight: 600;
		font-size: 24px;
		color: #1D223C;
		width: 950px;
		border-bottom: 1px solid #ECECEC;
		height: 60px;
		line-height: 60px;
		padding-left: 42px;

	}

	.search {

		padding: 24px 42px;

		.searchText {
			font-weight: 400;
			font-size: 18px;
			color: #1D223C;
		}
	}

	.roleTitles {

		width: 900px;
		border-bottom: 1px solid #ECECEC;
		height: 50px;
		// line-height: 87px;
		padding-left: 42px;
		padding-right: 42px;
		display: flex;
		align-items: center;

		.titleItem {
			font-weight: 400;
			font-size: 14px;
			position: relative;
			color: #1D223C;
			height: 30px;
			margin-right: 50px;
			cursor:pointer;
		}

		.titleItem::after {
			position: absolute;
			width: 1px;
			height: 20px;
			content: '';
			top: 2px;
			right: -25px;
			background-color: #DEDEDE;
		}

		.titleItem:last-child::after {
			width: 0px;
		}
	}

	.action {
		color: #0085FF;
		position: relative;
		// border-bottom: 4px solid #0085FF;
	}

	.action::after {
		position: absolute;
		content: '';
		width: 60px;
		height: 4px;
		background: #0085FF;
		left: 0;
		bottom: -21px;
	}

	.tableList {
		margin: 20px 42px;
	}

	.cardPrice {
		font-weight: bold;
		font-size: 12px;
		color: #1D223C;
	}

	.stateColor1 {
		width: 8px;
		height: 8px;
		background: #19E382;
		border-radius: 50%;
		margin-right: 6px;
	}

	.stateColor2 {
		width: 8px;
		height: 8px;
		background: red;
		border-radius: 50%;
		margin-right: 6px;
	}


	.dialogCenter {
		border-top: 1px solid #FAFAFA;
		border-bottom: 1px solid #FAFAFA;
		flex-direction: column;
		padding: 30px 0;

		.deleteTitle {
			font-weight: 600;
			font-size: 20px;
			color: #1D223C;
			text-align: center;
			margin: 20px;
		}

		.deleteDetaile {
			font-weight: 400;
			font-size: 16px;
			color: #777A8A;
			text-align: center;
			line-height: 30px;
		}
	}
</style>