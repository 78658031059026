<template>
	<div style="background-color: #F8F8F8;min-height: 100vh;" v-if="isShow">
		<head-bar></head-bar>
		<el-row class="content">

			<el-col :span="24">
				<div class="main">
					<div style="display: flex;">
						<div class="leftMenu">
							<div class="leftLable">个人中心</div>
							<el-menu :unique-opened="true" :default-active="defaultActive" class="el-menu-vertical-demo"
								@select="menuSelect" active-text-color="#0085FF">
								<div v-for="(item,index) in routerList" :key="item.id">
									<el-menu-item :index="item.id" v-if="item.child.length == 0">
										<span slot="title" style="font-size: 14px;">{{item.text}}</span>
									</el-menu-item>
									<el-submenu v-else :index="item.id">
										<template slot="title">
											<span slot="title" style="font-size: 14px;">{{item.text}}</span>
										</template>
										<el-menu-item :index="items.id" v-for="(items,index) in item.child">
											<template slot="title">
												<div class="fl">
													<div class="dot">&emsp;</div>
													<span style="font-size: 14px;">{{items.text}}</span>
												</div>
											</template>
										</el-menu-item>
									</el-submenu>
								</div>
							</el-menu>
						</div>
						<div>
							<mine @withdraw="withdraw" v-if="defaultActive == 1"></mine>
							<role v-if="defaultActive == 11"></role>
							<buyOrder v-if="defaultActive == 8 "></buyOrder>
							<bidOrder v-if="defaultActive == 9"></bidOrder>
							<sellerGood v-if="defaultActive == 12"></sellerGood>
							<myOrder v-if="defaultActive == 13"> </myOrder>
							<myDownOrder v-if="defaultActive == 14"> </myDownOrder>
							<moneyAdmin @withdraw="withdraw" v-if="defaultActive == 15"></moneyAdmin>
							<moneyAccount v-if="defaultActive == 16"></moneyAccount>
							<shopEnter v-if="defaultActive == 17"></shopEnter>
							<shopAdmin v-if="defaultActive == 18"></shopAdmin>
							<buyShop v-if="defaultActive == 19 "></buyShop>
							<sellGoods v-if="defaultActive == 20"></sellGoods>
							<message v-if="defaultActive == 6"></message>
							<stateAdmin v-if="defaultActive == 22"></stateAdmin>
							<gameLeveling v-if="defaultActive == 24"></gameLeveling>
							<gameLeveling1 v-if="defaultActive == 25"></gameLeveling1>
							<publicGameLeveling v-if="defaultActive == 26"></publicGameLeveling>

							<userInfo v-if="defaultActive == 27"></userInfo>
							<realName v-if="defaultActive == 28"></realName>
							<loginPassword v-if="defaultActive == 29"></loginPassword>
							<paymentCode v-if="defaultActive == 30"></paymentCode>
							<phoneBind v-if="defaultActive == 31"></phoneBind>
							<loginType v-if="defaultActive == 32"></loginType>
						</div>
					</div>
				</div>
			</el-col>

		</el-row>
		<shoppingCard></shoppingCard>
		<footer-bars></footer-bars>
	</div>
</template>

<script>
	import mine from '@/views/mine/components/mine.vue'
	import role from '@/views/mine/components/role.vue'
	import buyOrder from '@/views/mine/components/buyOrder.vue'
	import bidOrder from '@/views/mine/components/bidOrder.vue'
	import sellerGood from '@/views/mine/components/sellerGood.vue'
	import myOrder from '@/views/mine/components/myOrder.vue'
	import myDownOrder from '@/views/mine/components/myDownOrder.vue'
	import moneyAdmin from '@/views/mine/components/moneyAdmin.vue'
	import moneyAccount from '@/views/mine/components/moneyAccount.vue'
	import shopEnter from '@/views/mine/components/shopEnter.vue'
	import shopAdmin from '@/views/mine/components/shopAdmin.vue'
	import buyShop from '@/views/mine/components/buyShop.vue'
	import sellGoods from '@/views/mine/components/sellGoods.vue'
	import message from '@/views/mine/components/message.vue'
	import stateAdmin from '@/views/mine/components/stateAdmin.vue'
	import headBar from '@/components/HeadBar.vue';
	import shoppingCard from '@/components/shoppingCart.vue';
	import gameLeveling from '@/views/mine/components/gameLeveling.vue';
	import gameLeveling1 from '@/views/mine/components/gameLeveling1.vue';
	import publicGameLeveling from '@/views/mine/components/publicGameLeveling.vue';
	import footerBars from '@/components/footerBars.vue';



	import userInfo from '@/views/setting/components/userInfo.vue'
	import realName from '@/views/setting/components/realName.vue'
	import loginPassword from '@/views/setting/components/loginPassword.vue'
	import paymentCode from '@/views/setting/components/paymentCode.vue'
	import phoneBind from '@/views/setting/components/phoneBind.vue'
	import loginType from '@/views/setting/components/loginType.vue'
	import {
		settledStoreList
	} from '@/api/index.js'
	export default {
		components: {
			mine,
			role,
			buyOrder,
			bidOrder,
			sellerGood,
			myOrder,
			moneyAdmin,
			moneyAccount,
			shopEnter,
			shopAdmin,
			buyShop,
			sellGoods,
			message,
			stateAdmin,
			headBar,
			myDownOrder,
			shoppingCard,
			gameLeveling,
			gameLeveling1,
			publicGameLeveling,
			footerBars,

			realName,
			userInfo,


			loginPassword,
			paymentCode,
			phoneBind,
			loginType,
		},
		data() {
			return {
				defaultActive: 1,
				isShow: false,
				routerList: [{
						id: 1,
						text: '我的首页',
						child: []
					},
					{
						id: 2,
						text: '我是买家',
						child: [{
								id: 8,
								text: '我的购买订单'
							},
							// {
							// 	id: 9,
							// 	text: '我的出价订单'
							// },
							{
								id: 11,
								text: '收货角色管理'
							},
						]
					},
					{
						id: 3,
						text: '我是卖家',
						child: [{
								id: 12,
								text: '我的商品'
							},
							{
								id: 13,
								text: '我的订单'
							},
							// {
							// 	id: 14,
							// 	text: '我的降价'
							// }
						]
					},
					{
						id: 23,
						text: '我的代练',
						child: [{
								id: 26,
								text: '我发布的'
							}, {
								id: 24,
								text: '我发起的'
							},
							{
								id: 25,
								text: '我接单的'
							}
						]
					},
					{
						id: 4,
						text: '我的钱包',
						child: [{
								id: 15,
								text: '资金管理'
							},
							{
								id: 16,
								text: '我的提现'
							}
						]
					},
					{
						id: 5,
						text: '商家管理',
						child: [{
								id: 17,
								text: '商家入驻'
							},
							{
								id: 18,
								text: '店铺管理'
							},
							// {
							// 	id: 19,
							// 	text: '收货商品'
							// },
							// {
							// 	id: 20,
							// 	text: '卖货商品'
							// }
						]
					},
					{
						id: 6,
						text: '消息中心',
						child: []
					},
					{
						id: 7,
						text: '账户申述',
						child: [{
								id: 21,
								text: '账户解封申述'
							},
							{
								id: 22,
								text: '申述结果查询'
							}
						]
					},
					{
						id: 27,
						text: '个人信息',
						child: []
					},
					{
						id: 28,
						text: '实名认证',
						child: []
					},
					{
						id: 29,
						text: '登录密码',
						child: []
					},
					{
						id: 30,
						text: '支付密码',
						child: []
					},
					{
						id: 31,
						text: '手机绑定',
						child: []
					},
					{
						id: 32,
						text: '绑定账户',
						child: []
					}

				]
			}
		},
		watch: {
			// 利用watch方法检测路由变化：
			$route: function(to, from) {
				if (to.path !== from.path) {
					this.defaultActive = to.params.id; // 获取参数
					this.isShow = true
				}
			}
		},
		created() {
			// 获取已开通商家信息
			settledStoreList().then(res => {
				if (res.code == 200) {
					// this.tableList1 = res.result.settled
					// if (res.result.settled.length != 0) {
					// 	this.routerList[5].child.push({
					// 		id: 18,
					// 		text: '店铺管理'
					// 	})
					// }
					res.result.settled.forEach(data => {
						if (data.settledTypeName == '热卖商城入驻') {
							this.routerList[5].child.push({
								id: 20,
								text: '卖货商品'
							})
						} else if (data.settledTypeName == '收货商家入驻' || data.settledTypeName ==
							'CC客服专收（客服专享请勿申请）') {
							this.routerList[5].child.push({
								id: 19,
								text: '收货商品'
							})
						}
					})
					this.defaultActive = this.$route.params.id
					this.isShow = true
				}

			})

		},
		methods: {
			menuSelect(e) {
				if(e == this.defaultActive){
					return
				}
				if (e == 21) {
					this.$router.push('/serviceCenter/1/0')
				} else {
					// this.defaultActive = e
					this.$router.push('/mineIndex/' + e)
				}

			},
			withdraw(type) {
				this.$router.push('/mineIndex/' + type)
			}
		}
	}
</script>

<style scoped lang="scss">
	::v-deep .el-menu {
		border: none;
	}

	::v-deep .el-submenu__title {
		font-weight: 400;
		font-size: 18px;
		color: #1D223C;
		background-color: #F8F8F8;
	}





	.dot {
		width: 6px;
		height: 6px;
		background: #777A8A;
		border-radius: 50%;
		margin-right: 11px;
	}





	.main {
		min-height: 1154px;
		background: #FFFFFF;
		box-shadow: 0px 6px 24px 0px rgba(0, 0, 0, 0.08);
		border-radius: 20px 20px 20px 20px;
		overflow: hidden;
		margin: 13px 0;

		::v-deep .el-menu-item {
			font-weight: 400;
			font-size: 18px;
			height: 68px;
			line-height: 68px;
			color: #777A8A;
			background-color: #F8F8F8;
		}

		::v-deep .el-menu-item.is-active {
			color: #0085FF;
			font-size: 18px;
			background-color: #fff;
			border-left: 4px solid #0085FF;

			.dot {
				background: #0085FF;
			}
		}
	}

	.leftMenu {
		width: 200px;
		height: 1154px;
		background: #F8F8F8;

		.leftLable {


			font-weight: 600;
			font-size: 16px;
			color: #1D223C;
			text-align: center;
			line-height: 74px;

			width: 200px;
			height: 74px;
			border-radius: 0px 0px 0px 0px;
		}

	}
</style>