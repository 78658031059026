<template>
	<div class="appMains">
		<!-- 内容区域 -->
		<AppMain></AppMain>

		<FooterBar></FooterBar>


	</div>
</template>

<script>
	// import { mapGetters } from 'vuex'
	// import HeadBar from "./components/HeadBar.vue";
	import AppMain from "./components/AppMain.vue";
	import FooterBar from "./components/FooterBar.vue";
	export default {
		data() {
			return {}
		},
		components: {
			// HeadBar,
			AppMain,
			FooterBar
		},
		// computed: {
		//   ...mapGetters([
		//     'sidebar',
		//   ]),
		//    // 是否关闭侧边栏
		//   isCollapse() {
		//     return !this.sidebar.opened
		//   }
		// },
	};
</script>

<style lang="scss" scoped>
	.appMains {
		position: relative;
		height: 100vh;
		// overflow-y: auto;
		// 		scrollbar-width: none;
		// 		/* 隐藏WebKit浏览器的滚动条 */
		// 		&::-webkit-scrollbar {
		// 			display: none;
		// 		}
	}


	::v-deep .el-header {
		padding: 0;
		background-color: #000;
	}

	::v-deep .el-main {
		padding: 0;
	}

	::v-deep .el-footer {
		padding: 0;
	}
</style>